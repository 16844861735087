import { TaskPlanSubSection } from '#models/task-plan-sub-section';

export class TaskPlanSection {
    public General: boolean;
    public Description: string;
    public ID: number;
    public TaskPlanId: number;
    public SortID: number;
    public TaskPlanSubSections: TaskPlanSubSection[];

    // Only for angular model
    public hide: boolean;
}
