import { Component, OnInit, ɵConsole, Inject } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem, MessageService, SelectItemGroup } from 'primeng/api';
import { Equipment } from '#models/equipment';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { ORDService } from '#services/api/ord.service';
import { ORDTemplate } from '#models/ordTemplate';
import { EquipmentEC } from '#models/equipmentEC';
import { BusinessUnit } from '#models/business-unit';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { EamEquipment } from '#models/eamEquipment';
import { EquipmentService } from '#services/api/equipment.service';

import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-task-plan-edit',
  templateUrl: './task-plan-edit.component.html',
  styleUrls: ['./task-plan-edit.component.scss']
})
export class TaskPlanEditComponent implements OnInit {
  taskPlan: TaskPlan = new TaskPlan();
  taskPlanId: number;
  ordTemplateID: number;
  ordTemplate: ORDTemplate;
  displayWarningDesc: boolean;
  displayWarningCode: boolean;
  Equipments: SelectItem[];
  GenericEquipments: SelectItem[];
  originalGenericParentID: number;
  selectedGenericEquipment: TaskPlan = new TaskPlan();
  private storedData: any = [];
  selectedBU: BusinessUnit;
  private loadedEquipment = false;
  private loadedGeneric = false;
  groupedEquipment: SelectItemGroup[];
  groupedEquipmentCopy: SelectItemGroup[];
  equipmentWells: SelectItem[];
  ecEquipment: SelectItem;
  selectedEAMEquipment: SelectItem;
  groupedEAMEquipment: SelectItemGroup[] = [];
  groupedEAMEquipmentCopy: SelectItemGroup[] = [];

  constructor(
    private taskPlanSvc: TaskPlanService,
    private eqSvc: EquipmentService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private omSvc: OMWorkbenchService,
    private ordSvc: ORDService,
    private aRoute: ActivatedRoute,
    private messageSvc: MessageService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
  }

  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  ngOnInit() {
    this.Equipments = [];
    this.GenericEquipments = [];
    this.aRoute.params.subscribe(v => {
      this.taskPlanId = v['id'];
      this.ordTemplateID = v['ordID'];
    });

    this.groupedEquipment = [];
    this.groupedEquipmentCopy = [];
    this.equipmentWells = [];
    this.getFromLocal("BU");
    this.selectedBU = this.storedData["BU"];

    this.getORDTemplateById(this.ordTemplateID);
    this.displayWarningDesc = false;
    this.displayWarningCode = false;
  }

  private getORDTemplateById(ordTempID: number) {
    this.loadingIndicatorSvc.show();
    this.ordSvc.getORDTemplateByID(ordTempID)
      .pipe(
        finalize(() => {
          // this.loadingIndicatorSvc.hide();
          // this.loadGenericEquipment();
        }))
      .subscribe((data: ORDTemplate) => {
        this.ordTemplate = data;
        this.getTaskPlanById(this.taskPlanId);
      });
  }

  private getWells(): void {
    // const well = this.taskPlan.Equipment[0];
    this.omSvc.getWell(this.ordTemplate.RouteID)
      .pipe(
        finalize(() => {
          const selectedEquipmentId = this.taskPlan.ECObjectID;
          const selEquip = this.Equipments.find(x => x.value.ObjectId === selectedEquipmentId);
          const selWell = this.equipmentWells.find(x => x.value.ObjectId === selectedEquipmentId);
          if (selEquip !== undefined) {
            this.ecEquipment = selEquip.value;
          }
          if (selWell !== undefined) {
            this.ecEquipment = selWell.value;
          }
          this.loadedEquipment = true;
          if (this.loadedGeneric) {
            this.loadingIndicatorSvc.hide();
          }
        }))
      .subscribe((data: Map<string, string>[]) => {
        data.forEach((eqEC) => {
          const equipmentEC = new EquipmentEC();
          equipmentEC.ID = eqEC["ID"];
          equipmentEC.Name = eqEC["Name"];
          equipmentEC.RouteId = "";
          equipmentEC.EquipmentType = "WELL";
          equipmentEC.CreatedDate = new Date(eqEC["CreatedDate"]);
          equipmentEC.EndDate = null;
          equipmentEC.CollectionPointId = eqEC["CollectionPointID"];
          const equipment = new Equipment(equipmentEC);
          const element: SelectItem = { label: equipment.Name, value: equipment };
          this.equipmentWells.push(element);
        });
        const groupWells: SelectItemGroup = { label: "Wells", items: this.equipmentWells };
        this.groupedEquipment.push(groupWells);
        const groupEquipment: SelectItemGroup = { label: "Equipment", items: this.Equipments };
        this.groupedEquipment.push(groupEquipment);
        this.groupedEquipmentCopy = this.groupedEquipment;
      },
        e => {
          this.showError(e, 'Failed to get Wells from EC');
        });
  }

  private getEAMEquipment(): void {
    const eamCode = this.taskPlan.EAMCode;
    this.eqSvc.getEAMEquipmentByRoute(this.ordTemplate.RouteID)
      .pipe(
        finalize(() => {
          let allEqOptions: SelectItem[] = [];
          this.groupedEAMEquipment.forEach(group => {
            group.items.forEach(item => {
              allEqOptions.push(item);
            })
          })
          const selectedEq = allEqOptions.find(x => x.value.EAMCode == eamCode);
          if (selectedEq !== undefined) {
            this.selectedEAMEquipment = selectedEq.value;
          }
          this.loadedEquipment = true;
          if (this.loadedGeneric) {
            this.loadingIndicatorSvc.hide();
          }
        }))
      .subscribe((eamEquipments: EamEquipment[]) => {
        let eqTypes: string[] = [];
        eamEquipments.forEach((eq) => {
          const splitString = eq.EAMDescription.split(";");
          let eqTypeLabel = "";
          let descriptionLabel = "";
          if (splitString.length === 2) {
            eqTypeLabel = splitString[0];  // Isolate Object Type prefix from description
            descriptionLabel = `${splitString[1].trim()} - ${eq.ECName.trim()}`;  // Remove Object Type prefix from description
          } else {
            eqTypeLabel = "OTHER";
            descriptionLabel = `${eq.EAMDescription.trim()} - ${eq.ECName.trim()}`;  // Remove Object Type prefix from description
          }
          const element: SelectItem = { label: descriptionLabel, value: eq };
          if (!eqTypes.includes(eqTypeLabel)) {
            eqTypes.push(eqTypeLabel);
            const newSelectItemGroup: SelectItemGroup = { label: eqTypeLabel, items: [element] }
            this.groupedEAMEquipment.push(newSelectItemGroup);
            this.groupedEAMEquipment.sort((a, b) => (a.label > b.label) ? 1 : -1);
          }
          else {
            let selectedGroup = this.groupedEAMEquipment.find(group => group.label == eqTypeLabel);
            selectedGroup.items.push(element);
            selectedGroup.items.sort((a, b) => (a.label > b.label) ? 1 : -1);
          }
          this.groupedEAMEquipmentCopy=this.groupedEAMEquipment;
        })
      },
        e => {
          this.showError(e, 'Failed to get Equipment from EAM');
        });
  }

  private getECEquipment(objIds: string[]): void {
    this.omSvc.getEquipment(this.ordTemplate.RouteID)
      .pipe(
        finalize(() => {
          this.getWells();
        }))
      .subscribe((data: EquipmentEC[]) => {
        // For every EC Equipment
        data.forEach((eqEC) => {
          const equipment = new Equipment(eqEC);
          // Check if already accounted for by pre-selected EQ
          if (!objIds.includes(eqEC.ID)) {
            const element: SelectItem = { label: equipment.Name, value: equipment };
            this.Equipments.push(element);
          } else {
            const element: SelectItem = { label: equipment.Name, value: this.taskPlan.Equipment.find(x => x.ObjectId === eqEC.ID) };
            this.Equipments.push(element);
          }
        });
      });
  }

  private loadEquipment() {
    // this.loadingIndicatorSvc.show();
    const objIds: string[] = [];
    // Grab Equipment from the TaskPlan, and add the OBJ IDs to an exception List
    this.taskPlan.Equipment.forEach((equipment) => {
      objIds.push(equipment.ObjectId);
    });

    if (this.selectedBU.LinkToEquipment === "EAM") {
      this.getEAMEquipment();
    } else if (this.selectedBU.LinkToEquipment === "EC") {
      this.getECEquipment(objIds);
    }
  }

  private loadGenericEquipment() {
    const buID = this.selectedBU.Id;
    // this.loadingIndicatorSvc.show();
    this.taskPlanSvc.getTaskPlanSimple(1, buID)
      .pipe(
        finalize(() => {
          this.loadedGeneric = true;
          if (this.loadedEquipment) {
            this.loadingIndicatorSvc.hide();
          }
        }))
      .subscribe((data: TaskPlan[]) => {
        data.forEach((tPlan) => {
          this.GenericEquipments.push({ label: tPlan.Description, value: tPlan });

          if (tPlan.ID == this.taskPlan.GenericParentID) {
            this.selectedGenericEquipment = tPlan;
          }
        });
      });
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/TaskPlan', { id: this.ordTemplateID }]);
  }

  public getTaskPlanById(id: number): void {
    // this.loadingIndicatorSvc.show();
    this.taskPlanSvc.getTaskPlanById(id)
      // .pipe(
      // finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        this.taskPlan = data.pop();
        this.originalGenericParentID = this.taskPlan.GenericParentID;
        this.loadGenericEquipment();
        this.loadEquipment();
      });
  }

  public updateTaskPlan(): void {
    if (this.selectedBU.LinkToEquipment === "EC") {
      // Create array of selected Equipment
      this.taskPlan.Equipment = [];
      this.taskPlan.ECObjectID = this.ecEquipment.value.ObjectId;
      this.taskPlan.ECObjectType = this.ecEquipment.value.EquipmentType;
    } else if (this.selectedBU.LinkToEquipment === "EAM") {
      this.taskPlan.EAMCode = this.selectedEAMEquipment.value.EAMCode;
      this.taskPlan.ECObjectID = this.selectedEAMEquipment.value.ECObjectID;
      this.taskPlan.ECObjectType = this.selectedEAMEquipment.value.ECType;
      this.taskPlan.Equipment = [];
    }
    this.displayWarningDesc = this.taskPlan.Description === undefined || this.taskPlan.Description == null || this.taskPlan.Description.length === 0;
    this.displayWarningCode = this.taskPlan.Code === undefined || this.taskPlan.Code == null || this.taskPlan.Code.length === 0;

    if (!this.displayWarningDesc && !this.displayWarningCode) {
      this.loadingIndicatorSvc.show();
      this.taskPlanSvc.updateTaskPlans([this.taskPlan])
        .pipe(
          finalize(() => {
            this.loadingIndicatorSvc.hide();
          })
        ).subscribe((data: any) => {
          // this.onRedirectButtonClick();
          if (this.selectedGenericEquipment === undefined || this.selectedGenericEquipment === null) {
            this.copyAndDeleteTaskPlan(null, this.taskPlan.ID);
          } else {
            this.copyAndDeleteTaskPlan(this.selectedGenericEquipment.ID, this.taskPlan.ID);
          }
        },
          e => {
            this.showError(e, 'Error');
          });
    }
  }

  public copyAndDeleteTaskPlan(sourceID: number, destinationID: number): void {
    if (this.originalGenericParentID !== sourceID) {
      this.loadingIndicatorSvc.show();
      if (sourceID === undefined || sourceID === null) {
        // Call Delete Generic API.
        if (this.originalGenericParentID === undefined || this.originalGenericParentID === null) {
          // Do nothing, TP doesn't have GenericParentID in DB.
          this.loadingIndicatorSvc.hide();
          this.onRedirectButtonClick();
        } else {
          // Delete using GenericParentID
          this.taskPlanSvc.deleteTaskPlanGenericContents(this.taskPlan, this.originalGenericParentID)
            .subscribe((data: any) => {
              this.loadingIndicatorSvc.hide();
              this.onRedirectButtonClick();
            },
              e => {
                this.showError(e, 'Failed to create the Generic Tasks.');
              });
        }
      } else {
        if (this.originalGenericParentID === undefined || this.originalGenericParentID === null) {
          this.loadingIndicatorSvc.hide();
          // Do nothing, TP doesn't have GenericParentID in DB.
          this.copyTaskPlanGenericContents(sourceID, destinationID);
        } else {
          this.taskPlanSvc.deleteTaskPlanGenericContents(this.taskPlan, this.originalGenericParentID)
            .subscribe((data: any) => {
              this.loadingIndicatorSvc.hide();
              this.copyTaskPlanGenericContents(sourceID, destinationID);
            },
              e => {
                this.showError(e, 'Failed to create the Generic Tasks.');
              });
        }
      }
    } else {
      this.onRedirectButtonClick();
    }
  }

  public copyTaskPlanGenericContents(sourceID: number, destinationID: number): void {
    this.loadingIndicatorSvc.show();
    // Grab Subsequent SubArea Ids
    this.taskPlanSvc.copyTaskPlan(sourceID, destinationID, false)
      .pipe(
        finalize(() => {
          // console.log("finally - copytaskplan");
          this.loadingIndicatorSvc.hide();
        })
      ).subscribe((data: any) => {
        // this.loadingIndicatorSvc.hide();
        // this.onRedirectButtonClick();
        this.cloneTaskPlanScheduleFromGeneric(destinationID);
      },
        e => {
          this.loadingIndicatorSvc.hide();
          this.showError(e, 'Failed to create the Generic Tasks.');
        });
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  public cloneTaskPlanScheduleFromGeneric(taskPlanID: number): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.cloneTaskPlanSchedule(taskPlanID)
      .pipe(
        finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        this.onRedirectButtonClick();
      },
        e => {
          this.showError(e, 'Failed to create the Generic Schedule.');
        });
  }
}
