import { NgModule } from '@angular/core';

import { NotificationComponent } from './notification/notification.component';
import { NotificationService } from '#services/shared';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DataViewModule } from 'primeng/dataview';
import { ORDGridComponent } from './ordGrid/ordgrid.component';
import { CheckboxModule } from 'primeng/checkbox';
import { FieldOrdGridComponent } from './field-ord-grid/field-ord-grid.component';
import { IncompletefieldgridComponent } from './incompletefieldgrid/incompletefieldgrid.component';

@NgModule({
  declarations: [
    NotificationComponent,
    ORDGridComponent,
    FieldOrdGridComponent,
    IncompletefieldgridComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    DataViewModule,
    CheckboxModule
  ],
  providers: [
    NotificationService,
    ConfirmationService,
    MessageService
  ],
  exports: [
    NotificationComponent,
    ORDGridComponent,
    FieldOrdGridComponent,
    IncompletefieldgridComponent
  ]
})

export class ComponentsModule { }
