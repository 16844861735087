import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '#environments/environment';
import { User, BusinessUnit } from 'models';

class UserRouteContainer {
    CAI: string;
    RouteIDs: string[];
}

@Injectable()
export class UserService {
    private readonly httpOptions: any = { headers: { 'Content-Type': 'application/json' } };

    constructor(
        private http: HttpClient,
    ) { }

    public getCurrentUser(): Observable<any> {
        return this.http.get<User>(`${environment.apiEndpoint}/user/current/`);
    }

    public getCurrentConfiguration(bu: string): Observable<any> {
        const param = (bu === "ALL BUSINESS UNITS") ? "MCBU" : bu;
        return this.http.post<any>(`${environment.apiEndpoint}/Configuration`, `"${param}"`, this.httpOptions);
    }

    public getAll(): Observable<any> {
        return this.http.get<User[]>(`${environment.apiEndpoint}/user/users/`);
    }

    public getUsersByBusinessUnit(businessUnitID: Number): Observable<any> {
        return this.http.get<BusinessUnit[]>(`${environment.apiEndpoint}/business-unit/${businessUnitID}/users`);
    }

    public search(term: string): Observable<any> {
        const param = (term || '').trim();

        if (param.length === 0) {
            return this.getAll();
        }

        return this.http.post<User[]>(`${environment.apiEndpoint}/user/local-search`, `"${param}"`, this.httpOptions);
    }

    /**
     * This sends a post method with new User to the database
     * It has to put the user into an empty array since the API calls for a Collection of Users
     * @param user The user to be added
     */
    public addUser(user): Observable<any> {
        return this.http.post<any>(`${environment.apiEndpoint}/user/addusers/`, [{'FirstName':user.GivenName, 
        'LastName': user.Surname, 'Cai': user.Cai, 'Email': user.Mail,
        'BusinessUnitID': user.BusinessUnitID }]);
    }

    /**
     * Searches for users in an Azure Directory by Cai, email, first or last name
     * @param term The term to be searched in the Azure Directory
     */
    public getUserFromDirectory(term: string): Observable<any> {
        const param = (term || '').trim();

        if (param.length === 0) {
            return this.getAll();
        }

        return this.http.post<User[]>(`${environment.apiEndpoint}/user/azure-search`, `"${param}"`, this.httpOptions);
    }

    /**
     * Sends an update request to api
     * It has to put the user into an empty array since the API calls for a Collection of Users
     * @param user user to be updated
     */
    public updateUser(user: User): Observable<User> {
        return this.http.post<User>(`${environment.apiEndpoint}/user/updateusers`, [user]);
    }

    public deleteUser(id: number): Observable<User> {
        return this.http.delete<User>(`${environment.apiEndpoint}/user/${id}`);
    }

    /**
     * This sends a post method with a new UserRoute to the database
     * @param routes The user to be added
     */
    public addUserRoute(cai: string, routeIDs: string[]): Observable<any> {
        const routes = new UserRouteContainer();
        routes.CAI = cai;
        routes.RouteIDs = routeIDs;
        return this.http.post<any>(`${environment.apiEndpoint}/userroute/`, routes);
    }
}
