import { TaskPlanItemOption } from './task-plan-item-option';

export class TaskPlanItem {
    public ID: number;
    public Code: string;
    public Description: string;
    public TaskPlanSubSectionId: number;
    public SortID: number;
    public ItemOptionType: string;
    public IsOptional: boolean;
    public IncludeRepairBox: boolean;
    public TaskPlanItemOptions: TaskPlanItemOption[];
}
