<div class="box-shadow module">
  <h2>{{ord.Name}}'s Equipment Task Plans</h2>
  <div class="row headerInfo">
    <div >
      <a class="btn btn-primary" (click)="onRedirectButtonClick('TaskPlan-create')" style="cursor: pointer;">Create New</a>
    </div>
    <div class="col-sm-6">
    </div>
    <p-dialog header="Please review table below to confirm these changes" [(visible)]="displayEAMEquipmentTable"
      [style]="{width: '50vw'}" [baseZIndex]="10000" [modal]="true">
      <p-table [value]="eamEquipmentAboutToChange">
        <ng-template pTemplate="header">
          <tr>
            <th>Task Plan Code</th>
            <th>Matched Equipment</th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-eq>
          <tr>
            <td [ngStyle]="{'color': eq.showDuplicateWarning ? 'red' : '' }">{{ eq.taskPlanCode }}</td>
            <td [ngStyle]="{'color': eq.showDuplicateWarning ? 'red' : '' }">{{ eq.eamEq.ECName }} </td>
            <td> <button (click)="removePendingEAMEqChange(eq.eamEq.ECName, eq.taskPlanCode)">Remove</button></td>
          </tr>
        </ng-template>
      </p-table>
      <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="updateTaskPlansWithEAMEquipment()"
          label="Confirm"></button>
        <button type="button" pButton icon="pi pi-times" (click)="cancelTaskPlanUpdate()" label="Cancel"
          class="p-button-secondary"></button>
      </p-footer>
    </p-dialog>
    <button *ngIf="selectedBU.LinkToEquipment == 'EAM'" class="p-button-raised p-button-secondary" pButton
      (click)="getEAMEquipmentFromTaskPlanCode()">Match Equipment</button>
  </div>

  <table class="table table-hover">
    <tr>
      <th>Code</th>
      <th>Description</th>
      <th>Equipment</th>
      <th></th>
      <th></th>
    </tr>
    <tr *ngFor="let tp of taskPlans">
      <td>{{tp.Code}}</td>
      <td>{{tp.Description}}</td>
      <td>
        <div *ngIf="selectedBU.LinkToEquipment == 'EC'">
          <div *ngFor="let eq of tp.Equipment">
            {{eq.Name }}
          </div>
        </div>

        <span *ngIf="selectedBU.LinkToEquipment == 'EC'">Linked to EC: {{tp.ECObjectType}} - {{tp.ECObjectID}}</span>
        <span *ngIf="selectedBU.LinkToEquipment == 'EAM'">{{tp.displayName}}</span>
      </td>
      <td style="text-align:end">
        <a (click)="onRedirectButtonClick('TaskPlan-clone',tp.ID)" style="cursor: pointer;">Clone</a> |
        <a (click)="onRedirectButtonClick('TaskPlan-edit',tp.ID)" style="cursor: pointer;">Edit</a> |
        <a (click)="onRedirectButtonClick('TaskPlan-manage',tp.ID)" style="cursor: pointer;">Manage</a> |
        <a (click)="onRedirectButtonClick('TaskPlan-delete',tp.ID)" style="cursor: pointer;">Delete</a>
      </td>
      <td style="text-align: end" width="5%">
        <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm ShowOnHover" *ngIf="tp.ID == firstTPId" />
        <a (click)="moveUpTaskPlan(tp.ID)" *ngIf="tp.ID != firstTPId">
          <img src="assets/images/upTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover" />
        </a>
        <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm ShowOnHover" *ngIf="tp.ID == lastTPId" />
        <a (click)="moveDownTaskPlan(tp.ID)" *ngIf="tp.ID != lastTPId">
          <img src="assets/images/dnTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover" />
        </a>
      </td>
    </tr>
  </table>
</div>
<div class="row">
  <div class="col-sm-6">
    <a class="btn btn-primary" (click)="onRedirectButtonClick('')" style="cursor: pointer;">Back to ORD</a>
  </div>
  <div class="col-sm-6">
  </div>
</div>