import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';
import { TaskPlanSection } from '#models/task-plan-section';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TaskPlanSubSection } from '#models/task-plan-sub-section';
import { TaskPlanItem } from '#models/task-plan-item';
import { ConfirmationService, SelectItem, MessageService } from 'primeng/api';
import { AssetSchedule } from '#models/assetSchedule';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from "rxjs/operators";

@Component({
  selector: 'app-task-plan-manage',
  templateUrl: './task-plan-manage.component.html',
  styleUrls: ['./task-plan-manage.component.scss']
})
export class TaskPlanManageComponent implements OnInit {
  taskPlan: TaskPlan = new TaskPlan();
  taskPlanId: number;
  ordID: number;
  hasSections: boolean;
  //-----------------------
  displayWarningSec: boolean;
  taskPlanSection: TaskPlanSection = new TaskPlanSection();
  selectedSectionId: number;
  firstSectionId: number;
  lastSectionId: number;
  //-----------------------
  displayWarningSubSec: boolean;
  subSectionList: TaskPlanSubSection[];
  subSection: TaskPlanSubSection = new TaskPlanSubSection();
  firstSubSectionId: number;
  lastSubSectionId: number;
  //-----------------------  
  itemToDelete: TaskPlanItem = new TaskPlanItem();
  //-----------------------
  displaySchedule: boolean = false;
  days: SelectItem[];
  selectedDays: number[] = [];
  selectedSchedule: number;
  selectedTaskPlanSection: number;
  requiresSchedule: number;
  displayWarningFrequency: boolean;
  disableScheduleButtons: boolean;
  autoCreateRepairStatusbox: boolean = false;

  constructor(
    private taskPlanSvc: TaskPlanService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private messageSvc: MessageService
  ) {
    this.days = [
      { label: 'Monday', value: 1 },
      { label: 'Tuesday', value: 2 },
      { label: 'Wednesday', value: 3 },
      { label: 'Thursday', value: 4 },
      { label: 'Friday', value: 5 },
      { label: 'Saturday', value: 6 },
      { label: 'Sunday', value: 7 }
    ];
    this.selectedSchedule = 1;
    this.requiresSchedule = 0;
  }

  onConfirm() {
    this.messageSvc.clear('c');
  }

  onReject() {
    this.messageSvc.clear('c');
  }

  //---------------INIT FUNCTION---------------//
  ngOnInit() {
    this.aRoute.params.subscribe(v => {
      this.taskPlanId = v['id'];
      this.ordID = v['ordID'];
      this.selectedSectionId = this.taskPlanSvc.isInvalidString(v['sectionId']) ? null : v['sectionId'];
    });

    this.getTaskPlanById(this.taskPlanId);
    this.displayWarningSec = false;
    this.displayWarningSubSec = false;
    this.displayWarningFrequency = false;
    this.selectedDays = [];
    this.disableScheduleButtons = false;
    this.subSection.AutoCreateRepairBox = false;
  }

  onRedirectButtonClick(): void {
    if (this.ordID == 1) {
      this._router.navigate(['ord/generic', { id: this.ordID }]);
    } else {
      this._router.navigate(['ord/TaskPlan', { id: this.ordID }]);
    }
  }

  //---------------TASK PLAN SECTION---------------//

  public getTaskPlanById(id: number, selectLastSection: boolean = false): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.getTaskPlanById(id)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: TaskPlan[]) => {
        this.taskPlan = data.pop();
        this.completeTaskPlanSectionsList(selectLastSection);
      });
  }

  public createTaskPlanSection(): void {
    this.displayWarningSec = this.taskPlanSvc.isInvalidString(this.taskPlanSection.Description);
    if (!this.displayWarningSec) {
      this.taskPlanSection.TaskPlanId = this.taskPlanId;
      this.loadingIndicatorSvc.show();

      if (this.ordID == 1) {
        this.taskPlanSvc.createAndCloneTaskPlanSection(this.taskPlanSection)
          .pipe(
      finalize(() => {
            this.loadingIndicatorSvc.hide();
            this.taskPlanSection.Description = '';
          }))
          .subscribe(
            (data: any) => {
              this.getTaskPlanById(this.taskPlanId, true);
            }, exception => {
              if (exception instanceof HttpErrorResponse) {
                switch (exception.status) {
                  case 0:
                    this.loadingIndicatorSvc.hide();
                    this.messageSvc.clear();
                    this.messageSvc.add({
                      key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                      detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                    });
                    break;
                }
              }
            }
          );
      } else {
        this.taskPlanSvc.createTaskPlanSection(this.taskPlanSection)
          .pipe(
      finalize(() => {
            this.loadingIndicatorSvc.hide();
            this.taskPlanSection.Description = '';
          }))
          .subscribe((data: any) => {
            this.getTaskPlanById(this.taskPlanId, true);
          });
      }


    }
  }

  public moveUpTaskPlanSection(tpsId: number): void {
    this.loadingIndicatorSvc.show();

    if (this.ordID == 1) {
      this.taskPlanSvc.moveUpTaskPlanSectionClone(tpsId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSection(tpsId, -1); // MOVE UP
            this.completeTaskPlanSectionsList();
          } else {
            // console.log(data.Message);
          }
        });
    } else {
      this.taskPlanSvc.moveUpTaskPlanSection(tpsId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSection(tpsId, -1); // MOVE UP
            this.completeTaskPlanSectionsList();
          } else {
            // console.log(data.Message);
          }
        });
    }
  }

  public moveDownTaskPlanSection(tpsId: number): void {
    this.loadingIndicatorSvc.show();

    if (this.ordID == 1) {
      this.taskPlanSvc.moveDownTaskPlanSectionClone(tpsId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSection(tpsId, 1); // MOVE DOWN
            this.completeTaskPlanSectionsList();
          } else {
            // console.log(data.Message);
          }
        });
    } else {
      this.taskPlanSvc.moveDownTaskPlanSection(tpsId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSection(tpsId, 1); // MOVE DOWN
            this.completeTaskPlanSectionsList();
          } else {
            // console.log(data.Message);
          }
        });
    }
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  swapSection(sectionId: number, possition: number): void {
    var indexItem = this.taskPlan.TaskPlanSections.findIndex(x => x.ID == sectionId);
    var itemUpSortId = this.taskPlan.TaskPlanSections[indexItem].SortID;
    var itemDownSortId = this.taskPlan.TaskPlanSections[indexItem + possition].SortID;
    this.taskPlan.TaskPlanSections[indexItem].SortID = itemDownSortId;
    this.taskPlan.TaskPlanSections[indexItem + possition].SortID = itemUpSortId;
  }

  completeTaskPlanSectionsList(selectLastSection: boolean = false): void {
    var list = this.taskPlan.TaskPlanSections;
    this.hasSections = false;
    if (list.length > 0) {
      this.hasSections = true;
      this.taskPlan.TaskPlanSections = list.sort(function (obj1: TaskPlanSection, obj2: TaskPlanSection) { return obj1.SortID - obj2.SortID; });
      this.firstSectionId = this.taskPlan.TaskPlanSections[0].ID;
      this.lastSectionId = this.taskPlan.TaskPlanSections[this.taskPlan.TaskPlanSections.length - 1].ID;
      if (this.selectedSectionId == 0 || this.selectedSectionId == null || this.selectedSectionId == undefined) {
        this.selectedSectionId = this.taskPlan.TaskPlanSections[0].ID;
      }
      this.selectTaskPlanSections(selectLastSection ? this.lastSectionId : this.selectedSectionId);
    }
  }

  public selectTaskPlanSections(sectionId: number): void {
    this.loadingIndicatorSvc.show();
    this.selectedSectionId = sectionId;
    this.taskPlanSvc.getTaskPlanSubSectionsBySectionID(sectionId)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: TaskPlanSubSection[]) => {
        this.subSectionList = data;
        this.completeTaskPlanSubSectionsList();
      });
  }

  public updateTaskPlanSection(sectionId: number, desc: string): void {
    this._router.navigate(['ord/TaskPlan-section-edit', { taskPlanId: this.taskPlanId, id: sectionId, desc: desc, ordID: this.ordID }]);
  }

  public deleteTaskPlanSection(sectionId: number, desc: string): void {
    this._router.navigate(['ord/TaskPlan-section-delete', { taskPlanId: this.taskPlanId, taskPlanDesc: this.taskPlan.Description, id: sectionId, desc: desc, ordID: this.ordID }]);
  }

  public resetTaskPlans(): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.resetTaskPlan(this.taskPlanId)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        // TO-DO -- add modal
      });
  }

  // ---------------TASK PLAN SUB SECTION---------------//
  private cloneDefaultItemsForGenericSubSection(id: number) {
    this.loadingIndicatorSvc.show();
    // Get Different Equipment Types for the Menu
    this.taskPlanSvc.cloneDefaultItemsForGenericSubSection(id)
      .pipe(
      finalize(() => {
        this.loadingIndicatorSvc.hide();
      })
      ).subscribe((data: any) => {
        this.getTaskPlanById(this.taskPlanId);
        this.subSection.Description = "";
      }, exception => {
        if (exception instanceof HttpErrorResponse) {
          switch (exception.status) {
            case 0:
              this.loadingIndicatorSvc.hide();
              this.messageSvc.clear();
              this.messageSvc.add({
                key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
              });
              break;
          }
        }
      });
  }

  public createSubSection(): void {
    this.displayWarningSubSec = this.taskPlanSvc.isInvalidString(this.subSection.Description);
    if (!this.displayWarningSubSec) {
      // console.log("this.ordID = " + this.ordID);
      if (this.ordID == 1) {
        this.subSection.TaskPlanSectionId = this.selectedSectionId;
        this.loadingIndicatorSvc.show();
        this.taskPlanSvc.createAndCloneTaskPlanSubSection(this.subSection)
          .pipe(
      finalize(() => {
            // this.loadingIndicatorSvc.hide();
            // this.cloneDefaultItemsForGenericSubSection();
          })
        )  .subscribe((data: TaskPlanSubSection) => {
            this.loadingIndicatorSvc.hide();
            this.cloneDefaultItemsForGenericSubSection(data.ID);
          }, exception => {
            if (exception instanceof HttpErrorResponse) {
              switch (exception.status) {
                case 0:
                  this.loadingIndicatorSvc.hide();
                  this.messageSvc.clear();
                  this.messageSvc.add({
                    key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                    detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                  });
                  break;
              }
            }
          });
      } else {
        this.subSection.TaskPlanSectionId = this.selectedSectionId;
        this.loadingIndicatorSvc.show();
        this.taskPlanSvc.createTaskPlanSubSection(this.subSection)
          .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe((data: any) => {
            this.getTaskPlanById(this.taskPlanId);
            this.subSection.Description = "";
          });
      }
    }
  }

  public moveUpTaskPlanSubSection(tpsId: number): void {
    this.loadingIndicatorSvc.show();
    if (this.ordID == 1) {
      this.taskPlanSvc.moveUpTaskPlanSubSectionClone(tpsId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSubSection(tpsId, -1); // MOVE UP
            this.completeTaskPlanSubSectionsList();
          } else {
            // console.log(data.Message);
          }
        });
    } else {
      this.taskPlanSvc.moveUpTaskPlanSubSection(tpsId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSubSection(tpsId, -1); // MOVE UP
            this.completeTaskPlanSubSectionsList();
          } else {
            // console.log(data.Message);
          }
        });
    }
  }

  public moveDownTaskPlanSubSection(tpsId: number): void {
    this.loadingIndicatorSvc.show();
    if (this.ordID == 1) {
      this.taskPlanSvc.moveDownTaskPlanSubSectionClone(tpsId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSubSection(tpsId, 1); // MOVE DOWN
            this.completeTaskPlanSubSectionsList();
          } else {
            // console.log(data.Message);
          }
        });
    } else {
      this.taskPlanSvc.moveDownTaskPlanSubSection(tpsId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapSubSection(tpsId, 1); // MOVE DOWN
            this.completeTaskPlanSubSectionsList();
          } else {
            // console.log(data.Message);
          }
        });
    }
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  swapSubSection(subSectionId: number, possition: number): void {
    var indexItem = this.subSectionList.findIndex(x => x.ID == subSectionId);
    var itemUpSortId = this.subSectionList[indexItem].SortID;
    var itemDownSortId = this.subSectionList[indexItem + possition].SortID;
    this.subSectionList[indexItem].SortID = itemDownSortId;
    this.subSectionList[indexItem + possition].SortID = itemUpSortId;
  }

  completeTaskPlanSubSectionsList(): void {
    var list = this.subSectionList;
    if (list.length > 0) {
      this.subSectionList = list.sort(function (obj1: TaskPlanSubSection, obj2: TaskPlanSubSection) { return obj1.SortID - obj2.SortID; });
      this.firstSubSectionId = this.subSectionList[0].ID;
      this.lastSubSectionId = this.subSectionList[this.subSectionList.length - 1].ID;
      for (let ss of this.subSectionList) {
        this.completeTaskPlanItemList(ss.ID);
      }
    }
  }

  public updateTaskPlanSubSection(id: number): void {
    this._router.navigate(['ord/TaskPlan-sub-section-edit', { taskPlanId: this.taskPlanId, id: id, sectionId: this.selectedSectionId, ordID: this.ordID }]);
  }

  public deleteTaskPlanSubSection(id: number): void {
    var section = this.taskPlan.TaskPlanSections.find(x => x.ID == this.selectedSectionId);
    this._router.navigate(['ord/TaskPlan-sub-section-delete', {
      taskPlanId: this.taskPlanId, id: id, sectionId: this.selectedSectionId, section: section.Description, ordID: this.ordID
    }]);
  }

  //---------------TASK PLAN ITEM---------------//

  public moveUpTaskPlanItem(id: number, subId: number): void {
    this.loadingIndicatorSvc.show();
    if (this.ordID == 1) {
      this.taskPlanSvc.moveUpTaskPlanItemClone(id)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapItem(id, subId, -1); // MOVE UP
            this.completeTaskPlanItemList(subId);
          } else {
            // console.log(data.Message);
          }
        });
    } else {
      this.taskPlanSvc.moveUpTaskPlanItem(id)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapItem(id, subId, -1); // MOVE UP
            this.completeTaskPlanItemList(subId);
          } else {
            // console.log(data.Message);
          }
        });
    }
  }

  public moveDownTaskPlanItem(id: number, subId: number): void {
    this.loadingIndicatorSvc.show();
    if (this.ordID == 1) {
      this.taskPlanSvc.moveDownTaskPlanItemClone(id)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapItem(id, subId, 1); // MOVE DOWN
            this.completeTaskPlanItemList(subId);
          } else {
            // console.log(data.Message);
          }
        });
    } else {
      this.taskPlanSvc.moveDownTaskPlanItem(id)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (data.Success) {
            this.swapItem(id, subId, 1); // MOVE DOWN
            this.completeTaskPlanItemList(subId);
          } else {
            // console.log(data.Message);
          }
        });
    }
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  swapItem(itemId: number, subId: number, possition: number): void {
    var indexSub = this.subSectionList.findIndex(x => x.ID == subId);
    var indexItem = this.subSectionList[indexSub].TaskPlanItems.findIndex(x => x.ID == itemId);
    var itemUpSortId = this.subSectionList[indexSub].TaskPlanItems[indexItem].SortID;
    var itemDownSortId = this.subSectionList[indexSub].TaskPlanItems[indexItem + possition].SortID;
    this.subSectionList[indexSub].TaskPlanItems[indexItem].SortID = itemDownSortId;
    this.subSectionList[indexSub].TaskPlanItems[indexItem + possition].SortID = itemUpSortId;
  }

  completeTaskPlanItemList(subId: number): void {
    var list = this.subSectionList.find(x => x.ID == subId).TaskPlanItems;
    if (list.length > 0) {
      list = list.sort(function (obj1: TaskPlanItem, obj2: TaskPlanItem) { return obj1.SortID - obj2.SortID; });
      this.subSectionList.find(x => x.ID == subId).TaskPlanItems = list;
      this.subSectionList.find(x => x.ID == subId).firstItemId = list[0].ID;
      this.subSectionList.find(x => x.ID == subId).lastItemId = list[list.length - 1].ID;
    }
  }

  public createItem(subSectionId: number): void {
    this._router.navigate(['ord/TaskPlan-item-create', { taskPlanId: this.taskPlanId, subSectionId: subSectionId, sectionId: this.selectedSectionId, ordID: this.ordID }]);
  }

  public updateItem(id: number, subSectionId: number): void {
    this._router.navigate(['ord/TaskPlan-item-edit', { taskPlanId: this.taskPlanId, itemId: id, sectionId: this.selectedSectionId, ordID: this.ordID, subSectionId: subSectionId }]);
  }

  public deleteItem(id: number, subId: number) {
    this.itemToDelete = this.subSectionList.find(x => x.ID == subId).TaskPlanItems.find(x => x.ID == id);
    var message = `Record will be permanently removed, continue?<br/>
                   - Item Code:  <b>` + this.itemToDelete.Code + `</b><br/>
                   - Item Description: <b>` + this.itemToDelete.Description + `</b><br/>`;
    if (this.ordID == 1) {
      this.confirmationService.confirm({
        message: message,
        header: 'Confirm delete',
        accept: () => {
          this.loadingIndicatorSvc.show();
          this.taskPlanSvc.deleteAndCloneTaskPlanItem(id)
            .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
            .subscribe((data: any) => {
              var index = this.subSectionList.find(x => x.ID == subId).TaskPlanItems.findIndex(x => x.ID == id);
              this.subSectionList.find(x => x.ID == subId).TaskPlanItems.splice(index, 1);
              this.completeTaskPlanItemList(subId);
            }, exception => {
              if (exception instanceof HttpErrorResponse) {
                switch (exception.status) {
                  case 0:
                    this.loadingIndicatorSvc.hide();
                    this.messageSvc.clear();
                    this.messageSvc.add({
                      key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                      detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                    });
                    break;
                }
              }
            }
            );
        }
      });
    } else {
      this.confirmationService.confirm({
        message: message,
        header: 'Confirm delete',
        accept: () => {
          this.loadingIndicatorSvc.show();
          this.taskPlanSvc.deleteTaskPlanItem(id)
            .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
            .subscribe((data: any) => {
              var index = this.subSectionList.find(x => x.ID == subId).TaskPlanItems.findIndex(x => x.ID == id);
              this.subSectionList.find(x => x.ID == subId).TaskPlanItems.splice(index, 1);
              this.completeTaskPlanItemList(subId);
            });
        }
      });
    }
  }


  showScheduleDialog(tpsId: number): void {
    this.loadingIndicatorSvc.show();
    this.selectedTaskPlanSection = tpsId;
    this.taskPlanSvc.getAssetScheduleByTaskPlanSectionID(tpsId)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: AssetSchedule) => {
        if (data !== undefined && data != null) {
          if (data.ID > 0) {
            this.requiresSchedule = 1;
            this.selectedSchedule = data.ScheduleID;
            const arrayOfDays: number[] = JSON.parse("[" + data.FrequencyDays + "]");
            this.selectedDays = arrayOfDays;
          } else {
            // Show error
            // No schedule
            this.requiresSchedule = 0;
            this.selectedSchedule = 1;
            this.selectedDays = [];
          }
        } else {
          // No schedule
          this.requiresSchedule = 0;
          this.selectedSchedule = 1;
          this.selectedDays = [];
        }
        this.displaySchedule = true;
      });

  }


  public saveAssetSchedule(): void {
    this.loadingIndicatorSvc.show();
    let freq = "";

    if (this.requiresSchedule == 0) {
      // delete schedule
      this.displaySchedule = false;
      this.taskPlanSvc.deleteAssetSchedule(this.selectedTaskPlanSection)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          this.displaySchedule = false;
        });
    } else {
      if (this.selectedSchedule == 3) {
        if (this.selectedDays.length == 0) {
          // Return error
          this.displayWarningFrequency = true;
          this.loadingIndicatorSvc.hide();
          return;
        } else {
          this.displayWarningFrequency = false;
          const sortedArrayN: number[] = this.selectedDays.sort((n1, n2) => n1 - n2);
          freq = sortedArrayN.toString();
        }
      }

      const asset = new AssetSchedule();
      asset.TaskPlanSectionID = this.selectedTaskPlanSection;
      asset.ScheduleID = this.selectedSchedule;
      asset.FrequencyDays = freq;

      this.disableScheduleButtons = true;
      this.taskPlanSvc.createAssetSchedule(asset)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: AssetSchedule) => {
          if (data.ID > 0) {
            // Close Dialog
            this.displaySchedule = false;
          } else {
            // Show error
            this.displaySchedule = false;
          }
          this.disableScheduleButtons = false;
        }, exception => {
          this.disableScheduleButtons = false;
          if (exception instanceof HttpErrorResponse) {
            switch (exception.status) {
              case 0:
                this.loadingIndicatorSvc.hide();
                this.messageSvc.clear();
                this.messageSvc.add({
                  key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                  detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                });
                break;
            }
          }
        }
        );
    }
  }

}

