<div class="report-module">
    <!--Report Filters-->
    <div class="box-shadow module">
        <div class="row">
            <h3>ORD Report Filters</h3>
        </div>
        <div class="row">
            <!--Select Areas-->
            <div class="form-group col-sm-6">
              <h3>Hierarchy</h3>
  
              <div class="row" style="padding-bottom: 16px">
                  <div class="form-group">
                      <label class="control-label col-md-4" style="text-align:right">Production Unit</label>
                      <label class="control-label col-md-8" style="text-align:left">{{prodUnitName}}</label>
                  </div>
              </div>
  
              <div class="row" style="padding-bottom: 16px">
                  <div class="form-group">
                      <label class="control-label col-md-4" style="text-align:right">Production Sub-Unit</label>
                      <div class="col-md-8 dropdown">                
                          <p-dropdown  [options]="ProdSubUnits" [(ngModel)]="selectedProdSubUnit" optionLabel="Name" [showClear]="true"
                              [filter]="true" (onChange) = "getAreas()" [style]="{'width': '100%'}" placeholder="Select a Production Sub-Unit">
                          </p-dropdown>
                      </div>
                  </div>
              </div>
  
                
              <div class="row" style="padding-bottom: 16px">
                  <div class="form-group">
                      <label class="control-label col-md-4" style="text-align:right">Area</label>
                      <div class="col-md-8 dropdown">                
                          <p-dropdown  [options]="Areas" [(ngModel)]="selectedArea" [showClear]="true" optionLabel="label" [disabled]="disableArea"
                              [filter]="true" (onChange) = "getSubAreas()" [style]="{'width': '100%'}" placeholder="Select an Area">
                          </p-dropdown>
                      </div>
                  </div>
              </div>
  
              <!--SubAreas-->
              <div class="row" style="padding-bottom: 16px">
                  <div class="form-group">
                      <label class="control-label col-md-4" style="text-align:right">Sub-Area</label>
                      <div class="col-md-8 dropdown">                
                          <p-dropdown  [options]="subAreas" [(ngModel)]="selectedSubArea" [disabled]="disableSubArea" optionLabel="label" [showClear]="true"
                          [filter]="true" (onChange) = "getFacilities2()" placeholder="Select a Sub-Area">
                          </p-dropdown>
                      </div>
                  </div>
              </div>
  
              <!--Facilities2-->
              <div class="row" style="padding-bottom: 16px">
                  <div class="form-group">
                      <label class="control-label col-md-4" style="text-align:right">Facility Class 2</label>
                      <div class="col-md-8 dropdown">                
                          <p-dropdown  [options]="Facilities2" [(ngModel)]="selectedFacility2" optionLabel="label" [disabled]="disableFacility2"
                          [showClear]="true" (onChange) = "getFacilities1()" [filter]="true" placeholder="Select a Facility 2">
                          </p-dropdown>
                      </div>
                  </div>
              </div>
  
              <!--Facilities1-->
              <div class="row" style="padding-bottom: 16px">
                  <div class="form-group">
                      <label class="control-label col-md-4" style="text-align:right">Facility Class 1</label>
                      <div class="col-md-8 dropdown">                
                          <p-dropdown  [options]="Facilities1" [(ngModel)]="selectedFacility1" optionLabel="label" [disabled]="disableFacility1"
                          [showClear]="true" (onChange) = "getORDs()" [filter]="true" placeholder="Select a Facility 1">
                          </p-dropdown>
                      </div>
                  </div>
              </div>
  
                <!--ORDs-->
                <div class="row" style="padding-bottom: 16px">
                    <div class="form-group">
                        <label class="control-label col-md-4" style="text-align:right">ORD</label>
                        <div class="col-md-8 dropdown">                
                            <p-dropdown  [options]="ords" [(ngModel)]="selectedORD" optionLabel="label" [disabled]="disableORD"
                            [showClear]="true" [filter]="true" placeholder="Select an ORD" (onChange) = "getEquipments()">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
  
              <!--Equipment-->
              <div class="row" style="padding-bottom: 16px">
                  <div class="form-group">
                      <label class="control-label col-md-4" style="text-align:right">Equipment</label>
                      <div class="col-md-8 dropdown">
                          <p-multiSelect  (onChange) = "saveInLocal('equip', selectedEquipment)" [options]="equips" [(ngModel)]="selectedEquipment" [disabled]="disableEquipment"
                      title="Select an Equipment" placeholder="Select an Equipment" showToggleAll="false" [style]="{'width': '100%'}" >
                          </p-multiSelect>
                      </div>
                  </div>
              </div>
            </div>
            <!--Calendar input-->
            <div class="form-group col-sm-6">
                <h3>Choose Dates</h3>
                <div class="row calendar-div">
                    <div class="form-group">
                        <label class="control-label col-md-2">Start Date</label>
                        <p-calendar [(ngModel)] = "startDate" dateFormat="mm.dd.yy" showIcon="true" placeholder="Enter the start date..."
                            [monthNavigator] = "true" [yearNavigator]="true" yearRange="2018:2030" [readonlyInput]="true"
                            class = "calendar"
                        ></p-calendar>
                    </div>
                </div>
                <div class="row calendar-div">
                    <div class="form-group">
                        <label class="control-label col-md-2">End Date</label>
                        <p-calendar [(ngModel)] = "finishDate" dateFormat="mm.dd.yy" showIcon="true" placeholder="Enter the end date..."
                            [monthNavigator] = "true" [yearNavigator]="true" yearRange="2018:2030" [readonlyInput]="true"
                            class = "calendar">
                        </p-calendar>
                    </div>
                </div>
            </div>
        </div>
        <p-checkbox *ngIf="this.selectedTabReport==1" binary = "true" name="Not_OK_chkbx" label="Show Incomplete Only"
        (onChange)="filterResponses(onlyShowIncomplete, currentTaskPlan)" [(ngModel)]="onlyShowIncomplete"></p-checkbox>
        <!--Button to start report-->
        <div class="row">
            <p-button label="Get Report" (onClick)="getReport()"></p-button>
            <span class="text-danger field-validation-error" *ngIf="ordWarningMsg">
                <span>Please select an ORD.</span>
            </span>
            <span class="text-danger field-validation-error" *ngIf="equipWarningMsg">
                <span>Please select one or more equipments.</span>
          </span>
        </div>
    </div>
    <!--End of Report Filters-->
  
    <!--Report-->
    <div class="box-shadow module" *ngIf="showReportFlag">
        <h3>{{reportName}} Report</h3>
        <div class="row">
            <p-tabView (onChange)="handleChange($event)">
                <p-tabPanel header="ORD View" [selected]="true">
                  <app-field-ord-grid [responses]="responses"></app-field-ord-grid>
                </p-tabPanel>
                <p-tabPanel header="Completed Vs. Expected" [selected]="false">
                  <app-incompletefieldgrid [responsesIncomplete]="responsesIncomplete" [onlyShowIncomplete]="onlyShowIncomplete"></app-incompletefieldgrid>
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
  </div>