import { Component, OnInit, Inject } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ORDTemplate } from '#models/ordTemplate';
import { BusinessUnitsService } from '#services/api/business-unit.service';
import { SelectItem, MessageService, SelectItemGroup } from 'primeng/api';
import { Equipment } from '#models/equipment';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { ORDService } from '#services/api/ord.service';
import { EquipmentEC } from '#models/equipmentEC';
import { BusinessUnit } from '#models/business-unit';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { EquipmentService } from '#services/api/equipment.service';
import { EamEquipment } from '#models/eamEquipment';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-task-plan-create',
  templateUrl: './task-plan-create.component.html',
  styleUrls: ['./task-plan-create.component.scss']
})

export class TaskPlanCreateComponent implements OnInit {
  taskPlan: TaskPlan = new TaskPlan();
  ordTemplate: ORDTemplate = new ORDTemplate();
  Equipments: SelectItem[];
  selectedEquipment: Equipment[];
  manualEnteredEquipment: string[];
  disableGeneric: boolean;
  displayWarningNote: boolean;
  displayWarningCode: boolean;
  displayWarningDesc: boolean;
  GenericEquipments: SelectItem[];
  private storedData: any = [];
  selectedBU: BusinessUnit;
  private loadedEquipment = false;
  private loadedGeneric = false;
  groupedEquipment: SelectItemGroup[];
  groupedEquipmentCopy: SelectItemGroup[];
  groupedEAMEquipment: SelectItemGroup[] = [];
  groupedEAMEquipmentCopy: SelectItemGroup[] = [];
  equipmentWells: SelectItem[];
  ecEquipment: SelectItem;
  selectedEAMEquipment: SelectItem;

  constructor(
    private taskPlanSvc: TaskPlanService,
    private ordSvc: ORDService,
    public buSvc: BusinessUnitsService,
    public omSvc: OMWorkbenchService,
    private eqSvc: EquipmentService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private messageSvc: MessageService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
  }

  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  ngOnInit() {
    this.Equipments = [];
    this.GenericEquipments = [];
    this.selectedEquipment = [];
    this.displayWarningNote = false;
    this.displayWarningCode = false;
    this.displayWarningDesc = false;
    this.disableGeneric = true;
    this.taskPlan.IsGeneric = false;
    this.groupedEquipment = [];
    this.groupedEquipmentCopy = [];
    this.equipmentWells = [];
    this.getFromLocal("BU");
    this.selectedBU = this.storedData["BU"];

    this.aRoute.params.subscribe(v => {
      this.taskPlan.ORDTemplateID = v['ordID'];
    });

    this.loadingIndicatorSvc.show();
    this.ordSvc.getORDTemplateByID(this.taskPlan.ORDTemplateID)
      .pipe(
        finalize(() => {

        }))
      .subscribe((data: ORDTemplate) => {
        this.ordTemplate = data;
        this.getEquipment();
      });
    this.loadGenericEquipment();
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  private getWells(): void {
    this.omSvc.getWell(this.ordTemplate.RouteID)
      .pipe(
        finalize(() => {
          this.loadedEquipment = true;
          if (this.loadedGeneric) {
            this.loadingIndicatorSvc.hide();
          }
        }))
      .subscribe((data: Map<string, string>[]) => {
        data.forEach((eqEC) => {
          const equipmentEC = new EquipmentEC();
          equipmentEC.ID = eqEC["ID"];
          equipmentEC.Name = eqEC["Name"];
          equipmentEC.RouteId = "";
          equipmentEC.EquipmentType = "WELL";
          equipmentEC.CreatedDate = new Date(eqEC["CreatedDate"]);
          equipmentEC.EndDate = null;
          equipmentEC.CollectionPointId = eqEC["CollectionPointID"];
          const equipment = new Equipment(equipmentEC);
          const element: SelectItem = { label: equipment.Name, value: equipment };
          this.equipmentWells.push(element);
        });
        const groupWells: SelectItemGroup = { label: "Wells", items: this.equipmentWells };
        this.groupedEquipment.push(groupWells);
        const groupEquipment: SelectItemGroup = { label: "Equipments", items: this.Equipments };
        this.groupedEquipment.push(groupEquipment);
        this.groupedEquipmentCopy = this.groupedEquipment;
      },
        e => {
          this.showError(e, 'Failed to get Wells from EC');
        });
  }

  private getEquipment(): void {
    if (this.selectedBU.LinkToEquipment == "EAM") {
      this.getEAMEquipment();
    } else if (this.selectedBU.LinkToEquipment == "EC") {
      this.getECEquipment();
    }

  }

  private getECEquipment(): void {
    this.omSvc.getEquipment(this.ordTemplate.RouteID)
      .pipe(
        finalize(() => {
          this.getWells();
        }))
      .subscribe((data: EquipmentEC[]) => {
        data.forEach((eqEC) => {
          const equipment = new Equipment(eqEC);
          const element: SelectItem = { label: equipment.Name, value: equipment };
          this.Equipments.push(element);
        });
      },
        e => {
          this.showError(e, 'Failed to get Equipment from EC');
        });
  }

  private getEAMEquipment(): void {
    this.eqSvc.getEAMEquipmentByRoute(this.ordTemplate.RouteID)
      .pipe(
        finalize(() => {
          this.loadedEquipment = true;
          if (this.loadedGeneric) {
            this.loadingIndicatorSvc.hide();
          }
        }))
      .subscribe((eamEquipments: EamEquipment[]) => {
        let eqTypes: string[] = [];
        eamEquipments.forEach((eq) => {
          const eqTypeLabel = eq.EAMDescription.split(";")[0];  // Isolate Object Type prefix from description
          const descriptionLabel = `${eq.EAMDescription.split(";")[1].trim()} - ${eq.ECName.trim()}`;  // Remove Object Type prefix from description and concat EC Name
          const element: SelectItem = { label: descriptionLabel, value: eq };
          if (!eqTypes.includes(eqTypeLabel)) {
            eqTypes.push(eqTypeLabel);
            const newSelectItemGroup: SelectItemGroup = { label: eqTypeLabel, items: [element] }
            this.groupedEAMEquipment.push(newSelectItemGroup);
            this.groupedEAMEquipment.sort((a, b) => (a.label > b.label) ? 1 : -1);
          }
          else {
            let selectedGroup = this.groupedEAMEquipment.find(group => group.label == eqTypeLabel);
            selectedGroup.items.push(element);
            selectedGroup.items.sort((a, b) => (a.label > b.label) ? 1 : -1);
          }
        });
        this.groupedEAMEquipmentCopy=this.groupedEAMEquipment;
      },
        e => {
          this.showError(e, 'Failed to get Equipment from EAM');
        });
  }

  private loadGenericEquipment() {
    const buID = this.selectedBU.Id;
    this.taskPlanSvc.getTaskPlanSimple(1, buID)
      .pipe(
        finalize(() => {
          this.loadedGeneric = true;
          if (this.loadedEquipment) {
            this.loadingIndicatorSvc.hide();
          }
        }))
      .subscribe((data: TaskPlan[]) => {
        data.forEach((tPlan) => {
          this.GenericEquipments.push({ label: tPlan.Description, value: tPlan.ID });
        });
      });
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/TaskPlan', { id: this.taskPlan.ORDTemplateID }]);
  }

  public createTaskPlan(): void {
    if (this.selectedBU.LinkToEquipment == "EC" && this.ecEquipment != undefined) {
      // Create array of selected Equipment
      this.taskPlan.Equipment = [];
      this.taskPlan.ECObjectID = this.ecEquipment.value.ObjectId;
      this.taskPlan.ECObjectType = this.ecEquipment.value.EquipmentType;
    } else if (this.selectedBU.LinkToEquipment == "EAM" && this.selectedEAMEquipment != undefined) {
      this.taskPlan.EAMCode = this.selectedEAMEquipment.value.EAMCode;
      this.taskPlan.ECObjectID = this.selectedEAMEquipment.value.ECObjectID;
      this.taskPlan.ECObjectType = this.selectedEAMEquipment.value.ECType;
      this.taskPlan.Equipment = [];
    }
    const invalidCode = this.taskPlan.Code === undefined || this.taskPlan.Code == null || this.taskPlan.Code.length === 0;
    const invalidDesc = this.taskPlan.Description === undefined || this.taskPlan.Description == null || this.taskPlan.Description.length === 0;
    this.displayWarningCode = invalidCode;
    this.displayWarningDesc = invalidDesc;
    if (!invalidCode && !invalidDesc) {
      this.loadingIndicatorSvc.show();
      this.taskPlan.BusinessUnitID = 1;
      this.taskPlanSvc.createTaskPlanV2(this.taskPlan)
        .pipe(
          finalize(() => {
            this.loadingIndicatorSvc.hide();
            // this.onRedirectButtonClick();
          }))
        .subscribe((data: TaskPlan) => {
          this.copyTaskPlan(this.taskPlan.GenericParentID, data.ID);
        },
          e => {
            this.showError(e, 'Failed to fully create Task Plan');
          });
    }
  }

  public copyTaskPlan(sourceID: number, destinationID: number): void {
    this.loadingIndicatorSvc.show();

    if (sourceID === undefined || sourceID == null) {
      this.loadingIndicatorSvc.hide();
      this.onRedirectButtonClick();
    } else {
      // Grab Subsequent SubArea Ids
      this.taskPlanSvc.copyTaskPlan(sourceID, destinationID, true)
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {

          this.cloneTaskPlanScheduleFromGeneric(destinationID);

        },
          e => {
            this.showError(e, 'Failed to create the Generic Tasks.');
          });
    }
  }


  public cloneTaskPlanScheduleFromGeneric(taskPlanID: number): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.cloneTaskPlanSchedule(taskPlanID)
      .pipe(
        finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        this.onRedirectButtonClick();
      },
        e => {
          this.showError(e, 'Failed to create the Generic Schedule.');
        });
  }
}

