<style>
.centerButtonContainer {
    display: flex;
    justify-content: center;
}
</style>
<div class="box-shadow module" style="width:80%; margin:auto; background-color: midnightblue; color: white">
    <h2>Delete ORD Part/Location</h2>
    <hr>
    <h3>Are you sure you want to delete <span style="color:red">{{taskPlanSectionDesc}}</span> <br> from {{taskPlanDesc}} ?</h3>
    <h4>All the related subsection(s) | item(s) will be removed.</h4>
    <h4><span style="color:red">Deleting a Generic Section will delete all of its dependencies.</span></h4>   
    <div class="form-actions no-color">
        <div class="centerButtonContainer">
            <a class="btn btn-danger" style="width: 100px;" (click)="deleteTaskPlanSection()" >Delete</a>
            &nbsp;
            &nbsp;
            <a class="btn btn-primary" style="width: 100px;" (click)="onRedirectButtonClick()">Back</a>
        </div>
    </div>  
</div>