import { TaskPlan } from 'models/task-plan';
import { TaskPlanSection } from 'models/task-plan-section';
import { TaskPlanSubSection } from 'models/task-plan-sub-section';
import { TaskPlanItem } from 'models/task-plan-item';
import { TaskPlanItemOption } from 'models/task-plan-item-option';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { AssetSchedule } from '#models/assetSchedule';

@Injectable()
export class TaskPlanService {
    constructor(
        private http: HttpClient
    ) { }

    // ---------------TASK PLAN------------------------------------
    public getTaskPlans(): Observable<TaskPlan[]> {
        return this.http.get<TaskPlan[]>(`${environment.apiEndpoint}/task-plan`);
    }

    public getTaskPlanById(id: number): Observable<TaskPlan[]> {
        return this.http.get<TaskPlan[]>(`${environment.apiEndpoint}/task-plan?id=${id}`);
    }

    public getTaskPlanByTemplateId(id: number): Observable<TaskPlan[]> {
        return this.http.get<TaskPlan[]>(`${environment.apiEndpoint}/task-plan?ordTemplateId=${id}`);
    }

    public getTaskPlanNamesByORDId(ordID: number): Observable<TaskPlan[]> {
        return this.http.get<TaskPlan[]>(`${environment.apiEndpoint}/task-plan/GetTaskPlanNamesByORD?ordID=${ordID}`);
    }
    
    public getTaskPlanByTemplateIdWithDeleted(id: number, startDate: Date, responseStartDate: Date): Observable<TaskPlan[]> {
        const dateString = startDate.toISOString();
        const ordResponseDateString = responseStartDate.toISOString();
        return this.http.get<TaskPlan[]>(`${environment.apiEndpoint}/task-plan?ordTemplateId=${id}&isDeleted=true&StartDate=${dateString}&ordResponseDate=${ordResponseDateString}`);
    }

    // Used for Field ORDs
    public getTaskPlanByTemplateIdForSection(id: number, startDate: Date, responseStartDate: Date, sectionID: number): Observable<TaskPlan[]> {
        const dateString = startDate.toISOString();
        const ordResponseDateString = responseStartDate.toISOString();
        return this.http.get<TaskPlan[]>(`${environment.apiEndpoint}/task-plan?ordTemplateId=${id}&isDeleted=true&StartDate=${dateString}&ordResponseDate=${ordResponseDateString}&taskPlanSectionID=${sectionID}`);
    }

    public getTaskPlanSimple(ordTemplateID: number, businessUnitID: number): Observable<TaskPlan[]> {
        return this.http.get<TaskPlan[]>(`${environment.apiEndpoint}/task-plan/simple?oRDTemplateID=${ordTemplateID}&businessUnitID=${businessUnitID}`);
    }

    public createTaskPlan(taskPlan: TaskPlan): Observable<TaskPlan> {
        return this.http.post<TaskPlan>(`${environment.apiEndpoint}/task-plan/createTaskPlan`, taskPlan);
    }

    // V2 returns the ID of the TaskPlan instead of a success message.
    public createTaskPlanV2(taskPlan: TaskPlan): Observable<TaskPlan> {
        return this.http.post<TaskPlan>(`${environment.apiEndpoint}/task-plan/createTaskPlanV2`, taskPlan);
    }

    public updateTaskPlans(taskPlans: TaskPlan[]): Observable<TaskPlan> {
        return this.http.put<TaskPlan>(`${environment.apiEndpoint}/task-plan/updateTaskPlan`, taskPlans);
    }

    public moveUpTaskPlan(id: number): Observable<TaskPlan> {
        return this.http.put<TaskPlan>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlan/${id}`, null);
    }

    public moveDownTaskPlan(id: number): Observable<TaskPlan> {
        return this.http.put<TaskPlan>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlan/${id}`, null);
    }

    public deleteTaskPlan(id: number): Observable<TaskPlan> {
        return this.http.put<TaskPlan>(`${environment.apiEndpoint}/task-plan/deleteTaskPlan/${id}`, null);
    }

    public cloneTaskPlan(id: number): Observable<TaskPlan> {
        return this.http.post<TaskPlan>(`${environment.apiEndpoint}/task-plan/cloneTaskPlan/${id}`, null);
    }

    public resetTaskPlan(id: number): Observable<TaskPlan> {
        return this.http.put<TaskPlan>(`${environment.apiEndpoint}/task-plan/resetTaskPlan/${id}`, null);
    }

    public copyTaskPlan(sourceTaskPlanID: number, destinationTaskPlanID: number, isNew: boolean): Observable<TaskPlan> {
        return this.http.post<TaskPlan>(`${environment.apiEndpoint}/task-plan/copyTaskPlan?sourceTaskPlanID=${sourceTaskPlanID}&destinationTaskPlanID=${destinationTaskPlanID}&isNew=${isNew}`, null);
    }

    public cloneTaskPlanSchedule(taskPlanID: number): Observable<TaskPlan> {
        return this.http.post<TaskPlan>(`${environment.apiEndpoint}/task-plan/cloneTaskPlanSchedule?taskPlanID=${taskPlanID}`, null);
    }

    public deleteTaskPlanGenericContents(taskPlan: TaskPlan, genericParentID: Number): Observable<TaskPlan> {
        return this.http.post<TaskPlan>(`${environment.apiEndpoint}/task-plan/deleteGenericTaskPlanContents?currentTaskPlanID=${taskPlan.ID}&genericParentID=${genericParentID}`, null);
    }

    // ---------------TASK PLAN SECTION------------------------------------
    public getTaskPlanSectionById(id: number): Observable<TaskPlanSection[]> {
        return this.http.get<TaskPlanSection[]>(`${environment.apiEndpoint}/task-plan/getTaskPlanSections?id=${id}`);
    }

    public createTaskPlanSection(taskPlanSection: TaskPlanSection): Observable<TaskPlanSection> {
        return this.http.post<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/createTaskPlanSection`, taskPlanSection);
    }

    public createAndCloneTaskPlanSection(taskPlanSection: TaskPlanSection): Observable<TaskPlanSection> {
        return this.http.post<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/createAndCloneTaskPlanSection`, taskPlanSection);
    }

    public updateTaskPlanSection(taskPlanSection: TaskPlanSection): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/updateTaskPlanSection`, taskPlanSection);
    }

    public updateAndCloneTaskPlanSection(taskPlanSection: TaskPlanSection): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/updateAndCloneTaskPlanSection`, taskPlanSection);
    }

    public deleteTaskPlanSection(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/deleteTaskPlanSection/${id}`, null);
    }

    public deleteAndCloneTaskPlanSection(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/deleteAndCloneTaskPlanSection/${id}`, null);
    }

    public moveUpTaskPlanSection(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanSection/${id}`, null);
    }

    public moveUpTaskPlanSectionClone(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanSectionClone/${id}`, null);
    }

    public moveDownTaskPlanSection(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanSection/${id}`, null);
    }

    public moveDownTaskPlanSectionClone(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanSectionClone/${id}`, null);
    }
    // ---------------TASK PLAN SUB SECTION------------------------------------
    public getTaskPlanSubSectionsBySectionID(id: number): Observable<TaskPlanSubSection[]> {
        return this.http.get<TaskPlanSubSection[]>(`${environment.apiEndpoint}/task-plan/getTaskPlanSubSections?TaskPlanSectionID=${id}`);
    }

    public getTaskPlanSubSectionById(id: number): Observable<TaskPlanSubSection[]> {
        return this.http.get<TaskPlanSubSection[]>(`${environment.apiEndpoint}/task-plan/getTaskPlanSubSections?ID=${id}`);
    }

    public createTaskPlanSubSection(taskPlanSubSection: TaskPlanSubSection): Observable<TaskPlanSubSection> {
        return this.http.post<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/createTaskPlanSubSection`, taskPlanSubSection);
    }

    public createAndCloneTaskPlanSubSection(taskPlanSubSection: TaskPlanSubSection): Observable<TaskPlanSubSection> {
        return this.http.post<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/createAndCloneTaskPlanSubSection`, taskPlanSubSection);
    }

    public cloneDefaultItemsForGenericSubSection(taskPlanSubSectionID: number): Observable<TaskPlanSubSection> {
        return this.http.post<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/cloneDefaultItemsForGenericSubSection/${taskPlanSubSectionID}`, null);
    }

    public updateTaskPlanSubSection(taskPlanSubSection: TaskPlanSubSection): Observable<TaskPlanSubSection> {
        return this.http.put<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/updateTaskPlanSubSection`, taskPlanSubSection);
    }

    public updateAndCloneTaskPlanSubSection(taskPlanSubSection: TaskPlanSubSection): Observable<TaskPlanSubSection> {
        return this.http.put<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/updateAndCloneTaskPlanSubSection`, taskPlanSubSection);
    }

    public deleteTaskPlanSubSection(id: number): Observable<TaskPlanSubSection> {
        return this.http.put<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/deleteTaskPlanSubSection/${id}`, null);
    }

    public deleteAndCloneTaskPlanSubSection(id: number): Observable<TaskPlanSubSection> {
        return this.http.put<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/deleteAndCloneTaskPlanSubSection/${id}`, null);
    }

    public moveUpTaskPlanSubSection(id: number): Observable<TaskPlanSubSection> {
        return this.http.put<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanSubSection/${id}`, null);
    }

    public moveUpTaskPlanSubSectionClone(id: number): Observable<TaskPlanSubSection> {
        return this.http.put<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanSubSectionClone/${id}`, null);
    }

    public moveDownTaskPlanSubSection(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanSubSection/${id}`, null);
    }

    public moveDownTaskPlanSubSectionClone(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanSubSectionClone/${id}`, null);
    }

    // ---------------TASK PLAN ITEM ------------------------------------
    public getTaskPlanItemById(id: number): Observable<TaskPlanItem[]> {
        return this.http.get<TaskPlanItem[]>(`${environment.apiEndpoint}/task-plan/getTaskPlanItems?id=${id}`);
    }

    public createTaskPlanItem(taskPlanItem: TaskPlanItem): Observable<TaskPlanItem> {
        return this.http.post<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/createTaskPlanItem`, taskPlanItem);
    }
    public createAndCloneTaskPlanItem(taskPlanItem: TaskPlanItem): Observable<TaskPlanItem> {
        return this.http.post<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/createAndCloneTaskPlanItem`, taskPlanItem);
    }
    public updateTaskPlanItem(taskPlanItem: TaskPlanItem): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/updateTaskPlanItem`, taskPlanItem);
    }
    public updateAndCloneTaskPlanItem(taskPlanItem: TaskPlanItem): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/updateAndCloneTaskPlanItem`, taskPlanItem);
    }
    public updateTaskPlanItemStatusbox(taskPlanItem: TaskPlanItem): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/updateTaskPlanItemStatusbox`, taskPlanItem);
    }
    public deleteTaskPlanItem(id: number): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/deleteTaskPlanItem/${id}`, null);
    }
    public deleteAndCloneTaskPlanItem(id: number): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/deleteAndCloneTaskPlanItem/${id}`, null);
    }
    public moveUpTaskPlanItem(id: number): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanItem/${id}`, null);
    }

    public moveUpTaskPlanItemClone(id: number): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanItemClone/${id}`, null);
    }

    public moveDownTaskPlanItem(id: number): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanItem/${id}`, null);
    }

    public moveDownTaskPlanItemClone(id: number): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanItemClone/${id}`, null);
    }
    // ------ Task-Plan-Item-Option
    public getItemOptionById(id: number): Observable<TaskPlanItemOption> {
        return this.http.get<TaskPlanItemOption>(`${environment.apiEndpoint}/task-plan/getTaskPlanItemOption/${id}`);
    }

    public getItemOptionByItemId(id: number): Observable<TaskPlanItemOption[]> {
        return this.http.get<TaskPlanItemOption[]>(`${environment.apiEndpoint}/task-plan/getTaskPlanItemOptionsByItemID/${id}`);
    }

    public createItemOptions(taskPlanItemOptions: TaskPlanItemOption[]): Observable<TaskPlanItemOption[]> {
        return this.http.post<TaskPlanItemOption[]>(`${environment.apiEndpoint}/task-plan/createTaskPlanItemOption`, taskPlanItemOptions);
    }
    public createAndCloneItemOptions(taskPlanItemOptions: TaskPlanItemOption[]): Observable<TaskPlanItemOption[]> {
        return this.http.post<TaskPlanItemOption[]>(`${environment.apiEndpoint}/task-plan/createAndCloneTaskPlanItemOption`, taskPlanItemOptions);
    }
    public updateItemOptions(taskPlanItemOptions: TaskPlanItemOption[]): Observable<TaskPlanItemOption[]> {
        return this.http.put<TaskPlanItemOption[]>(`${environment.apiEndpoint}/task-plan/updateTaskPlanItemOption`, taskPlanItemOptions);
    }
    public updateAndCloneItemOptions(taskPlanItemOptions: TaskPlanItemOption[]): Observable<TaskPlanItemOption[]> {
        return this.http.put<TaskPlanItemOption[]>(`${environment.apiEndpoint}/task-plan/updateAndCloneTaskPlanItemOption`, taskPlanItemOptions);
    }
    public deleteItemOption(id: number): Observable<TaskPlanItemOption> {
        return this.http.put<TaskPlanItemOption>(`${environment.apiEndpoint}/task-plan/deleteTaskPlanItemOption/${id}`, null);
    }
    public deleteAndCloneItemOption(id: number): Observable<TaskPlanItemOption> {
        return this.http.put<TaskPlanItemOption>(`${environment.apiEndpoint}/task-plan/deleteAndCloneTaskPlanItemOption/${id}`, null);
    }
    public moveUpTaskPlanItemOption(id: number): Observable<TaskPlanItemOption> {
        return this.http.put<TaskPlanItemOption>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanItemOption/${id}`, null);
    }

    public moveDownTaskPlanItemOption(id: number): Observable<TaskPlanItemOption> {
        return this.http.put<TaskPlanItemOption>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanItemOption/${id}`, null);
    }

    // ------ AssetSchedule
    public createAssetSchedule(assetSchedule: AssetSchedule): Observable<AssetSchedule> {
        return this.http.post<AssetSchedule>(`${environment.apiEndpoint}/assetSchedule/addAssetSchedule`, assetSchedule);
    }

    public deleteAssetSchedule(id: number): Observable<AssetSchedule> {
        return this.http.put<AssetSchedule>(`${environment.apiEndpoint}/assetSchedule/deleteAssetSchedule/${id}`, null);
    }

    public getAssetScheduleByTaskPlanSectionID(id: number): Observable<AssetSchedule> {
        return this.http.get<AssetSchedule>(`${environment.apiEndpoint}/assetSchedule/getAssetScheduleByTaskPlanSectionID/${id}`);
    }

    // ------ GENERAL FUNCTION
    public isInvalidString(value: string): boolean {
        return value === undefined || value == null || value.length === 0;
    }

    public isInvalidInt(value: number): boolean {
        return value === undefined || value == null || value === 0;
    }

    // https://angular.io/guide/http#getting-error-details
    // private handleError(error: HttpErrorResponse) {
    //     if (error.error instanceof ErrorEvent) {
    //       // A client-side or network error occurred. Handle it accordingly.
    //       console.error('An error occurred:', error.error.message);
    //     } else {
    //       // The backend returned an unsuccessful response code.
    //       // The response body may contain clues as to what went wrong,
    //       console.error(
    //         `Backend returned code ${error.status}, ` +
    //         `body was: ${error.error}`);
    //     }
    //     // return an observable with a user-facing error message
    //     return throwError('Something bad happened; please try again later.');
    // }
}

