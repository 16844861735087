<div class="box-shadow module form-horizontal" style="margin: auto auto 20px; width: 80%;">
    <h3>Create ORD Item for Operator Task - {{subSectionDesc}}</h3>

    <div class="form-group">
        <label class="control-label col-md-2">Description</label>
        <div class="col-md-10">
            <input class="form-control text-box single-line" [(ngModel)]="taskPlanItem.Description">
            <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
                <span>The Description field is required.</span>
            </span>
            <span class="text-danger field-validation-error" *ngIf="displayNullValueWarning">
                <span>Options must not be blank/null.</span>
            </span>
        </div>
    </div>

    <div class="form-group">
        <div class="flex align-items-center" style="width: 14%; float: left;">
            <!-- <label style="padding-right: 10px;"> -->
            <p-radioButton name="ItemOption" value="Dropdown" [(ngModel)]="selectedItemOptionType" inputId="option1"
                (onClick)="showItemOptions('Dropdown')"></p-radioButton>
            <label for="option1" class="ml-2">Buttons</label>
        </div>
        <div class="flex align-items-center" style="width: 14%; float: left;">
            <!-- </label>
            <label style="padding-right: 10px;"> -->
            <p-radioButton name="ItemOption" value="Checkbox" [(ngModel)]="selectedItemOptionType" inputId="option2"
                (onClick)="showItemOptions('Checkbox')"></p-radioButton>
            <label for="option2" class="ml-2">Dropdown</label>
        </div>
        <div class="flex align-items-center" >
            <!-- </label> -->
            <!-- <label style="padding-right: 10px;"> -->
            <p-radioButton name="ItemOption" value="Numeric" [(ngModel)]="selectedItemOptionType" inputId="option3"
                (onClick)="showItemOptions('Numeric')"></p-radioButton>
            <label for="option3" class="ml-2">Numeric Input</label>
        </div>
        <div class="flex align-items-center" style="width: 14%; float: left;">
            <!-- Numeric Input
            </label>
            <label style="padding-right: 10px;"> -->
            <p-radioButton name="ItemOption" value="Textfield" [(ngModel)]="selectedItemOptionType"
                inputId="option4" (onClick)="showItemOptions('Textfield')"></p-radioButton>
            <label for="option4" class="ml-2">Text Field</label>
            <!-- Text Field -->
        </div>
        <!-- </label> -->
        <div class="flex align-items-center" style="width: 14%; float: left;"><!-- <label style="padding-right: 10px;"> -->
            <p-radioButton name="ItemOption" value="Multiple-Dropdown" [(ngModel)]="selectedItemOptionType"
                inputId="option5" (onClick)="showItemOptions('Multiple-Dropdown')"></p-radioButton>
            <label for="option5" class="ml-2">Multiple Dropdown</label>
            <!-- </label> -->
        </div>

    </div>
    <div class="col-md-4" style="text-align: left">
        <span class="text-danger field-validation-error" *ngIf="displayOptionWarning">
            <span>At least one option is required</span>
        </span>
        <br>
        <span class="text-danger field-validation-error" *ngIf="displayNumericWarning">
            <span>Missing Numeric high, low, or units Values</span>
        </span>
    </div>

</div>


<div class="ItemOptionContent">
    <!--Start of Item Options, display content based on what radio button is chosen-->
    <!--Dropdown Item Options-->
    <div class="DropdownOptions" style="padding-left: 19%; padding-bottom: 5%" *ngIf="displayDropdown">
        <div class='col-sm-4' [formGroup]="form">
            <label>Please add values to your buttons list:</label>
            <input #newInput id="Dropdown-input" type="text" size="30" (keyup.enter)="addItemOption(newInput.value)"
                formControlName="name" />
            <button id="dropdown-addOption-btn" type="button" (click)="addItemOption(newInput.value)" pButton
                label="Add Option"></button>
        </div>
        <div class='col-sm-4'>
            <div *ngIf="taskPlanItem.TaskPlanItemOptions.length > 0">
                <h3 class="subSectionHeader">
                    <span class="text-tab">Dropdown Options</span>
                </h3>
            </div>
            <!-- dropdown option list -->
            <table class="table table-hover" style="width:100%; margin-bottom: 20px; border-color: black;" frame="box"
                bordercolor="black">
                <tr *ngFor="let itemOp of taskPlanItem.TaskPlanItemOptions">
                    <td style="width:80%">
                        <label>
                            {{itemOp.Description}}
                        </label>
                    </td>
                    <td>
                        <a (click)="moveUpItemOption(itemOp.Description)">
                            <img src="assets/images/upTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                *ngIf="!itemOp.isFirstOption" />
                        </a>
                    </td>
                    <td>
                        <a (click)="moveDownItemOption(itemOp.Description)">
                            <img src="assets/images/dnTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                *ngIf="!itemOp.isLastOption" />
                        </a>
                    </td>
                    <td>
                        <a (click)="deleteItemOption(itemOp.Description)">
                            <img src="assets/images/cross_color.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                        </a>
                    </td>
                </tr>
            </table> <!-- end dropdown option list -->
        </div>
    </div>
    <!--End of Dropdown Item Options-->
    <!--Checkbox Item Options-->
    <div class="CheckboxOptions" style="padding-left: 19%; padding-bottom: 5%" *ngIf="displayCheckbox">
        <div class='col-sm-4' [formGroup]="form">
            <label>Please add values to your dropdown list:</label>
            <input #newInput id="Checkbox-input" type="text" size="30" (keyup.enter)="addItemOption(newInput.value)"
                formControlName="name" />
            <button id="Checkbox-addOption-btn" type="button" (click)="addItemOption(newInput.value)" pButton
                label="Add Option"></button>
        </div>
        <div class='col-sm-4'>
            <div *ngIf="taskPlanItem.TaskPlanItemOptions.length > 0">
                <h3 class="subSectionHeader">
                    <span class="text-tab">Checkbox Options</span>
                </h3>
            </div>
            <!-- checkbox option list -->
            <table class="table table-hover" style="width:100%; margin-bottom: 0px; border-color: black;" frame="box"
                bordercolor="black">
                <tr *ngFor="let itemOp of taskPlanItem.TaskPlanItemOptions">
                    <td style="width:80%">
                        <label>
                            {{itemOp.Description}}
                        </label>
                    </td>
                    <td>
                        <a (click)="moveUpItemOption(itemOp.Description)">
                            <img src="assets/images/upTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                *ngIf="!itemOp.isFirstOption" />
                        </a>
                    </td>
                    <td>
                        <a (click)="moveDownItemOption(itemOp.Description)">
                            <img src="assets/images/dnTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                *ngIf="!itemOp.isLastOption" />
                        </a>
                    </td>
                    <td>
                        <a (click)="deleteItemOption(itemOp.Description)">
                            <img src="assets/images/cross_color.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                        </a>
                    </td>
                </tr>
            </table> <!-- end checkbox option list -->
        </div>
    </div>
    <!--End of Checkbox Item Options-->
    <!--Numeric Item Options-->
    <div class="NumericOptions" style="padding-left: 19%; padding-bottom: 5%" *ngIf="displayNumeric">
        <div class='col-sm-4' style="text-align: right">
            <div class=row style="padding-bottom: 2%">
                <label style="padding-right: 2%">Low Range: </label>
                <p-spinner [step]="0.1" #Low size="30" [(ngModel)]="LowRange" [max]="HighRange"
                    (keydown.enter)="focusHighInput()"></p-spinner>
            </div>
            <div class=row style="padding-bottom: 2%">
                <label style="padding-right: 2%">High Range: </label>
                <p-spinner [step]="0.1" #High size="30" [(ngModel)]="HighRange" [min]="LowRange"></p-spinner>
            </div>
            <div class=row style="padding-bottom: 2%">
                <label style="padding-right: 2%">Unit of Measure: </label>
                <input #units id="Numeric-input" type="text" size="25" [(ngModel)]="Units"
                    (keyup.enter)="addItemOption(Low.value + ' ' + Units + ' - ' + High.value + ' ' +  Units)" />
                <p-button id="Numeric-addOption-btn"
                    (onClick)="addItemOption(Low.value + ' ' + Units + ' - ' + High.value + ' ' +  Units)"
                    icon="pi pi-check"></p-button>
            </div>
        </div>
        <div class='col-sm-4'>
            <div *ngIf="taskPlanItem.TaskPlanItemOptions.length > 0">
                <h3 class="subSectionHeader">
                    <span class="text-tab">Range and Units</span>
                </h3>
            </div>
            <!-- numeric option list -->
            <table class="table table-hover" style="width:100%; margin-bottom: 0px; border-color: black;" frame="box"
                bordercolor="black">
                <tr *ngFor="let itemOp of taskPlanItem.TaskPlanItemOptions">
                    <td style="width:80%">
                        <label>
                            {{LowRange}} {{Units}} - {{HighRange}} {{Units}}
                        </label>
                    </td>
                    <td>
                        <a (click)="deleteItemOption(itemOp)">
                            <img src="assets/images/cross_color.png" class="imageIcon-sm ShowOnHover" />
                        </a>
                    </td>
                </tr>
            </table> <!-- end numeric option list -->
        </div>
    </div>
    <!--End of Numeric Item Options-->
    <!--Text Field Item Options-->
    <div class="TextFieldOptions" style="padding-left: 19%; padding-bottom: 5%" *ngIf="displayTextField">
        <div class='col-sm-4' [formGroup]="form">
            <label>Please Enter a Title for the Text Field:</label>
            <input #newInput id="textField-input" type="text" size="30" (keyup.enter)="addItemOption(newInput.value)"
                formControlName="name" />
            <button id="Checkbox-addOption-btn" type="button" (click)="addItemOption(newInput.value)" pButton
                label="Add Title"></button>
        </div>
        <div class='col-sm-4'>
            <div *ngIf="taskPlanItem.TaskPlanItemOptions.length > 0">
                <h3 class="subSectionHeader">
                    <span class="text-tab">Text Field Title</span>
                </h3>
            </div>
            <!-- text field table -->
            <table class="table table-hover" style="width:100%; margin-bottom: 0px; border-color: black;" frame="box"
                bordercolor="black">
                <tr *ngFor="let itemOp of taskPlanItem.TaskPlanItemOptions">
                    <td style="width:80%">
                        <label>
                            {{itemOp.Description}}
                        </label>
                    </td>

                </tr>
            </table> <!-- end text field table -->
        </div>
    </div>
    <!--End of Text Field Item Options-->

    <!--Multiple Dropdown-->
    <div class="MultipleDropdownOptions" style="padding-left: 19%; padding-bottom: 5%;" *ngIf="displayMultipleDropdown">
        <div class="col-sm-4" [formGroup]="form">
            <label>Please Add Values to Multiple Dropdown:</label>
            <input #newInput id="MultipleDropdown-input" type="text" size="30"
                (keyup.enter)="addItemOption(newInput.value)" formControlName="name" />
            <button id="Checkbox-addOption-btn" type="button" (click)="addItemOption(newInput.value)" pButton
                label="Add Option"></button>
        </div>
        <div class="col-sm-4">
            <div *ngIf="taskPlanItem.TaskPlanItemOptions.length > 0">
                <h3 class="subSectionHeader">
                    <span class="text-tab">Multiple-Dropdown Options</span>
                </h3>
            </div>
            <!-- text field table -->
            <table class="table table-hover" style="width: 100%; margin-bottom: 0px; border-color: black;" frame="box"
                bordercolor="black">
                <tr *ngFor="let itemOp of taskPlanItem.TaskPlanItemOptions">
                    <td style="width: 80%;">
                        <label>
                            {{ itemOp.Description }}
                        </label>
                    </td>
                </tr>
            </table>
            <!-- end text field table -->
        </div>
    </div>
    <!--End of Multiple Dropdown-->

</div>
<div class="form-group">
    <div class="col-md-6" style="padding-left: 19%;">
        <p-checkbox [(ngModel)]="taskPlanItem.IsOptional" binary="true" label="  Optional?"></p-checkbox>
    </div>
</div>

<div class="form-group">
    <div class="col-md-offset-2 col-md-10">
        <a class="btn btn-primary" style="width: 100px;" (click)="createTaskPlanItem()">Create</a>
    </div>
</div>


<div>
    <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Manage Task Plans</a>
</div>