import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule} from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { ChipsModule } from 'primeng/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BusinessUnitsComponent } from './';

import { UsersComponent } from  '#pages/admin/users/users.component';


import { ApiModule } from '#services/api/api.module';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { SharedModule } from '#services/shared/shared.module';
import { ConfirmationService, MessageService } from 'primeng/api';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { SpinnerModule} from 'primeng/spinner';
import { TooltipModule } from 'primeng/tooltip';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
@NgModule({
    declarations: [
        BusinessUnitsComponent,
        UsersComponent
    ],
    imports: [
        // Angular
        CommonModule,
        ReactiveFormsModule,

        // PrimeNG
        InputTextModule,
        ButtonModule,
        BrowserAnimationsModule,
        ConfirmDialogModule,
        DialogModule,
        RadioButtonModule,
        DropdownModule,
        ListboxModule,
        SpinnerModule,
        CheckboxModule,
        TooltipModule,
        MultiSelectModule,
        ChipsModule,
        AutoCompleteModule,
        InputSwitchModule,
        TableModule,
        ToggleButtonModule,
        SelectButtonModule,
        // App
        ApiModule,
        SharedModule,
        FormsModule
    ],
    providers: [
        LoadingIndicatorService,
        ConfirmationService,
        MessageService
    ]
})

export class AdminPagesModule { }
