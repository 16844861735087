import { Component, OnInit, Inject } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Area } from '#models/area';
import { Subarea } from 'models/subarea';
import { Route } from 'models/route';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { ORDTemplate } from '#models/ordTemplate';
import { ORDService } from '#services/api/ord.service';
import { Equipment } from '#models/equipment';
import { BusinessUnit } from '#models/business-unit';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { EquipmentService } from '#services/api/equipment.service';
import { EamEquipment } from '#models/eamEquipment';

import { DialogModule } from 'primeng/dialog';
import { MessageService } from 'primeng/api';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-task-plan',
  templateUrl: './task-plan.component.html',
  styleUrls: ['./task-plan.component.scss']
})
export class TaskPlanComponent implements OnInit {
  public taskPlans: TaskPlan[];
  public ord: ORDTemplate;
  public areas: Area[];
  public subareas: Subarea[];
  public routes: Route[];
  public OrdTemplateID: number;
  public firstTPId: number;
  public lastTPId: number;
  public selectedBU: BusinessUnit;
  private storedData: any = [];
  public eamEquipmentAboutToChange: { taskPlanCode: string, eamEq: EamEquipment, showDuplicateWarning: boolean}[] = [];
  displayEAMEquipmentTable: boolean;
  duplicatedTaskPlans: TaskPlan[] = [];

  constructor(
    private taskPlanSvc: TaskPlanService,
    private omSvc: OMWorkbenchService,
    private ordSvc: ORDService,
    private eqSvc: EquipmentService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    private messageSvc: MessageService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) { }

  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  ngOnInit() {
    this.loadingIndicatorSvc.show();
    this.ord = new ORDTemplate();
    this.getFromLocal("BU");
    this.selectedBU = this.storedData["BU"];
    this.aRoute.params.subscribe(v => {
      this.OrdTemplateID = v['id'];
    });
    this.ordSvc.getORDTemplateByID(this.OrdTemplateID)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: ORDTemplate) => {
        this.ord = data;
      });
    this.loadTaskPlans();
  }

  onRedirectButtonClick(sufix: string, id?: number): void {
    if (sufix === '') {
      this._router.navigate(['ord/field']);
    } else if (id !== undefined && id != null) {
      this._router.navigate(['ord/' + sufix, { ordID: this.OrdTemplateID, id: id }]);
    } else {
      this._router.navigate(['ord/' + sufix, { ordID: this.OrdTemplateID }]);
    }
  }

  private loadTaskPlans(): void {
    this.loadingIndicatorSvc.show();
    this.taskPlans = [];

    this.taskPlanSvc.getTaskPlanNamesByORDId(this.OrdTemplateID)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: TaskPlan[]) => {
        this.taskPlans = data.filter(obj => obj.IsGeneric === false);
        if(this.selectedBU.LinkToEquipment == "EAM")
        {
          this.loadingIndicatorSvc.show();
          let eamCodes = data.map(tp => tp.EAMCode);
          eamCodes = eamCodes.filter(eamCode => !eamCode)
          this.eqSvc.getEAMEquipmentByEAMCodes(eamCodes)
          .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe(data => {
            data.forEach(eq => {
              let taskPlansForCurrentEquipment = this.taskPlans.filter(x => x.EAMCode == eq.EAMCode);
              let displayName = !eq.ECName ? eq.ECName.trim() : `${eq.EAMDescription.split(";")[1].trim()} (EAM Name)`;
              taskPlansForCurrentEquipment.forEach(tp => {
                tp.displayName = displayName;
              })
            })
          });
        }
        this.sortTaskPlans();
      });
  }

  // ------------------------------Moving Task Plans-------------------------------------------------//
  moveUpTaskPlan(id: number): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.moveUpTaskPlan(id)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        if (data.Success) {
          this.swapItem(id, -1);
          this.sortTaskPlans();
        }
      });
  }

  moveDownTaskPlan(id: number): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.moveDownTaskPlan(id)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        if (data.Success) {
          this.swapItem(id, 1);
          this.sortTaskPlans();
        }
      });
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  private swapItem(id: number, possition: number): void {
    const taskPlanIndex = this.taskPlans.findIndex(x => x.ID === id);
    const temp = this.taskPlans[taskPlanIndex + possition];
    this.taskPlans[taskPlanIndex + possition] = this.taskPlans[taskPlanIndex];
    this.taskPlans[taskPlanIndex] = temp;
  }

  private sortTaskPlans(selectLastSection: boolean = false): void {
    const list = this.taskPlans;
    if (list.length > 0) {
      this.taskPlans = list.sort(function (obj1: TaskPlan, obj2: TaskPlan) { return obj1.sortId - obj2.sortId; });
      this.setFirstLastFlags();
    }
  }

  private setFirstLastFlags(): void {
    this.firstTPId = this.taskPlans[0].ID;
    this.lastTPId = this.taskPlans[this.taskPlans.length - 1].ID;
  }

  getEAMEquipmentFromTaskPlanCode(): void {
    this.loadingIndicatorSvc.show();
    this.eamEquipmentAboutToChange = [];
    this.duplicatedTaskPlans = [];
    let taskPlansWithoutEAMEquipment = this.taskPlans.filter(tp => tp.EAMCode);
    let potentialECNames: string[] = [];
    taskPlansWithoutEAMEquipment.forEach(tp => {
      let standardizedTPCode = tp.Code.replace(/_/g, " ");

      // Check all but last word
      let lastIndex = standardizedTPCode.lastIndexOf(" ");
      let ignoreLastWordInCode = standardizedTPCode.substring(0, lastIndex);

      // Check 1st word
      let splitTPCode = standardizedTPCode.trim().split(" ");
      let firstWord = splitTPCode[0].trim();

      // Check combine Last 2 Words
      let combineLastTwoWords = ignoreLastWordInCode + splitTPCode[splitTPCode.length - 1];

      //Check whole Code
      let wholeCode = standardizedTPCode.trim();

      // Check for potential duplicates that may cause errors
      if (this.taskPlans.filter(x => x.Code === wholeCode).length > 1) {
        let duplicatedTP = this.taskPlans.find(x => x.Code.trim() === standardizedTPCode.trim());
        this.duplicatedTaskPlans.push(duplicatedTP);
      }

      //Add in potential names unless two rules produce same string
      potentialECNames.push(wholeCode);
      if(wholeCode != firstWord)
      {
        potentialECNames.push(firstWord)
      }
      if(wholeCode != ignoreLastWordInCode || firstWord != ignoreLastWordInCode)
      {
        potentialECNames.push(ignoreLastWordInCode);
      }
      if (wholeCode != combineLastTwoWords || firstWord != combineLastTwoWords || ignoreLastWordInCode != combineLastTwoWords)
      {
        potentialECNames.push(combineLastTwoWords);
      }
    })
    //Get rid of empty names
    potentialECNames = potentialECNames.filter(x => x != "");
    let uniqueECNames = potentialECNames.filter(function(item, pos, self) {
      return self.indexOf(item) == pos;
    })
    this.eqSvc.getEAMEquipmentByECNames(uniqueECNames)
    .pipe(
      finalize(() => {this.displayEAMEquipmentTable = true; this.loadingIndicatorSvc.hide()})
    ).subscribe(data => {
      data.forEach(eamEquipment => {
        let matchedTaskPlan = this.taskPlans.find(tp => {
          let standardizedECName = eamEquipment.ECName.replace(/_/g, " ");
          let standardizedTPCode = tp.Code.replace(/_/g, " ");

          let lastIndex = standardizedTPCode.lastIndexOf(" ");
          let ignoreLastWordInCode = standardizedTPCode.substring(0, lastIndex);
          let splitTPCode = standardizedTPCode.trim().split(" ");
          let combineLastTwoWords = ignoreLastWordInCode + splitTPCode[splitTPCode.length - 1];
          return splitTPCode[0].trim() === standardizedECName 
            || standardizedTPCode === standardizedECName 
            || ignoreLastWordInCode === standardizedECName
            || combineLastTwoWords === standardizedECName;
        });
        // Check for duplicates in case api returned two equipment with same name
        let foundIDAPDuplicate = this.eamEquipmentAboutToChange.find(x => x.taskPlanCode === matchedTaskPlan.Code) != undefined;
        let foundORDDuplicate = this.duplicatedTaskPlans.find(x => x.Code === matchedTaskPlan.Code) != undefined;
        if (!foundIDAPDuplicate)
        {
          this.eamEquipmentAboutToChange.push({ taskPlanCode: matchedTaskPlan.Code, eamEq: eamEquipment, showDuplicateWarning: foundORDDuplicate })
        }
      })
    });
  }

  removePendingEAMEqChange(ecName: string, tpCode: string)
  {
    this.eamEquipmentAboutToChange = this.eamEquipmentAboutToChange.filter(x => x.eamEq.ECName != ecName || x.taskPlanCode != tpCode);
  }

  updateTaskPlansWithEAMEquipment()
  {
    this.loadingIndicatorSvc.show();
    this.displayEAMEquipmentTable = false; 
    let updatedTaskPlans: TaskPlan[] = [];
    this.eamEquipmentAboutToChange.forEach(eq => {
      let matchedTaskPlan = this.taskPlans.find(tp => eq.taskPlanCode == tp.Code);
      matchedTaskPlan.EAMCode = eq.eamEq.EAMCode;
      matchedTaskPlan.ECObjectID = eq.eamEq.ECObjectID;
      matchedTaskPlan.ECObjectType = eq.eamEq.ECType;
      matchedTaskPlan.Equipment = [];
      updatedTaskPlans.push(matchedTaskPlan);
    })
    this.taskPlanSvc.updateTaskPlans(updatedTaskPlans)
    .pipe(
      finalize(() => { 
      this.loadingIndicatorSvc.hide();
      this.eamEquipmentAboutToChange = [];
      }))
    .subscribe(() => {
      this.loadTaskPlans()
    },
      e => {
        this.showError(e, "Error Updating TaskPlans");
      })
    }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  cancelTaskPlanUpdate() 
  {
    this.eamEquipmentAboutToChange = [];
    this.displayEAMEquipmentTable = false;
  }
}
