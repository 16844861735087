<p-dialog header="Schedule" [(visible)]="displaySchedule" [modal]="true" [responsive]="true" style="width:600"
    [positionTop]="300" [maximizable]="true" [baseZIndex]="10000">
    <div class="row">
        <div class="col-md-12">
            <label>Requires Schedule?</label><br>
            <label style="padding-right: 10px;">
                <p-radioButton name="ScheduleOption" value="0" [(ngModel)]="requiresSchedule"></p-radioButton>
                No
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ScheduleOption" value="1" [(ngModel)]="requiresSchedule"></p-radioButton>
                Yes
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12" *ngIf="requiresSchedule == 1">
            <label>Schedule Type:</label><br>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="1" [(ngModel)]="selectedSchedule"></p-radioButton>
                Daily
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="2" [(ngModel)]="selectedSchedule"></p-radioButton>
                2X Day
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="3" [(ngModel)]="selectedSchedule"></p-radioButton>
                Weekly (select days)
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="4" [(ngModel)]="selectedSchedule"></p-radioButton>
                Weekly (any day)
            </label><br>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="5" [(ngModel)]="selectedSchedule"></p-radioButton>
                2X Week (any day)
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="6" [(ngModel)]="selectedSchedule"></p-radioButton>
                3X Week (any day)
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="12" [(ngModel)]="selectedSchedule"></p-radioButton>
                Semi-Monthly (due at mid-Month and End of Month)
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="7" [(ngModel)]="selectedSchedule"></p-radioButton>
                Monthly
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="8" [(ngModel)]="selectedSchedule"></p-radioButton>
                2X Month (2 due at End Of Month)
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="11" [(ngModel)]="selectedSchedule"></p-radioButton>
                Every 45 days
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="9" [(ngModel)]="selectedSchedule"></p-radioButton>
                Quarterly
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="13" [(ngModel)]="selectedSchedule"></p-radioButton>
                Bi-annually
            </label>
            <label style="padding-right: 10px;">
                <p-radioButton name="ItemOption" value="10" [(ngModel)]="selectedSchedule"></p-radioButton>
                Annually
            </label>


        </div>
    </div>
    <br>
    <div class="row">
        <div class="col-md-12" *ngIf="requiresSchedule == 1 && (selectedSchedule == 3)">
            <label>Frequency:</label><br>
            <p-selectButton [options]="days" [(ngModel)]="selectedDays" multiple="multiple"></p-selectButton>
            <span class="text-danger field-validation-error" *ngIf="displayWarningFrequency">
                <span>Frequency is required.</span>
            </span>
        </div>
    </div>
    <p-footer>
        <button type="button" pButton icon="pi pi-check" (click)="saveAssetSchedule()" label="Save"
            [disabled]="disableScheduleButtons"></button>
        <button type="button" pButton icon="pi pi-close" (click)="displaySchedule=false" label="Cancel"
            class="p-button-secondary" [disabled]="disableScheduleButtons"></button>
    </p-footer>
</p-dialog>

<h2>{{taskPlan.Description}}'s Content</h2>
<div class="form-horizontal">
    <hr />
    <div class="row is-flex">
        <!-- *START* Left Panel -->
        <div class="col-sm-4 box-shadow module">
            <h4 style="color:dimgray">{{taskPlan.Description}} Part/Location</h4>
            <table class="table">
                <tr *ngFor="let tps of taskPlan.TaskPlanSections"
                    [ngClass]="{'isSelected' : tps.ID == selectedSectionId}">
                    <td>
                        <a (click)="selectTaskPlanSections(tps.ID)" *ngIf="tps.ID != selectedSectionId">
                            {{tps.Description}}
                        </a>
                        <div style="padding: 6px 12px;  display: inline-block;"
                            *ngIf="tps.ID == selectedSectionId">
                            {{tps.Description}}
                        </div>
                    </td>
                    <td>
                        <div class="col-sm-6">
                            <a (click)="showScheduleDialog(tps.ID)" *ngIf="tps.ID == selectedSectionId">
                                <span style="font-size:1.8em;" class="pi pi-calendar"></span>
                            </a>
                            <a (click)="showScheduleDialog(tps.ID)" *ngIf="tps.ID != selectedSectionId">
                                <span style="font-size:1.8em;" class="pi pi-calendar"></span>
                            </a>
                        </div>
                    </td>
                    <td>
                        <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm"
                            *ngIf="tps.ID == firstSectionId" />
                        <a (click)="moveUpTaskPlanSection(tps.ID)" *ngIf="tps.ID != firstSectionId">
                            <img src="assets/images/upTriangle.png" alt="blank" class="imageIcon-sm" />
                        </a>
                    </td>
                    <td>
                        <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm"
                            *ngIf="tps.ID == lastSectionId" />
                        <a (click)="moveDownTaskPlanSection(tps.ID)" *ngIf="tps.ID != lastSectionId">
                            <img src="assets/images/dnTriangle.png" alt="blank" class="imageIcon-sm" />
                        </a>
                    </td>
                    <td>
                        <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm"
                            *ngIf="tps.General" />
                        <a (click)="updateTaskPlanSection(tps.ID, tps.Description)" *ngIf="!tps.General">
                            <img src="assets/images/edit.png" alt="blank" class="imageIcon-sm" />
                        </a>
                    </td>
                    <td>
                        <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm"
                            *ngIf="tps.General" />
                        <a (click)="deleteTaskPlanSection(tps.ID, tps.Description)" *ngIf="!tps.General">
                            <img src="assets/images/cross_color.png" alt="blank" class="imageIcon-sm" />
                        </a>
                    </td>
                </tr>
            </table>
            <hr style="border-color:lightgray">
            <!-- Add New Sections Sub-panel-->
            <div class="form-horizontal">
                <h4>Create New Part Location</h4>
                <div class="form-group">
                    <div class="col-md-10 col-md-offset-1">
                        <input class="form-control text-box single-line" [(ngModel)]="taskPlanSection.Description"
                            (keyup.enter)="createTaskPlanSection()">
                        <span class="text-danger field-validation-error" *ngIf="displayWarningSec">
                            <span>This field is required.</span>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-10 col-md-offset-1">
                        <a class="btn btn-primary " style="width: 100px; border:1px solid" (click)="createTaskPlanSection()">Create</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- *END* Left Panel -->
        <!-- *START* Right Panel -->
        <div class="col-sm-8" *ngIf="hasSections">
            <div class="box-shadow module">
                <div class="accordionContainer-lg">
                    <!-- sub-panel *START* (NewSubSection) -->
                    <div>
                        <h3 class="sectionHeader">
                            <div class="_accordion-title">
                                <span class="text-tab">New Operator Task</span>
                            </div>
                        </h3>
                        <hr style="border-color:lightgray">
                        <div class="form-horizontal">
                            <div class="form-group">
                                <label class="control-label col-md-2">Operator Task Description</label>
                                <div class="col-md-10">
                                    <textarea class="form-control text-box single-line"
                                        [(ngModel)]="subSection.Description" cols="5" rows="5"></textarea>
                                    <span class="text-danger field-validation-error" *ngIf="displayWarningSubSec">
                                        <span>This field is required.</span>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group newTaskInputs">
                                <div class="col-md-offset-2 col-md-5">
                                    <a class="btn btn-primary " style="width: 100px;  border:1px solid"
                                        (click)="createSubSection()">Create</a>
                                </div>
                                <div class="newTaskSwitch">
                                    <p-inputSwitch [(ngModel)]="subSection.AutoCreateRepairBox">
                                    </p-inputSwitch>
                                    <label id="repairSwitchLabel">Include "Repair/Adjust" on Auto-Created
                                        Status?</label>
                                </div>
                            </div>
                        </div>
                        <hr style="border-color:lightgray">
                    </div> <!-- sub-panel *END* (NewSubSection) -->
                    <!-- sub-panel *START*  (SubSectionList) -->
                    <div *ngFor="let ss of subSectionList">
                        <h5 class="subSectionHeader">
                            <div class="_accordion-title">
                                <span class="text-tab white-space-pre">{{ss.Description}}</span>
                            </div>
                            <div class="_accordion-icons">
                                <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm imageIcon-tab-left"
                                    *ngIf="ss.ID == firstSubSectionId" />
                                <a (click)="moveUpTaskPlanSubSection(ss.ID)" *ngIf="ss.ID != firstSubSectionId">
                                    <div class="imageIcon-tab-left">
                                        <img src="assets/images/upTriangle_white.png" alt="blank"
                                            class="imageIcon-sm" />
                                    </div>
                                </a>
                                <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm imageIcon-tab-left"
                                    *ngIf="ss.ID == lastSubSectionId" />
                                <a (click)="moveDownTaskPlanSubSection(ss.ID)" *ngIf="ss.ID != lastSubSectionId">
                                    <div class="imageIcon-tab-left">
                                        <img src="assets/images/dnTriangle_white.png" alt="blank"
                                            class="imageIcon-sm" />
                                    </div>
                                </a>
                                <a (click)="updateTaskPlanSubSection(ss.ID)">
                                    <div class="imageIcon-tab-left">
                                        <img src="assets/images/edit.png" alt="blank"
                                            class="imageIcon-sm ShowOnHover" />
                                    </div>
                                </a>
                                <a (click)="deleteTaskPlanSubSection(ss.ID)">
                                    <div class="imageIcon-tab-left">
                                        <img src="assets/images/cross_color.png" alt="blank"
                                            class="imageIcon-sm ShowOnHover" />
                                    </div>
                                </a>
                            </div>
                        </h5>
                        <!-- *END* subSectionHeader -->
                        <!-- *START* subSectionBody -->
                        <div class="p-accordion-content p-helper-reset p-corner-bottom">
                            <!-- items list -->
                            <table class="table table-hover" style="width:100%; margin-bottom: 0px;">
                                <tr *ngFor="let item of ss.TaskPlanItems">
                                    <td style="width:70%">
                                        <a (click)="updateItem(item.ID, ss.ID)">
                                            {{item.Description}}
                                        </a>
                                    </td>
                                    <td>
                                        <img src="assets/images/blank.png" alt="Inactive"
                                            class="imageIcon-sm ShowOnHover" *ngIf="item.ID == ss.firstItemId" />
                                        <a (click)="moveUpTaskPlanItem(item.ID, ss.ID)"
                                            *ngIf="item.ID != ss.firstItemId">
                                            <img src="assets/images/upTriangle.png" alt="blank"
                                                class="imageIcon-sm ShowOnHover" />
                                        </a>
                                    </td>
                                    <td>
                                        <img src="assets/images/blank.png" alt="Inactive"
                                            class="imageIcon-sm ShowOnHover" *ngIf="item.ID == ss.lastItemId" />
                                        <a (click)="moveDownTaskPlanItem(item.ID, ss.ID)"
                                            *ngIf="item.ID != ss.lastItemId">
                                            <img src="assets/images/dnTriangle.png" alt="blank"
                                                class="imageIcon-sm ShowOnHover" />
                                        </a>
                                    </td>
                                    <td>
                                        <a (click)="updateItem(item.ID, ss.ID)"
                                            *ngIf="!(item.ItemOptionType === 'Statusbox')">
                                            <img src="assets/images/edit.png" alt="blank"
                                                class="imageIcon-sm ShowOnHover" />
                                        </a>
                                    </td>
                                    <td>
                                        <a (click)="deleteItem(item.ID, ss.ID)"
                                            *ngIf="!(item.ItemOptionType === 'Statusbox')">
                                            <img src="assets/images/cross_color.png" alt="blank"
                                                class="imageIcon-sm ShowOnHover" />
                                        </a>
                                    </td>
                                </tr>
                            </table> <!-- end items list -->
                            <div class="form-group">
                                <div class="col-md-10">
                                    <a class="btn btn-primary "  style=" border:1px solid" (click)="createItem(ss.ID)"
                                        *ngIf="ss.TaskPlanItems.length < 3; else disableBtn">Create New Item</a>
                                    <ng-template #disableBtn>
                                        <div pTooltip="Reached maximum screen capacity for this subsection"
                                            showDelay="500" tooltipPosition="left">
                                            <a class="btn btn-primary" style=" border:1px solid" disabled>Create New Item</a>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div> <!-- *END* subSectionBody -->
                    </div> <!-- *END* SubSectionList -->
                </div>
            </div>
        </div> <!-- *END* Right Panel -->
    </div>
    <hr />
</div>
<div class="row">
    <div class="col-sm-6" *ngIf="this.ordID == 1">
        <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Generic Equipment Types</a>
    </div>
    <div class="col-sm-6" *ngIf="this.ordID > 1">
        <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Task Plans</a>
    </div>
    <div class="col-sm-6">
    </div>
</div>