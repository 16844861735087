export const environment = {
    production: false,
    appCode: 'webApp',
    apiEndpoint: 'https://ordservice-dev-api-cvx.azurewebsites.net/api',
    OMEndpoint: 'https://omservice-vm-dev.azure.chevron.com/api',
    feedbackEndpoint: 'https://feedback-api-dev.cvx.azurewebsites.net/api',
    redirectURL: "https://ordservice-dev.azure.chevron.com",
    clientId: "fce6f8e0-dd40-4126-a63f-face013ea262",
    scope: "https://omworkbench-test.chevron.com/user_impersonation",

  };
  