<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px">
  <h2>Edit Generic Equipment Type</h2>
  <div class="form-horizontal">
      <div class="form-group">
          <label class="control-label col-md-2">Description</label>
          <div class="col-md-10">
              <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Description">
              <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
                  <span>The Description field is required.</span>
              </span>
          </div>
      </div>
      <!-- <div class="form-group">
          <label class="control-label col-md-2">Equipment</label>
          <div class="col-md-10">                
              <p-multiSelect [options]="Equipments" [(ngModel)]="taskPlan.Equipment" defaultLabel="Select Equipment"
                  filterPlaceHolder="Search for Equipment" showToggleAll="false" [style]="{'width': '100%'}">
              </p-multiSelect>
          </div>
      </div> -->

      <div class="form-group">
          <div class="col-md-offset-2 col-md-10">
              <a class="btn btn-primary" style="width: 100px;" (click)="updateTaskPlan()">Save</a>
          </div>
      </div>
  </div>    
</div>

<div style="width:80%; margin:auto">
  <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Generic Equipment Types</a> 
</div>

