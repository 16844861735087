export class ORDResponse {
    ID: number;
    ORDTemplateID: number;
    StartedDate: Date;
    CompletedDate: Date;
    Assignee: string;
    ResponseStatus: string;
    HoursCompletion: number;
    Shift: string;
    Notes: string;
    DueDate: Date;
    CreateDate: Date;
}
