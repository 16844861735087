import { NgModule } from '@angular/core';
import { LoggerService, AuthService, NotificationService } from './';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    LoggerService,
    AuthService,
    NotificationService
  ]
})

export class SharedModule { }
