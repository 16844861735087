import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';

@NgModule({
    declarations: [
        NotFoundComponent,
        GenericErrorComponent,
        NotAuthorizedComponent
    ],
    imports: [
        CommonModule
    ],
    providers: [
    ]
})

export class ErrorPagesModule { }
