<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px">
    <h2>Edit Equipment TaskPlan - {{taskPlan.Code}} </h2>
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-md-2">Code</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Code">
                <span class="text-danger field-validation-error" *ngIf="displayWarningCode">
                    <span>The Code field is required.</span>
                </span>
            </div>
            <label class="control-label col-md-2">Description</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Description">
                <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
                    <span>The Description field is required.</span>
                </span>
            </div>
        </div>
        <div class="form-group" *ngIf="selectedBU.LinkToEquipment=='EAM'">
            <label class="control-label col-md-2">Equipment</label>
            <div class="col-md-10">  
                <p-dropdown [options]="groupedEAMEquipmentCopy" [(ngModel)]="selectedEAMEquipment" [style]="{'width':'100%'}" placeholder="Select Equipment" [group]="true"
                [filter]="true" filterBy="label" optionLabel="label" >
                    <ng-template let-group pTemplate="group">
                        <span style="margin-left:.25em">{{group.label}}</span>
                    </ng-template>
                </p-dropdown>              
            </div>
        </div>
        <div class="form-group" *ngIf="selectedBU.LinkToEquipment=='EC'">
            <label class="control-label col-md-2">Equipment</label>
            <div class="col-md-10">
                <p-dropdown [options]="groupedEquipmentCopy" [(ngModel)]="ecEquipment" [style]="{'width':'100%'}" placeholder="Select Equipment" [group]="true"
                [filter]="true" filterBy="label"  optionLabel="label" >
                    <ng-template let-group pTemplate="group">
                        <span style="margin-left:.25em">{{group.label}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
                <label class="control-label col-md-2">Generic Equipment Type</label>
                <div class="col-md-10">                
                    <p-dropdown  [options]="GenericEquipments" [(ngModel)]="selectedGenericEquipment.GenericParentID"
                    optionLabel="label" [showClear]="true" [filter]="true" [style]="{'width': '100%'}" placeholder="Select a Generic Equipment Type"> </p-dropdown>
                </div>
            </div>
        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-primary" style="width: 100px;" (click)="updateTaskPlan()">Save</a>
            </div>
        </div>
    </div>    
</div>

<div style="width:80%; margin:auto">
    <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Task Plans</a> 
</div>

