import { BusinessUnit } from "./business-unit";

export class User {
    ID: number;

    GivenName: string;

    Surname: string;

    Email: string;

    Mail: string;

    Cai: string;

    IsSystemAdmin: boolean;

    BusinessUnitID: number;

    BusinessUnit: BusinessUnit;
}
