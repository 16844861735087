
import { Component, OnInit, Inject } from '@angular/core';
import { ORDResponse } from '#models/ordResponse';
import { SelectItem, MessageService } from 'primeng/api';
import { Area } from '#models/area';
import { Subarea } from '#models/subarea';
import { Route } from '#models/route';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { ORDTemplate } from '#models/ordTemplate';
import { ORDService } from '#services/api/ord.service';
import { LoadingIndicatorService, AuthService } from '#services/shared';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { BusinessUnit } from '#models/business-unit';
import { Router } from '@angular/router';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-field-reports',
  templateUrl: './field-reports.component.html',
  styleUrls: ['./field-reports.component.scss']
})
export class FieldReportsComponent implements OnInit {

  responses: ORDResponse[];
  responsesIncomplete: ORDResponse[];
  onlyShowIncomplete: boolean;
  startDate: Date;
  finishDate: Date;
  showReportFlag: boolean;
  reportName: string;
  Cai: string;
  isAdmin: boolean;
  public storedData: any = [];

  // Dropdown Options
  Areas: SelectItem[];
  subAreas: SelectItem[];
  routes: SelectItem[];
  ords: SelectItem[];
  equips: SelectItem[];

  // Object that is actually selected from Dropdown
  selectedArea: SelectItem;
  selectedSubArea: SelectItem;
  selectedRoute: SelectItem;
  selectedORD: SelectItem;
  selectedEquipment: SelectItem[];
  // Flags to show/hide/disable content
  disableSubArea: boolean;
  disableRoute: boolean;
  disableORD: boolean;
  disableEquipment: boolean;
  ordWarningMsg: boolean;
  equipWarningMsg: boolean;
  selectedTabReport: number;
  selectedBU: BusinessUnit;

  constructor(
    private omSvc: OMWorkbenchService,
    private ordSvc: ORDService,
    private loadingSvc: LoadingIndicatorService,
    private router: Router,
    private authSvc: AuthService,
    private messageSvc: MessageService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
    this.getFromLocal("BU");
    const selectedBU = this.storedData["BU"];
    if (selectedBU != undefined || selectedBU != null)
      if (selectedBU.Name === "LABU") {
        this.router.navigateByUrl('/op-field-report', { skipLocationChange: false });
        return;
      }
    this.Cai = this.authSvc.getCurrentUser().Cai;
    this.isAdmin = this.authSvc.isSystemAdmin();
    this.selectedEquipment = [];
    this.Areas = [{ label: 'Select an Area', value: null }];
    this.subAreas = [{ label: 'Select a sub-area', value: null }];
    this.routes = [{ label: 'Select a route', value: null }];
    this.ords = [{ label: 'Select a ORD', value: null }];
    this.equips = [{ label: 'Select an equipment', value: null }];
    this.selectedTabReport = 0;
    this.onlyShowIncomplete = false;
  }

  ngOnInit() {
    this.disableSubArea = true;
    this.disableRoute = true;
    this.disableORD = true;
    this.ordWarningMsg = false;
    this.equipWarningMsg = false;
    this.showReportFlag = false;
    this.getFromLocal("BusinessUnits");
    const buArray: BusinessUnit[] = this.storedData["BusinessUnits"];
    this.getFromLocal("BU");
    this.selectedBU = this.storedData["BU"];
    if (this.selectedBU !== undefined && this.selectedBU != null) {
      // If SuperAdmin
      if (this.selectedBU.Id === 0) {
        this.saveInLocal("BU", buArray[1]);
        this.selectedBU = buArray[1];
      }
    } else {
      // Temp fix for first time login. Issue is race conditions betwen header's getBU API and report's buArray request
      const defaultBU = new BusinessUnit();
      defaultBU.Id = 1;
      this.selectedBU = defaultBU;
    }
    this.getAreas();
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  // Grab all areas if you are a sys admin, grab only those belonging to your cai if not
  getAreas() {
    this.getFromLocal("area");
    //this.selectedArea = this.storedData["area"];
    this.loadingSvc.show();
    if (this.isAdmin) {
      this.omSvc.getAllAreas()
        .pipe(
          finalize(() => {
            this.loadingSvc.hide();
          }))
        .subscribe((data: Area[]) => {
          // Populate dropdown
          data.forEach((area) => {
            this.addArea(area);
          });
        }, e => {
          this.Areas = [];
          this.showError(e, 'Could not get Areas from EC');
        });
    } else {
      this.omSvc.getAreas(this.Cai)
        .pipe(
          finalize(() => {
            this.loadingSvc.hide();
          }))
        .subscribe((data: Area[]) => {
          data.forEach((area) => {
            this.addArea(area);
          });
        }, e => {
          this.Areas = [];
          this.showError(e, 'Could not get Areas from EC');
        });
    }
  }

  private addArea(area: Area) {
    let element: SelectItem;
    element = { label: area.Name, value: area };
    this.Areas.push(element);
    // Make stored value appear as selected in dropdown
    if (this.selectedArea != null && this.selectedArea.value != null && this.Areas[this.Areas.length - 1].value.ID === this.selectedArea.value.ID) {
      this.selectedArea = this.Areas[this.Areas.length - 1].value;
      this.getSubAreas();
    }
  }

  // Get Sub Areas by the Area ID selected, and update ORD to selected Area
  getSubAreas() {
    this.saveInLocal("area", this.selectedArea);
    this.getFromLocal("subarea");
    this.selectedSubArea = this.storedData["subarea"];
    if (this.selectedArea == null) {
      // Empty out sub fields
      this.selectedSubArea = null;
      this.selectedRoute = null;
      this.selectedORD = null;

      // Disable fields
      this.disableSubArea = true;
      this.disableRoute = true;
      this.disableORD = true;
      return;
    }
    this.subAreas = [];
    if (this.selectedArea.value != null) {
      this.loadingSvc.show();
      this.omSvc.getSubAreas(this.selectedArea.value.ID)
        .pipe(
          finalize(() => {
            // Handles disabling input fields
            if (this.selectedArea === null) {
              this.disableSubArea = true;
              this.disableRoute = true;
              this.disableORD = true;
            } else {
              this.disableSubArea = false;
            }
            this.loadingSvc.hide();
          }))
        .subscribe((data: Subarea[]) => {
          data.forEach((sub) => {
            const element: SelectItem = { label: sub.Name, value: sub };
            this.subAreas.push(element);
            if (this.selectedSubArea != null && this.selectedSubArea.value != null && this.subAreas[this.subAreas.length - 1].value.ID === this.selectedSubArea.value.ID) {
              this.selectedSubArea = this.subAreas[this.subAreas.length - 1].value;
              this.getRoutes();
            }
          });
        });
    }
  }

  // Get new Routes when different Sub Area is selected
  getRoutes() {
    this.saveInLocal("subarea", this.selectedSubArea);
    this.getFromLocal("route");
    this.selectedRoute = this.storedData["route"];
    if (this.selectedSubArea == null) { // Handles on Change to a null value
      this.selectedRoute = null;
      this.selectedORD = null;
      this.disableRoute = true;
      this.disableORD = true;
      return;
    }
    this.routes = [];
    if (this.selectedSubArea.value != null) {
      this.loadingSvc.show();
      this.omSvc.getRoutes(this.selectedSubArea.value.ID)
        .pipe(
          finalize(() => {
            // Handles disabling inputs
            if (this.selectedSubArea === null) {
              this.disableRoute = true;
              this.disableORD = true;
            } else {
              this.disableRoute = false;
            }
            this.loadingSvc.hide();
          }))
        .subscribe((data: Route[]) => {
          data.forEach((route) => {
            const element: SelectItem = { label: route.Name, value: route };
            this.routes.push(element);
            if (this.selectedRoute != null && this.selectedRoute.value != null && this.routes[this.routes.length - 1].value.ID === this.selectedRoute.value.ID) {
              this.selectedRoute = this.routes[this.routes.length - 1].value;
              this.getORDs();
            }
          });
        });
    }
  }

  // Gets the newest ORDs based on new route chosen
  getORDs() {
    this.saveInLocal("route", this.selectedRoute);
    this.getFromLocal("ord");
    this.selectedORD = this.storedData["ord"];
    if (this.selectedRoute == null) {
      this.selectedORD = null;
      this.disableORD = true;
      return;
    }
    this.ords = [];
    if (this.selectedRoute.value != null) {
      this.loadingSvc.show();
      this.ordSvc.getORDTemplateNamesByRouteID([this.selectedRoute.value.ID], true)  // Return true because we want logically deleted entries as well
        .pipe(
          finalize(() => {
            // Handles disabling the inputs
            if (this.selectedRoute === null) {
              this.disableORD = true;
            } else {
              this.disableORD = false;
            }
            this.loadingSvc.hide();
          }))
        .subscribe((data: ORDTemplate[]) => {
          data.forEach((ord) => {
            const element: SelectItem = { label: ord.Name, value: ord };
            // console.log('element ' + JSON.stringify(element));
            if (ord.IsFieldORD == true) {
              this.ords.push(element);
              if (this.selectedORD != null && this.selectedORD.value != null && this.ords[this.ords.length - 1].value.ID === this.selectedORD.value.ID) {
                this.selectedORD = this.ords[this.ords.length - 1].value;
              }
            }
          });
          if (this.selectedORD != null) {

            this.getEquipments();
          }
        });
    }
  }

  getEquipments() {
    this.saveInLocal('ord', this.selectedORD);
    this.selectedEquipment = [];
    this.equips = [];
    if (this.selectedORD != null) {
      if (this.selectedORD.value != null) {
        this.loadingSvc.show();
        this.ordSvc.getEquipmentsByORDID(this.selectedORD.value.ID)  // Return true because we want logically deleted entries as well
          .pipe(
            finalize(() => {
              // Handles disabling the inputs
              if (this.selectedRoute === null) {
                this.disableORD = true;
              } else {
                this.disableORD = false;
              }
              this.loadingSvc.hide();
            }))
          .subscribe((data: any) => {
            this.selectedEquipment = [];
            data.forEach((equip) => {
              const element: SelectItem = { label: equip.Description, value: equip.ID };

              this.equips.push(element);
              this.selectedEquipment.push(equip.ID);
              //  if (this.selectedEquipment != null && this.equips[this.equips.length - 1].value.ID === this.selectedEquipment.ID) {
              //    this.selectedEquipment = this.equips[this.equips.length - 1].value;
              //  }
            });
          });
      }
    }

  }

  saveInLocal(key: string, val: any): void {
    this.storage.set(key, val);
    this.storedData[key] = this.storage.get(key);
    //console.log('this.selectedEquipment ' + JSON.stringify(this.selectedEquipment));
  }

  getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  getReport() {
    // console.log('en getreport ' + this.selectedEquipment);
    if (this.selectedEquipment === null || this.selectedEquipment === undefined) {
      this.equipWarningMsg = true;
    }
    else {
      this.equipWarningMsg = false;
    }
    if (this.selectedORD.value.ID === null || this.selectedORD.value.ID === undefined || this.selectedORD === null || this.selectedORD === undefined) {
      this.ordWarningMsg = true;
    } else {
      if (this.equipWarningMsg == false) {
        this.loadingSvc.show();
        this.ordWarningMsg = false;
        this.showReportFlag = true;
        this.setReportName();

        // let fnDate = this.finishDate;
        // let stDate = this.startDate;

        if (this.finishDate == undefined && this.startDate != undefined) {
          this.finishDate = new Date();
        }
        if (this.finishDate != undefined && this.startDate == undefined) {
          this.startDate = new Date();
          this.startDate.setDate(this.startDate.getDate() - 30);
        }
        if (this.finishDate == undefined && this.startDate == undefined) {
          this.finishDate = new Date();
          this.startDate = new Date();
          this.startDate.setDate(this.startDate.getDate() - 30);
        }
        // console.log('this.startDate '+this.startDate + 'end ' + this.finishDate);
        if (this.selectedTabReport == 0) {
          this.ordSvc.getFieldOrdResponseWithDateFilter(this.startDate, this.finishDate, this.selectedEquipment)
            .pipe(
              finalize(() => {
                this.loadingSvc.hide();
              }))
            .subscribe((data: ORDResponse[]) => {
              //this.responses = data;
              this.responses = data.sort(function (obj1: any, obj2: any) { return obj2.CompletedDate - obj1.CompletedDate; });
              // console.log('this.responses '+ JSON.stringify(this.responses));
            });
        }
        else {
          if (this.selectedTabReport == 1) {
            //call new report API
            //let fnDate = this.finishDate;
            //let stDate = this.startDate;

            if (this.finishDate == undefined && this.startDate != undefined) {
              this.finishDate = new Date();
            }
            if (this.finishDate != undefined && this.startDate == undefined) {
              this.startDate = new Date();
              this.startDate.setDate(this.startDate.getDate() - 30);
            }
            if (this.finishDate == undefined && this.startDate == undefined) {
              this.finishDate = new Date();
              this.startDate = new Date();
              this.startDate.setDate(this.startDate.getDate() - 30);
            }
            this.ordSvc.getFieldOrdResponseExpectedWithDateFilter(this.startDate, this.finishDate, this.selectedEquipment)
              .pipe(
                finalize(() => {
                  this.loadingSvc.hide();
                }))
              .subscribe((data: ORDResponse[]) => {
                //this.responses = data;
                this.responsesIncomplete = data.sort(function (obj1: any, obj2: any) { return obj2.MissingResponses - obj1.MissingResponses; });
                // console.log('this.responses '+ JSON.stringify(this.responses));
              });
          }
          else {
            //call default report API
            this.ordSvc.getFieldOrdResponseWithDateFilter(this.startDate, this.finishDate, this.selectedEquipment)
              .pipe(
                finalize(() => {
                  this.loadingSvc.hide();
                }))
              .subscribe((data: ORDResponse[]) => {
                //this.responses = data;
                this.responses = data.sort(function (obj1: any, obj2: any) { return obj2.CompletedDate - obj1.CompletedDate; });
                // console.log('this.responses '+ JSON.stringify(this.responses));
              });

          }
        }
      }
    }
  }

  /**
   * The report Name variable has to be a copy of the selectedORD.Name variable.
   * When getting the report, it will display the report name as a header,
   * and it needs to not change if they go back and change the search filters
   */
  public setReportName() {
    if (this.selectedORD.value.ID === null || this.selectedORD.value.ID === undefined || this.selectedORD === null || this.selectedORD === undefined) {
      this.reportName = null;
    } else {
      this.reportName = this.selectedORD.value.Name;
    }
  }

  public filterResponses(showOnlyIncomplete, currentTaskPlan) {

  }
  handleChange(e) {
    this.selectedTabReport = e.index;
    if (e.index == 1) {
      //call report automatically
      this.getReport();
    }
  }
}
