import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { User } from '#models/user';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { MessageService, ConfirmationService, SelectItem } from 'primeng/api';
import { UserService, BusinessUnitsService } from '#services/api';
import { AuthService } from '#services/shared';
import { BusinessUnit } from '#models/business-unit';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { Area } from '#models/area';
import { Subarea } from '#models/subarea';
import { Route } from '#models/route';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Router } from '@angular/router';
import { finalize } from "rxjs/operators";
import { FormsModule } from '@angular/forms';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  public users: User[];
  permissionNumber: number;
  directoryUsers: User[];
  BusinessUnits: SelectItem[];
  selectedBU: SelectItem;

  CAI = '';
  FirstName = '';
  LastName = '';
  EmailAddress = '';
  BUName = '';
  userInput = '';

  selectedUser: User;
  userList: User[];
  isAdmin = false;
  userInfoFilled = false;
  editBUView = false;

  areaIds: string[];
  subareaIds: string[];
  routeIds: string[];
  disableAdminCheckbox = false;

  storedData: any = [];
  // selectedBusinessUnit: BusinessUnit = new BusinessUnit();
  isAdminForAllBUs: boolean;

  constructor(
    public loadingIndicatorSvc: LoadingIndicatorService,
    private messageSvc: MessageService,
    private userSvc: UserService,
    private authSvc: AuthService,
    private buSvc: BusinessUnitsService,
    private confirmationSvc: ConfirmationService,
    private omSvc: OMWorkbenchService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private router: Router
  ) {

  }

  ngOnDestroy() {
    // console.log("on destroy called!!!");
    this.getFromLocal("BusinessUnits");
    const data: BusinessUnit[] = this.storedData["BusinessUnits"];
    this.getFromLocal("BU");
    const selectedBU = this.storedData["BU"];
    const currentUrl = this.router.url;
    // console.log(currentUrl);
    // console.log(this.isAdminForAllBUs);
    // console.log(selectedBU.Id);
    if (!(currentUrl === "/") && selectedBU.Id === 0) {
      // console.log("replacing bu zero");
      this.saveInLocal("BU", data[1]);
    }
  }

  ngOnInit() {
    this.isAdminForAllBUs = false;
    this.loadingIndicatorSvc.show();
    this.BusinessUnits = [];
    this.selectedUser = new User();
    this.selectedUser.GivenName = ''
    this.selectedUser.IsSystemAdmin = false;
    this.areaIds = [];
    this.subareaIds = [];
    this.routeIds = [];

    this.getFromLocal("BU");
    const storedBU = this.storedData["BU"];

    this.getFromLocal("isAdminForAllBUs");
    this.isAdminForAllBUs = this.storedData["isAdminForAllBUs"];

    this.refreshUserTable();
    if (storedBU.Id === 0) {
      this.buSvc.getBusinessUnits()
        .pipe(
          finalize(() => {
            this.loadingIndicatorSvc.hide();
            this.selectedBU = storedBU;
          }))
        .subscribe((data: BusinessUnit[]) => {
          data.forEach((bu) => {
            const element: SelectItem = { label: bu.Name, value: bu };
            this.BusinessUnits.push(element);
          });
        });
    } else {
      this.buSvc.getBusinessUnitByID(storedBU.Id)
        .pipe(
          finalize(() => {
            this.loadingIndicatorSvc.hide();
            this.selectedBU = storedBU;
          }))
        .subscribe((buData: BusinessUnit) => {
          const element: SelectItem = { label: buData.Name, value: buData };
          this.BusinessUnits.push(element);
        });
    }
  }

  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  saveInLocal(key: string, val: any): void {
    this.storage.set(key, val);
    this.storedData[key] = this.storage.get(key);
    // if (this.storedData["BU"]) {
    //   this.selectedBU = this.storedData["BU"];
    // }
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  /**
   * Populates user table
   */
  refreshUserTable() {
    let buID = 0;
    if (this.selectedBU == null) {
      const businessUnit = this.storedData["BU"];
      buID = businessUnit.Id;
    } else {
      buID = this.selectedBU.value.Id;
    }
    // console.log("getting user table for BU ID: " + buID);
   // this.loadingIndicatorSvc.show();
    if (buID === 0) {
      this.userSvc.getAll().subscribe((uData: User[]) => {
        this.userList = uData;
        this.loadingIndicatorSvc.hide();
      }, e => {
        this.userList = [];
        this.showError(e, 'Failed to get user list');
      });
    } else {
      this.userSvc.getAll().subscribe((uData: User[]) => {
        this.userList = uData.filter(obj => obj.BusinessUnitID === buID);
      }, e => {
        this.userList = [];
        this.showError(e, 'Failed to get user list');
      });
      this.loadingIndicatorSvc.hide();
    }
  }

  public setBUName() {
    var storageBU = this.storage.get("BU");
    if (this.selectedBU.value.Id != storageBU.Id) {
      if (this.selectedBU.value.Id === 0) {
        this.selectedUser.IsSystemAdmin = true;
        this.disableAdminCheckbox = true;
      } else {
        this.selectedUser.IsSystemAdmin = false;
        this.disableAdminCheckbox = false;
      }
      if (this.selectedBU != null) {
        this.loadingIndicatorSvc.show();
        this.buSvc.getBusinessUnitByID(this.selectedBU.value.Id)
          .pipe(
            finalize(() => { () => this.loadingIndicatorSvc.hide() }))
          .subscribe((data: BusinessUnit) => {
            this.BUName = data.Name;
            this.selectedUser.BusinessUnitID = data.Id;
          },
            e => {
              this.showError(e, 'Failed to get BU');
            });
      }
    }
  }

  /**
   * Seaches for users in Azure Directory after every keystroke in search field
   * @param event Keystroke in the search field
   */
  searchDirectoryUsers(event) {
    this.userSvc.getUserFromDirectory(event.query)
      .subscribe((data: User[]) => {
        this.directoryUsers = data
      },
        e => {
          this.directoryUsers = [];
          this.showError(e, 'Failed to get directory users');
        }
      );
  }

  /**
   * Auto-completes the rest of the form after a user from the dropdown is selected
   * @param event Dropdown selection
   */
  fillInfo(event) {
    this.loadingIndicatorSvc.show();
    this.CAI = this.selectedUser.Cai;
    this.FirstName = this.selectedUser.GivenName;
    this.LastName = this.selectedUser.Surname;
    this.EmailAddress = this.selectedUser.Mail;
    this.userInfoFilled = true;
    this.loadingIndicatorSvc.hide();
    this.selectedBU = this.storage.get("BU");
    if (this.selectedBU.value.Id === 0) {
      this.selectedUser.IsSystemAdmin = true;
      this.disableAdminCheckbox = true;
    } else {
      this.selectedUser.IsSystemAdmin = false;
      this.disableAdminCheckbox = false;
    }
  }
  /**
   * Empties user's autofill info
   */
  emptyInfo() {
    this.CAI = '';
    this.FirstName = '';
    this.LastName = '';
    this.EmailAddress = '';

    this.userInfoFilled = false;
    this.selectedUser = new User;
  }

  emptyRouteInfo() {
    this.areaIds = [];
    this.subareaIds = [];
    this.routeIds = [];
  }

  /**
   * Saves the user created to the database
   * Checks to ensure that user data has been filled out
   */
  saveUser(): void {
    this.loadingIndicatorSvc.show();
    if (!this.userInfoFilled || this.selectedBU == null) {
      this.messageSvc.add({
        severity: 'error',
        summary: 'No User To Save',
        detail: 'Please fill out User/BU info before saving'
      });
      this.loadingIndicatorSvc.hide();
    } else {
      this.selectedUser.BusinessUnitID = this.selectedBU.value.Id;
      this.userSvc.addUser(this.selectedUser)
        .pipe(
          finalize(() => {
            () => {
              this.loadingIndicatorSvc.hide();
              // this.emptyInfo();
             // this.getAreas(); // Save to UserRoute table
            }
          }))
        .subscribe(value => {
          if (value || value.Status === 'Success') {
            this.messageSvc.add({ severity: 'success', summary: 'User Added', detail: 'User \"' + this.selectedUser.Cai + '\" has been saved.' });
            this.refreshUserTable();
          }
        },
          e => {
            this.showError(e, 'Failed to add new user');
          });
    }
  }

  /**
   * Update user's admin rights, reverts changes if confirmation is not accepted
   * @param user the user who is being updated
   */
  updateUserStatus(user: User): void {
    this.confirmationSvc.confirm({
      header: 'Please confirm',
      message: `Are you sure that you want to change Admin rights of ${user.GivenName + ' ' + user.Surname}?`,
      accept: () => {
        this.loadingIndicatorSvc.show();
        this.userSvc.updateUser(user)
          .pipe(
            finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe((data: any) => {
            user = data;
          }, e => this.showError(e, 'Failed to update user. '));
      },
      reject: () => {
        user.IsSystemAdmin = !user.IsSystemAdmin;
      }
    });
  }

  /**
   * Deletes user provided through API.  Sys Admins can only be
   * deleted by other Sys Admins
   * @param user The user being deleted
   */
  deleteUser(user: User): void {
    this.confirmationSvc.confirm({
      header: 'Please confirm',
      message: `Are you sure that you want to remove user ${user.GivenName + ' ' + user.Surname}?`,
      accept: () => {
        this.loadingIndicatorSvc.show();
        this.userSvc.deleteUser(user.ID)
          .pipe(
            finalize(() => ((() => { this.loadingIndicatorSvc.hide(); }))))
          .subscribe(
            (data) => {
              const index = this.userList.indexOf(user);
              this.userList.splice(index, 1);
            },
            e => {
              this.showError(e, 'Failed to remove User');
            }
          );
      }
    });
  }

  public saveBUChanges(user: User): void {
    this.confirmationSvc.confirm({
      header: 'Please confirm',
      message: `Are you sure that you want to edit the BU of user ${user.GivenName + ' ' + user.Surname}?`,
      accept: () => {
        this.loadingIndicatorSvc.show();
        user.BusinessUnitID = user.BusinessUnit.Id;
        this.userSvc.updateUser(user)
          .pipe(
            finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe((data: any) => {
            user = data;
            this.messageSvc.add({ severity: 'info', summary: 'User Edited', detail: 'User\'s BU has been updated' });
          }, e => this.showError(e, 'Failed to update user. '));
      },
      reject: () => {
      }
    });
  }

  public changeBUView() {
    this.editBUView = !this.editBUView;
  }

  // Grab all areas if you are a sys admin, grab only those belonging to your cai if not
  private getAreas() {
    this.loadingIndicatorSvc.show();
    this.omSvc.getAreas(this.CAI)
      .pipe(
        finalize((() => this.loadingIndicatorSvc.hide())))
      .subscribe((data: Area[]) => {
        data.forEach((area) => {
          this.areaIds.push(area.ID);
        });
        this.getSubAreas();
      },
        e => {
          // this.ords = [];
          this.showError(e, 'Failed to get Areas from EC');
        });
  }

  private getSubAreas() {
    this.loadingIndicatorSvc.show();
    // Grab Subsequent SubArea Ids
    this.omSvc.getSubAreasPost(this.areaIds)
      .pipe(
        finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: Subarea[]) => {
        data.forEach((subArea) => {
          this.subareaIds.push(subArea.ID);
        });
        this.getRoutes();
      },
        e => {
          // this.ords = [];
          this.showError(e, 'Failed to get SubAreas from EC');
        });
  }

  private getRoutes() {
    this.loadingIndicatorSvc.show();
    // Grab Subsequent Route Ids
    this.omSvc.getRoutesPost(this.subareaIds)
      .pipe(
        finalize((() => {
          this.loadingIndicatorSvc.hide();
          this.saveUserRoute();
        })))
      .subscribe((data: Route[]) => {
        data.forEach((route) => {
          this.routeIds.push(route.ID);
        });
        // this.getORDs();
      },
        e => {
          // this.ords = [];
          this.showError(e, 'Failed to get Route Ids from EC');
        });
  }

  private saveUserRoute() {
    // Call API
    this.loadingIndicatorSvc.show();
    if (this.routeIds.length > 0) {
      this.userSvc.addUserRoute(this.CAI, this.routeIds)
        .pipe(
          finalize((() => {
            this.loadingIndicatorSvc.hide();
            this.emptyRouteInfo();
            this.emptyInfo();
          })))
        .subscribe(value => {
          if (value || value.Status === 'Success') {
            this.messageSvc.add({ severity: 'success', summary: 'User Route Added', detail: 'User Route for \"' + this.CAI + '\" has been saved.' });
          }
        },
          e => {
            this.showError(e, 'Failed to add new user');
          });
    } else {
      this.emptyRouteInfo();
      this.emptyInfo();
      this.loadingIndicatorSvc.hide();
    }
  }
}

