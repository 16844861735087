import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlanSubSection } from '#models/task-plan-sub-section';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-task-plan-sub-section-edit',
  templateUrl: './task-plan-sub-section-edit.component.html'
})
export class TaskPlanSubSectionEditComponent implements OnInit {
  taskPlanId: number;
  taskPlanSubSection: TaskPlanSubSection = new TaskPlanSubSection();
  displayWarningDesc: boolean;
  sectionId: number;
  ordID: number;

  constructor(
    private taskPlanSvc: TaskPlanService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    private messageSvc: MessageService
  ) { }

  onConfirm() {
    this.messageSvc.clear('c');
  }

  onReject() {
    this.messageSvc.clear('c');
  }

  ngOnInit() {
    this.aRoute.params.subscribe(v => {
      this.taskPlanId = v['taskPlanId'];
      this.taskPlanSubSection.ID = v['id'];
      this.sectionId = v['sectionId'];
      this.ordID = v['ordID'];
    });
    this.displayWarningDesc = false;
    this.getTaskPlanSubSectionById(this.taskPlanSubSection.ID);
  }

  public getTaskPlanSubSectionById(id: number): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.getTaskPlanSubSectionById(id)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: TaskPlanSubSection[]) => {
        this.taskPlanSubSection = data.pop();
      });
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/TaskPlan-manage', { id: this.taskPlanId, sectionId: this.sectionId, ordID: this.ordID }]);
  }

  public updateTaskPlanSubSection(): void {
    this.displayWarningDesc = this.taskPlanSvc.isInvalidString(this.taskPlanSubSection.Description);
    if (!this.displayWarningDesc) {
      if (this.ordID == 1) {
        this.loadingIndicatorSvc.show();
        this.taskPlanSvc.updateAndCloneTaskPlanSubSection(this.taskPlanSubSection)
          .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe((data: any) => {
            this.onRedirectButtonClick();
          }, exception => {
            if (exception instanceof HttpErrorResponse) {
              switch (exception.status) {
                case 0:
                  this.loadingIndicatorSvc.hide();
                  this.messageSvc.clear();
                  this.messageSvc.add({
                    key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                    detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                  });
                  break;
              }
            }
          });
      } else {
        this.loadingIndicatorSvc.show();
        this.taskPlanSvc.updateTaskPlanSubSection(this.taskPlanSubSection)
          .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe((data: any) => {
            this.onRedirectButtonClick();
          });
      }
    }
  }
}
