import { Component, OnInit } from '@angular/core';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {
  netImage:any = "src/assets/images/spinner.gif";
  title = 'app';
  constructor(
    public loadingIndicatorSvc: LoadingIndicatorService
  ) { }

  ngOnInit() {
  }
}
