
<p-dataView  #dv [value]="responsesIncomplete"  *ngIf="responsesIncomplete && responsesIncomplete.length > 0" [sortField]="sortField" [sortOrder]="sortOrder"
    filterBy="ScheduleName, ActualCompletions,ExpectedCompletions">
    <p-header> 
        <div class="ug clearfix report-header">
            <div class="col p-g-3" [ngClass]="{'sorted': sortField == 'CompletedDate', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
                <a (click)="onSortChange('TaskPlanDescription')">Equipment - Part</a>
            </div> 
            <div class="col p-g-3" [ngClass]="{'sorted': sortField == 'ScheduleName', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
                <a (click)="onSortChange('ScheduleName')">Schedule Type</a>
            </div>
           
            <div class="col p-g-3" >
                <a (click)="onSortChange('ExpectedCompletions')">Expected</a>
            </div>
            <div class="col p-g-3" >
                <a (click)="onSortChange('ActualCompletions')">Completed</a>
            </div>
        
        </div>
    </p-header>
    <ng-template let-a pTemplate="listItem">
        <div class="ug report-row">
            <div [hidden]="(this.onlyShowIncomplete==true && a.MissingResponses==false)" [ngClass]="{'redOverdue' : a.MissingResponses == true}" class="col p-g-3 responseItem">{{a.TaskPlanDescription}} - {{a.TaskPlanSectionDescription}}</div>
            
            <div [hidden]="(this.onlyShowIncomplete==true && a.MissingResponses==false)" *ngIf="a.ScheduleName != null  && a.ScheduleName != ''" [ngClass]="{'redOverdue' : a.MissingResponses == true}" class="col p-g-3 responseItem">{{a.ScheduleName}}</div>
            <div [hidden]="(this.onlyShowIncomplete==true && a.MissingResponses==false)" *ngIf="a.ScheduleName == null || a.ScheduleName == ''" [ngClass]="{'redOverdue' : a.MissingResponses == true}" class="col p-g-3 responseItem"></div>
            
            <div [hidden]="(this.onlyShowIncomplete==true && a.MissingResponses==false)" *ngIf="a.ExpectedCompletions != null" [ngClass]="{'redOverdue' : a.MissingResponses == true}" class="col p-g-3 responseItem">{{a.ExpectedCompletions}}</div>
            <div [hidden]="(this.onlyShowIncomplete==true && a.MissingResponses==false)" *ngIf="a.ExpectedCompletions == null || a.ExpectedCompletions == undefined" [ngClass]="{'redOverdue' : a.MissingResponses == true}" class="col p-g-3 responseItem"></div>
            
            <div [hidden]="(this.onlyShowIncomplete==true && a.MissingResponses==false)" *ngIf="a.ActualCompletions != null " [ngClass]="{'redOverdue' : a.MissingResponses == true}" class="col p-g-3 responseItem">{{a.ActualCompletions}}</div>
            <div [hidden]="(this.onlyShowIncomplete==true && a.MissingResponses==false)" *ngIf="a.ActualCompletions == null || a.ActualCompletions == undefined" [ngClass]="{'redOverdue' : a.MissingResponses == true}" class="col p-g-3 responseItem"></div>
            
            
        </div>
    </ng-template>
</p-dataView>
<div *ngIf="!responsesIncomplete || responsesIncomplete.length === 0">
    <h5>No data</h5>
</div>