import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlanItem } from '#models/task-plan-item';
import { TaskPlanItemOption } from '#models/task-plan-item-option';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { TaskPlanSubSection } from '#models/task-plan-sub-section';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from "rxjs/operators";

@Component({
  selector: 'app-task-plan-item-create',
  templateUrl: './task-plan-item-create.component.html',
  styleUrls: ['./task-plan-item-create.component.scss']
})

export class TaskPlanItemCreateComponent implements OnInit {
  taskPlanId: number;
  ordID: number;
  subSectionDesc: string;
  taskPlanItem: TaskPlanItem = new TaskPlanItem();
  taskPlanItemOption: TaskPlanItemOption = new TaskPlanItemOption();
  displayWarningDesc: boolean; displayOptionWarning: boolean //Input handling flags
  displayNullValueWarning: boolean = false;

  //display options
  displayDropdown: boolean;
  displayCheckbox: boolean;
  displayNumeric: boolean;
  displayTextField: boolean;
  displayMultipleDropdown: boolean;
  displayNumericWarning: boolean;

  //Numeric Input Values
  LowRange: number;
  HighRange: number;
  Units: string;

  sectionId: number;
  selectedItemOptionType: string;

  form = new FormGroup({
    name: new FormControl()
  });

  constructor(
    private taskPlanSvc: TaskPlanService,
    private messageSvc: MessageService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    private _renderer2: Renderer2
  ) { }

  onConfirm() {
    this.messageSvc.clear('c');
  }

  onReject() {
    this.messageSvc.clear('c');
  }

  ngOnInit() {
    this.aRoute.params.subscribe(v => {
      this.taskPlanId = v['taskPlanId'];
      this.taskPlanItem.TaskPlanSubSectionId = v['subSectionId'];
      this.getSubSection(this.taskPlanItem.TaskPlanSubSectionId);
      this.sectionId = v['sectionId'];
      this.ordID = v['ordID'];
    });

    //Set default display
    this.displayWarningDesc = false;
    this.displayOptionWarning = false;
    this.displayNumeric = false;
    this.displayDropdown = true;
    this.displayMultipleDropdown = false;

    this.selectedItemOptionType = 'Dropdown';
    this.taskPlanItem.ItemOptionType = 'Dropdown';
    this.taskPlanItem.TaskPlanItemOptions = [];

  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/TaskPlan-manage', { id: this.taskPlanId, sectionId: this.sectionId, ordID: this.ordID }]);
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  public getSubSection(subSectionId: number) {
    this.taskPlanSvc.getTaskPlanSubSectionById(subSectionId)
      .subscribe((data: TaskPlanSubSection[]) => {
        this.subSectionDesc = data[0].Description;
      });
  }

  // -------------Task Plan Item --------------------//

  public createTaskPlanItem(): void {
    this.displayWarningDesc = this.taskPlanSvc.isInvalidString(this.taskPlanItem.Description);
    this.displayNumericWarning = this.checkNumericValues();
    this.displayOptionWarning = this.checkOptions();
    if (!this.displayWarningDesc && !this.displayOptionWarning && !this.displayNumericWarning) {

      if (this.ordID == 1) {
        this.loadingIndicatorSvc.show();
        this.taskPlanSvc.createAndCloneTaskPlanItem(this.taskPlanItem)
          .pipe(
      finalize(() => {
            this.loadingIndicatorSvc.hide();
            this.onRedirectButtonClick();
          }))
          .subscribe((data: TaskPlanItem) => {
            if (data != null) {
              this.messageSvc.add({
                severity: 'success',
                summary: 'Item and Options were added',
                detail: 'Item and all options were successfully saved.'
              });
            }
          }, exception => {
            this.showError(exception, 'Item/Item Option created failed');
            if (exception instanceof HttpErrorResponse) {
              switch (exception.status) {
                case 0:
                  this.loadingIndicatorSvc.hide();
                  this.messageSvc.clear();
                  this.messageSvc.add({
                    key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                    detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                  });
                  break;
              }
            }
          });
      } else {
        this.loadingIndicatorSvc.show();
        this.taskPlanSvc.createTaskPlanItem(this.taskPlanItem)
          .pipe(
      finalize(() => {
            this.loadingIndicatorSvc.hide();
            this.onRedirectButtonClick();
          }))
          .subscribe((data: TaskPlanItem) => {
            if (data != null) {
              this.messageSvc.add({
                severity: 'success',
                summary: 'Item and Options were added',
                detail: 'Item and all options were successfully saved.'
              });
            }
          },
            e => this.showError(e, 'Item/Item Option created failed'));
      }
    }
  }

  // --------------- Task Plan Item Option ------------------------//
  showItemOptions(optionType: string): void {
    if (optionType === 'Dropdown') {
      this.displayDropdown = true;
      this.displayCheckbox = false; this.displayNumeric = false; this.displayTextField = false;
      this.displayMultipleDropdown = false;
      this.taskPlanItem.ItemOptionType = 'Dropdown';
      this.taskPlanItem.TaskPlanItemOptions = [];
    }
    else if (optionType === 'Checkbox') {
      this.displayCheckbox = true;
      this.displayDropdown = false; this.displayNumeric = false; this.displayTextField = false;
      this.displayMultipleDropdown = false;
      this.taskPlanItem.ItemOptionType = 'Checkbox';
      this.taskPlanItem.TaskPlanItemOptions = [];
    }
    else if (optionType === 'Numeric') {
      this.displayNumeric = true;
      this.displayDropdown = false; this.displayCheckbox = false; this.displayTextField = false;
      this.displayMultipleDropdown = false;
      this.taskPlanItem.ItemOptionType = 'Numeric';
      this.taskPlanItem.TaskPlanItemOptions = [];
    }
    else if (optionType === 'Textfield') {
      this.displayTextField = true;
      this.displayDropdown = false; this.displayCheckbox = false; this.displayNumeric = false;
      this.displayMultipleDropdown = false;
      this.taskPlanItem.ItemOptionType = 'Textfield';
      this.taskPlanItem.TaskPlanItemOptions = [];
    }
    else if (optionType === "Multiple-Dropdown") {
      this.displayMultipleDropdown = true;
      this.displayDropdown = false;
      this.displayCheckbox = false;
      this.displayNumeric = false;
      this.displayTextField = false;
      this.taskPlanItem.ItemOptionType = "Multiple-Dropdown";
      this.taskPlanItem.TaskPlanItemOptions = [];
    }
  }

  addItemOption(description: string): void {
    let option = new TaskPlanItemOption();
    if (description === null || description === undefined || description.trim() === "") {
      this.displayNullValueWarning = true;
      return;
    }
    this.displayNullValueWarning = false;
    if (this.selectedItemOptionType === 'Numeric') {
      this.taskPlanItem.TaskPlanItemOptions = []; //We do not want more than one range
      option.MaxValue = this.HighRange;
      option.MinValue = this.LowRange;
      option.Units = this.Units;
    }
    else if (this.selectedItemOptionType === 'Textfield') {
      this.taskPlanItem.TaskPlanItemOptions = []; //We only want one title for the text field
    }
    option.Description = description;
    option.Type = this.selectedItemOptionType;
    this.taskPlanItem.TaskPlanItemOptions.push(option);
    this.setFirstLastFlags();
    this.form.reset();
  }

  //Moves do not use API in create screen, bc Item Options are stored locally until final 'Save' is hit
  moveUpItemOption(desc: string): void {
    this.loadingIndicatorSvc.show();
    this.swapItem(desc, -1);
    this.setFirstLastFlags();
    this.loadingIndicatorSvc.hide();
  }

  moveDownItemOption(desc: string): void {
    this.loadingIndicatorSvc.show();
    this.swapItem(desc, 1);
    this.setFirstLastFlags();
    this.loadingIndicatorSvc.hide();
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  private swapItem(desc: string, possition: number): void {
    let optionIndex = this.taskPlanItem.TaskPlanItemOptions.findIndex(x => x.Description === desc);
    let temp = this.taskPlanItem.TaskPlanItemOptions[optionIndex + possition];
    this.taskPlanItem.TaskPlanItemOptions[optionIndex + possition] = this.taskPlanItem.TaskPlanItemOptions[optionIndex];
    this.taskPlanItem.TaskPlanItemOptions[optionIndex] = temp;
  }

  //Called after item options are moved, created, or deleted
  private setFirstLastFlags(): void {
    if (this.taskPlanItem.TaskPlanItemOptions.length == 1) {
      this.taskPlanItem.TaskPlanItemOptions[0].isFirstOption = true;
      this.taskPlanItem.TaskPlanItemOptions[0].isLastOption = true;
    }
    else if (this.taskPlanItem.TaskPlanItemOptions.length > 1) {
      this.taskPlanItem.TaskPlanItemOptions.forEach((opt) => {
        opt.isFirstOption = false;
        opt.isLastOption = false;
      });
      this.taskPlanItem.TaskPlanItemOptions[0].isFirstOption = true;
      this.taskPlanItem.TaskPlanItemOptions[this.taskPlanItem.TaskPlanItemOptions.length - 1].isLastOption = true;
    }

  }

  deleteItemOption(description: string): void {
    let element = this.taskPlanItem.TaskPlanItemOptions.find((element: TaskPlanItemOption) => {
      return element.Description === description;
    });
    this.taskPlanItem.TaskPlanItemOptions = this.taskPlanItem.TaskPlanItemOptions.filter(obj => obj !== element);
    this.setFirstLastFlags();

  }

  //Returns true if there is options are not ready to be saved,
  //False if no issues, this logic does not apply to numeric options
  checkOptions(): boolean {
    if (this.taskPlanItem.TaskPlanItemOptions.length === 0 && this.selectedItemOptionType != "Numeric") {
      return true;
    }
    else {
      return false;
    }
  }

  checkNumericValues(): boolean {
    if ((this.HighRange == null || this.LowRange == null || this.Units == null) && this.selectedItemOptionType === "Numeric") {
      return true;
    }
    else {
      if (this.taskPlanItem.TaskPlanItemOptions.length == 0 && this.selectedItemOptionType === "Numeric") {
        this.addItemOption(this.LowRange + ' ' + this.Units + ' - ' + this.HighRange + ' ' + this.Units);
      }
      return false;
    }
  }

  //------------------- General Page Functions ------------------------//

  //Supposed to move focus on Enter key from Low field to High field, but not currently working
  @ViewChild("High") private High: ElementRef;
  focusHighInput(): void {
    this.High.nativeElement.focus();
  }


}