<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px">
    <h2>Edit ORD - {{ord.Name}} </h2>
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-md-2">Name</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="ord.Name">
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Description</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="ord.Description" >
                <span class="text-danger field-validation-error" *ngIf="displayWarningDesc"> 
                    <span>Please fill in all required fields.</span>
                </span>
            </div>
        </div>
        <div class="form-group">
            <span class="text-danger field-validation-error" *ngIf="displayWarningHeirarchy">
                <span>The entire route heirarchy is required.</span>
            </span>
            <label class="control-label col-md-2">Area</label>
            <div class="col-md-10">                
                <p-dropdown  [options]="Areas" [(ngModel)]="selectedArea" placeholder="Select an Area" [showClear]="true"
                optionLabel="label"   [filter]="true" (onChange) = "clearSubArea()" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Sub-Area</label>
            <div class="col-md-10">                
                <p-dropdown [options]="subAreas" [(ngModel)]="selectedSubArea" [disabled]="disableSubArea" [showClear]="true"
                optionLabel="label"   [filter]="true" (onChange) = "clearRoute()" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Route</label>
            <div class="col-md-10">                
                <p-dropdown [options]="routes" [(ngModel)]="selectedRoute" [disabled]="disableRoute"
                optionLabel="label"   [showClear]="true" (onChange) = "clearColPoint()" [filter]="true" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Collection Point</label>
            <div class="col-md-10">                
                <p-dropdown [options]="colPoints" [(ngModel)]="selectedColPoint" [disabled]="disableColPoint"
                optionLabel="label"    [showClear]="true" (onChange) = "updateCollectionPoint()" [filter]="true" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-primary" style="width: 100px;" (click)="updateORD()">Save</a>
            </div>
        </div>
    </div>    
</div>

<div style="width:80%; margin:auto">
    <a class="btn btn-primary"(click)="onRedirectButtonClick()" style="cursor: pointer;">Back to ORD</a> 
</div>

