<div *ngIf="canBeDeleted" class="box-shadow module" style="width:80%; margin:auto; background-color: midnightblue; color: white">
  <h2>Attention!</h2>
  <hr>
  <h3>Are you sure you want to delete the Generic Equipment Type below?</h3>
  <div>
      <div style="width: 30%; margin:auto">
          <!-- <div class="row" style="padding-top:10px">
              <div class="col-xs-6" style="text-align:right">
                 Code:
              </div>
              <div class="col-xs-6">
                  {{taskPlan.Code}}
              </div>
          </div> -->
          <div class="row" style="padding-bottom:10px">
              <div class="col-xs-6" style="text-align:right">
                  Description:
              </div>
              <div class="col-xs-6">
                  {{taskPlan.Description}}
              </div>
          </div>
      </div>
      <h4>All the related section(s) | subsection(s) | item(s) will be removed.</h4>
      <div class="form-actions no-color">
          <div class="centerButtonContainer">
              <a class="btn btn-danger" style="width: 100px; cursor:pointer" (click)="deleteTaskPlan()" >Delete</a>
              &nbsp;
              &nbsp;
              <a class="btn btn-primary" style="width: 100px; cursor:pointer" (click)="onRedirectButtonClick()">Back</a>
          </div>
      </div>    
  </div>
</div>

<div *ngIf="!canBeDeleted"  class="box-shadow module" style="width:80%; margin:auto; background-color: midnightblue; color: white">
  <h3>Generic Equipment Type "[{{taskPlan.Description}}]" can not be removed</h3> 
  <h4>There are existing items using it !</h4>
  <hr>
  <div class="centerButtonContainer">
      <a class="btn btn-danger" style="cursor: pointer;" href="/ord/generic">Back</a>
  </div>
</div>