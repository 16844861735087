import { Component, OnInit, Inject } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import {Area} from '#models/area';
import { Subarea } from 'models/subarea';
import { Route } from 'models/route';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { ORDTemplate } from '#models/ordTemplate';
import { ORDService } from '#services/api/ord.service';
import { BusinessUnit } from '#models/business-unit';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-generic',
  templateUrl: './generic.component.html',
  styleUrls: ['./generic.component.scss']
})

export class GenericComponent implements OnInit {
  public taskPlans: TaskPlan[];
  public ord: ORDTemplate;
  public areas: Area[];
  public subareas: Subarea[];
  public routes: Route[];
  public OrdTemplateID: number;
  public firstTPId: number;
  public lastTPId: number;
  private storedData: any = [];
  private selectedBU: BusinessUnit;

  constructor(
    private taskPlanSvc: TaskPlanService,
    private omSvc: OMWorkbenchService,
    private ordSvc: ORDService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) { }

  ngOnInit() {
    this.getFromLocal("BusinessUnits");
    this.getFromLocal("BU");
    const data: BusinessUnit[] = this.storedData["BusinessUnits"];
    this.selectedBU = this.storedData["BU"];

    if (this.selectedBU.Id === 0) {
      this.saveInLocal("BU", data[1]);
      this.selectedBU = data[1];
    }

    this.ord = new ORDTemplate();
    // this.aRoute.params.subscribe(v => {
    //   this.OrdTemplateID = 3; // v['id'];
    // });
    this.OrdTemplateID = 1;
    this.loadTaskPlans();
  }

  private saveInLocal(key: string, val: any): void {
    this.storage.set(key, val);
    this.storedData[key] = this.storage.get(key);
  }
  
  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  onRedirectButtonClick(sufix: string, id?: number): void {
    if (id !== undefined && id != null) {
      this._router.navigate(['ord/' + sufix, {ordID: this.OrdTemplateID, id: id}]);
    } else {
      this._router.navigate(['ord/' + sufix, {ordID: this.OrdTemplateID}]);
    }
  }

  private loadTaskPlans(): void {
    this.loadingIndicatorSvc.show();
    const buID = this.selectedBU.Id;

    this.taskPlanSvc.getTaskPlanSimple(this.OrdTemplateID, buID)
    .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: TaskPlan[]) => {
        this.taskPlans = data.filter(obj => obj.IsGeneric === true);
        this.sortTaskPlans();
      });
  }

  // ------------------------------Moving Task Plans-------------------------------------------------//
  moveUpTaskPlan(id: number): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.moveUpTaskPlan(id)
    .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
    .subscribe((data: any) => {
      if (data.Success) {
        this.swapItem(id, -1);
        this.sortTaskPlans();
      }
    });
  }

  moveDownTaskPlan(id: number): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.moveDownTaskPlan(id)
    .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
    .subscribe((data: any) => {
      if (data.Success) {
        this.swapItem(id, 1);
        this.sortTaskPlans();
      }
    });
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  private swapItem(id: number, possition: number): void {
    const taskPlanIndex = this.taskPlans.findIndex(x => x.ID === id);
    const temp = this.taskPlans[taskPlanIndex + possition];
    this.taskPlans[taskPlanIndex + possition] = this.taskPlans[taskPlanIndex];
    this.taskPlans[taskPlanIndex] = temp;
  }

  private sortTaskPlans(selectLastSection: boolean = false): void {
    const list = this.taskPlans;
    if (list.length > 0) {
      this.taskPlans = list.sort(function(obj1: TaskPlan, obj2: TaskPlan) { return obj1.sortId - obj2.sortId;  });
      this.setFirstLastFlags();
    }
  }

  private setFirstLastFlags(): void {
    this.firstTPId = this.taskPlans[0].ID;
    this.lastTPId = this.taskPlans[this.taskPlans.length - 1].ID;
  }
}
