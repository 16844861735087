import { Component, OnInit, AfterViewChecked, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '#services/shared';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private authService: AuthService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private router: Router) { }

  async ngOnInit() {
    var token = await this.authService.getToken();
    this.storage.set("token", "Bearer " + token);
    this.authService.loadUserInfo();
  }

  onConfirm() {
  }

  onReject() {
  }
}
