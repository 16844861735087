import { Component, OnInit } from '@angular/core';
import { ORDService } from '#services/api/ord.service';
import { ORDTemplate } from '#models/ordTemplate';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-ord-template-delete',
  templateUrl: './ord-template-delete.component.html',
  styleUrls: ['./ord-template-delete.component.scss']
})
export class ORDTemplateDeleteComponent implements OnInit {
  ord: ORDTemplate = new ORDTemplate();
  ordId: number;
  canBeDeleted: boolean;

  constructor(
    private ordSvc: ORDService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(v => {
      this.ordId = v['id'];
    });
    this.getordById(this.ordId);
    this.canBeDeleted = true;
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/field']);
  }

  public getordById(id: number): void {
    this.loadingIndicatorSvc.show();
    this.ordSvc.getORDTemplateByID(id)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        this.ord = data;
      });
  }

  public deleteord(): void {
    this.loadingIndicatorSvc.show();
    this.ordSvc.deleteORDTemplate(this.ordId)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        this.onRedirectButtonClick();
      });
  }
}
