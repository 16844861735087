import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute} from '@angular/router';
import {SelectItem} from 'primeng/api';
import { Equipment } from '#models/equipment';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { ORDService } from '#services/api/ord.service';
import { ORDTemplate } from '#models/ordTemplate';
import { EquipmentEC } from '#models/equipmentEC';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-generic-edit',
  templateUrl: './generic-edit.component.html',
  styleUrls: ['./generic-edit.component.scss']
})
export class GenericEditComponent implements OnInit {
  taskPlan: TaskPlan = new TaskPlan();
  taskPlanId: number;
  ordTemplateID: number;
  ordTemplate: ORDTemplate;
  displayWarningDesc: boolean;
  Equipments: SelectItem[];

  constructor(
    private taskPlanSvc: TaskPlanService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private omSvc: OMWorkbenchService,
    private ordSvc: ORDService,
    private aRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.Equipments = [];
    this.aRoute.params.subscribe(v => {
      this.taskPlanId = v['id'];
      this.ordTemplateID = v['ordID'];
    });
    this.getORDTemplateById(this.ordTemplateID);
    this.displayWarningDesc = false;
  }

  private getORDTemplateById(ordTempID: number) {
    this.loadingIndicatorSvc.show();
    this.ordSvc.getORDTemplateByID(ordTempID)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: ORDTemplate) => {
        this.ordTemplate = data;
        this.getTaskPlanById(this.taskPlanId);
    });
  }

  private loadEquipment() {
    this.loadingIndicatorSvc.show();
    const objIds: string[] = [];
    // Grab Equipment from the TaskPlan, and add the OBJ IDs to an exception List
    this.taskPlan.Equipment.forEach((equipment) => {
      objIds.push(equipment.ObjectId);
    });

    this.omSvc.getEquipment(this.ordTemplate.RouteID)
    .pipe(
     finalize(() => this.loadingIndicatorSvc.hide()))
    .subscribe((data: EquipmentEC[]) => {
      // For every EC Equipment
      data.forEach((eqEC) => {
        const equipment = new Equipment(eqEC);
        // Check if already accounted for by pre-selected EQ
        if (!objIds.includes(eqEC.ID)) {
          const element: SelectItem = {label: equipment.Name, value: equipment};
          this.Equipments.push(element);
        } else {
          const element: SelectItem = { label: equipment.Name, value: this.taskPlan.Equipment.find(x => x.ObjectId === eqEC.ID) };
          this.Equipments.push(element);
        }
      });
    });
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/generic', {id: this.ordTemplateID}]);
  }

  public getTaskPlanById(id: number): void {
    this.loadingIndicatorSvc.show();
        this.taskPlanSvc.getTaskPlanById(id)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          this.taskPlan = data.pop();
           this.loadEquipment();
        });
  }

  public updateTaskPlan(): void {
    this.displayWarningDesc = this.taskPlan.Description === undefined || this.taskPlan.Description == null || this.taskPlan.Description.length === 0;
    if (!this.displayWarningDesc) {
      this.loadingIndicatorSvc.show();
        this.taskPlanSvc.updateTaskPlans([this.taskPlan])
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          this.onRedirectButtonClick();
        });
    }
  }
}
