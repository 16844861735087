<div class="box-shadow module form-horizontal" style="margin: auto auto 20px; width: 80%;">
    <h3 class="white-space-pre">Edit ORD Item For Operator Task - {{ '\n' + subSectionDesc}}</h3>

    <div class="form-group">
        <div style="text-align: center;">
            {{taskPlanItem.Code}}
        </div>
    </div>
    <div class="form-group">
        <label class="control-label col-md-2">Description</label>
        <div class="col-md-10">
            <input [disabled]="displayStatus" class="form-control text-box single-line"
                [(ngModel)]="taskPlanItem.Description">
            <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
                <span>The Description field is required.</span>
            </span>
            <span class="text-danger field-validation-error" *ngIf="displayNullValueWarning">
                <span>Options must not be blank/null.</span>
            </span>
            <br>
            <span class="text-danger field-validation-error" *ngIf="displayNumericWarning">
                <span>Missing Numeric high, low, or units Values</span>
            </span>
        </div>
    </div>

    <div class="ItemOptionContent">
        <!--Start of Item Options, display content based on what radio button is chosen-->
        <!--Status Options-->
        <div class="StatusboxOptions" style="padding-left: 19%; padding-bottom: 5%;" *ngIf="displayStatus">
            <p-inputSwitch [(ngModel)]="taskPlanItem.IncludeRepairBox">
            </p-inputSwitch>
            <label id="inputSwitchLabel">Include "Repair/Adjust" Status Option? </label>
        </div>
        <!--Dropdown Item Options-->
        <div class="DropdownOptions" style="padding-left: 19%; padding-bottom: 5%" *ngIf="displayDropdown">
            <div class='col-sm-4' [formGroup]="form">
                <label>Please add values to your dropdown list:</label>
                <input #newInput id="Dropdown-input" type="text" size="30" (keyup.enter)="addItemOption(newInput.value)"
                    formControlName="name" />
                <button id="dropdown-addOption-btn" type="button" (click)="addItemOption(newInput.value)" pButton
                    label="Add Option"></button>
            </div>
            <div class='col-sm-4'>
                <div *ngIf="itemOptions.length > 0">
                    <h3 class="subSectionHeader">
                        <span class="text-tab">Dropdown Options</span>
                    </h3>
                </div>
                <!-- dropdown option list -->
                <table class="table table-hover" style="width:100%; margin-bottom: 20px; border-color: black;"
                    frame="box" bordercolor="black">
                    <tr *ngFor="let itemOp of itemOptions">
                        <td style="width:80%">
                            <label>
                                {{itemOp.Description}}
                            </label>
                        </td>
                        <td>
                            <a (click)="moveUpItemOption(itemOp.Description)">
                                <img src="assets/images/upTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                    *ngIf="!itemOp.isFirstOption" />
                            </a>
                        </td>
                        <td>
                            <a (click)="moveDownItemOption(itemOp.Description)">
                                <img src="assets/images/dnTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                    *ngIf="!itemOp.isLastOption" />
                            </a>
                        </td>
                        <td>
                            <a (click)="deleteItemOption(itemOp.Description)">
                                <img src="assets/images/cross_color.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                            </a>
                        </td>
                    </tr>
                </table> <!-- end dropdown option list -->
            </div>
        </div>
        <!--End of Dropdown Item Options-->
        <!--Checkbox Item Options-->
        <div class="CheckboxOptions" style="padding-left: 19%; padding-bottom: 5%" *ngIf="displayCheckbox">
            <div class='col-sm-4' [formGroup]="form">
                <label>Please add values to your checkbox list:</label>
                <input #newInput id="Checkbox-input" type="text" size="30" (keyup.enter)="addItemOption(newInput.value)"
                    formControlName="name" />
                <button id="Checkbox-addOption-btn" type="button" (click)="addItemOption(newInput.value)" pButton
                    label="Add Option"></button>
            </div>
            <div class='col-sm-4'>
                <div *ngIf="itemOptions.length > 0">
                    <h3 class="subSectionHeader">
                        <span class="text-tab">Checkbox Options</span>
                    </h3>
                </div>
                <!-- checkbox option list -->
                <table class="table table-hover" style="width:100%; margin-bottom: 0px; border-color: black;"
                    frame="box" bordercolor="black">
                    <tr *ngFor="let itemOp of itemOptions">
                        <td style="width:80%">
                            <label>
                                {{itemOp.Description}}
                            </label>
                        </td>
                        <td>
                            <a (click)="moveUpItemOption(itemOp.Description)">
                                <img src="assets/images/upTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                    *ngIf="!itemOp.isFirstOption" />
                            </a>
                        </td>
                        <td>
                            <a (click)="moveDownItemOption(itemOp.Description)">
                                <img src="assets/images/dnTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                    *ngIf="!itemOp.isLastOption" />
                            </a>
                        </td>
                        <td>
                            <a (click)="deleteItemOption(itemOp.Description)">
                                <img src="assets/images/cross_color.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                            </a>
                        </td>
                    </tr>
                </table> <!-- end checkbox option list -->
            </div>
        </div>
        <!--End of Checkbox Item Options-->
        <!--Numeric Item Options-->
        <div class="NumericOptions" style="padding-left: 19%; padding-bottom: 10%" *ngIf="displayNumeric">
            <div class='col-sm-4' style="text-align: right">
                <div class=row style="padding-bottom: 2%">
                    <label style="padding-right: 2%">Low Range: </label>
                    <p-spinner [step]="0.1" #Low size="30" [(ngModel)]="LowRange" [max]="HighRange"
                        (keydown.enter)="focusHighInput()"></p-spinner>
                </div>
                <div class=row style="padding-bottom: 2%">
                    <label style="padding-right: 2%">High Range: </label>
                    <p-spinner [step]="0.1" #High size="30" [(ngModel)]="HighRange" [min]="LowRange"></p-spinner>
                </div>
                <div class=row style="padding-bottom: 2%">
                    <label style="padding-right: 2%">Unit of Measure: </label>
                    <input #units id="Numeric-input" type="text" size="25" [(ngModel)]="Units"
                        (keyup.enter)="addItemOption(Low.value + ' ' + Units + ' - ' + High.value + ' ' +  Units)" />
                    <p-button id="Numeric-addOption-btn"
                        (onClick)="addItemOption(Low.value + ' ' + Units + ' - ' + High.value + ' ' +  Units)"
                        icon="pi pi-check"></p-button>
                </div>
            </div>
            <div class='col-sm-4'>
                <div *ngIf="itemOptions.length > 0">
                    <h3 class="subSectionHeader">
                        <span class="text-tab">Range and Units</span>
                    </h3>
                </div>
                <!-- numeric option list -->
                <table class="table table-hover" style="width:100%; margin-bottom: 0px; border-color: black;"
                    frame="box" bordercolor="black">
                    <tr *ngFor="let itemOp of itemOptions">
                        <td style="width:80%">
                            <label>
                                {{itemOp.MinValue}} {{itemOp.Units}} - {{itemOp.MaxValue}} {{itemOp.Units}}
                            </label>
                        </td>
                        <td>
                            <a (click)="deleteItemOption(itemOp)">
                                <img src="assets/images/cross_color.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                            </a>
                        </td>
                    </tr>
                </table> <!-- end numeric option list -->
            </div>
        </div>
        <!--End of Numeric Item Options-->
        <!--Text Field Item Options-->
        <div class="TextFieldOptions" style="padding-left: 19%; padding-bottom: 5%" *ngIf="displayTextField">
            <div class='col-sm-4' [formGroup]="form">
                <label>Please Enter a Title for the Text Field:</label>
                <input #newInput id="textField-input" type="text" size="30"
                    (keyup.enter)="addItemOption(newInput.value)" formControlName="name" />
                <button id="Checkbox-addOption-btn" type="button" (click)="addItemOption(newInput.value)" pButton
                    label="Add Title"></button>
            </div>
            <div class='col-sm-4'>
                <div *ngIf="itemOptions.length > 0">
                    <h3 class="subSectionHeader">
                        <span class="text-tab">Text Field Title</span>
                    </h3>
                </div>
                <!-- text field table -->
                <table class="table table-hover" style="width:100%; margin-bottom: 0px; border-color: black;"
                    frame="box" bordercolor="black">
                    <tr *ngFor="let itemOp of itemOptions">
                        <td style="width:80%">
                            <label>
                                {{itemOp.Description}}
                            </label>
                        </td>

                    </tr>
                </table> <!-- end text field table -->
            </div>
        </div>
        <!--End of Text Field Item Options-->

        <!-- Multiple-Dropdown Item Options-->
        <div class="multipleDropdownOptions" style="padding-left: 19%; padding-bottom: 5%;"
            *ngIf="displayMultipleDropdown">
            <div class="col-sm-4" [formGroup]="form">
                <label>Please add values to your Multiple-Dropdown list:</label>
                <input #newInput id="Dropdown-input" type="text" size="30" (keyup.enter)="addItemOption(newInput.value)"
                    formControlName="name" />
                <button id="dropdown-addOption-btn" type="button" (click)="addItemOption(newInput.value)" pButton
                    label="Add Option"></button>
            </div>
            <div class="col-sm-4">
                <div *ngIf="itemOptions.length > 0">
                    <h3 class="subSectionHeader">
                        <span class="text-tab">Multiple-Dropdown Options</span>
                    </h3>
                </div>
                <!-- Multiple-Dropdown option list -->
                <table class="table table-hover" style="width: 100%; margin-bottom: 20px; border-color: black;"
                    frame="box" bordercolor="black">
                    <tr *ngFor="let itemOp of itemOptions">
                        <td style="width: 80%;">
                            <label>
                                {{ itemOp.Description }}
                            </label>
                        </td>
                        <td>
                            <a (click)="moveUpItemOption(itemOp.Description)">
                                <img src="assets/images/upTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                    *ngIf="!itemOp.isFirstOption" />
                            </a>
                        </td>
                        <td>
                            <a (click)="moveDownItemOption(itemOp.Description)">
                                <img src="assets/images/dnTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover"
                                    *ngIf="!itemOp.isLastOption" />
                            </a>
                        </td>
                        <td>
                            <a (click)="deleteItemOption(itemOp.Description)">
                                <img src="assets/images/cross_color.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                            </a>
                        </td>
                    </tr>
                </table>
                <!-- end Multiple-Dropdown option list -->
            </div>
        </div>
        <!--End of Multiple-Dropdown Item Options-->

        <div class="form-group">
            <div class="col-md-6" style="padding-left: 19%;" *ngIf="!displayStatus">
                <p-checkbox [(ngModel)]="taskPlanItem.IsOptional" binary="true" label="  Optional?"></p-checkbox>
            </div>
        </div>

        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-primary" style="width: 100px;" (click)="updateTaskPlanItem()">Save</a>
            </div>
        </div>
    </div>

    <div>
        <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Manage Task Plan</a>
    </div>