export class TaskPlanItemOption {
    public ID: number;
    public sortID: number;
    public Description: string;
    public TaskPlanItemID: number;
    public MaxValue: number;
    public MinValue: number;
    public Type: string;
    public Units: string;

    // Only needed for Angular model
    public isFirstOption: boolean;
    public isLastOption: boolean;
    public isNew: boolean;
}
