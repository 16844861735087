import { Area } from "models/area";
import { Subarea } from "models/subarea";
import { Route } from "models/route";
import { Equipment } from "models/equipment";
import { CollectionPoint } from "models/collectionpoint";
import { HttpHeaders } from "@angular/common/http";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "#environments/environment";
import { Injectable, Inject } from "@angular/core";
import { EquipmentEC } from "#models/equipmentEC";
import { ProductionUnit } from "#models/production-unit";
import { ProductionSubUnit } from "#models/production-sub-unit";
import { FacilityClassOne } from "#models/facility-class-one";
import { FacilityClassTwo } from "#models/facility-class-two";
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service'; 

@Injectable()
export class OMWorkbenchService {

  constructor(private http: HttpClient, @Inject(LOCAL_STORAGE) private storage: WebStorageService) {}
  // Grabs areas for the CAI provided
  public getAreas(CAI: string): Observable<Area[]> {
    const url = this.getURLFromLocal();
    return this.http.get<Area[]>(
      `${url}/area?userID=${CAI}`
    );
  }

  public getAreasByProductionSubUnit(CAI: string, ProductionSubUnitId: string): Observable<Area[]> {
    const url = this.getURLFromLocal();
    return this.http.get<Area[]>(
      `${url}/area?userID=${CAI}&productionSubUnitId=${ProductionSubUnitId}`
    );
  }

  public getAllAreas(): Observable<Area[]> {
    const url = this.getURLFromLocal();
    return this.http.get<Area[]>(`${url}/area/getAllAreas`);
  }

  public getSubAreas(areaID: string): Observable<Subarea[]> {
    const url = this.getURLFromLocal();
    return this.http.get<Subarea[]>(
      `${url}/sub-area?areaID=${areaID}`
    );
  }

  public getSubAreasPost(areaIDs: string[]): Observable<Subarea[]> {
    const url = this.getURLFromLocal();
    return this.http.post<Subarea[]>(
      `${url}/sub-area`,
      areaIDs
    );
  }

  public getRoutes(subareaID: string): Observable<Route[]> {
    const url = this.getURLFromLocal();
    return this.http.get<Route[]>(
      `${url}/route?subAreaID=${subareaID}`
    );
  }

  public getRoutesPost(subareaIDs: string[]): Observable<Route[]> {
    const url = this.getURLFromLocal();
    return this.http.post<Route[]>(
      `${url}/route`,
      subareaIDs
    );
  }

  public getCollectionPoints(routeID: string): Observable<CollectionPoint[]> {
    const url = this.getURLFromLocal();
    return this.http.get<CollectionPoint[]>(
      `${url}/collection-point?routeID=${routeID}`
    );
  }

  public getEquipment(routeID: string): Observable<EquipmentEC[]> {
    const url = this.getURLFromLocal();
    const bodyValue = "{ \"RouteID\": \"" + routeID + "\" }";
    return this.http.post<EquipmentEC[]>(`${url}/Equipment`, bodyValue, { headers: { 'Content-Type': 'application/json' } });
  }

  public getProductionUnit(): Observable<ProductionUnit[]> {
    const url = this.getURLFromLocal();
    return this.http.get<ProductionUnit[]>(`${url}/ProductionUnit/GetProductionUnits`);
  }

  public getProductionSubUnit(): Observable<ProductionSubUnit[]> {
    const url = this.getURLFromLocal();
    return this.http.get<ProductionSubUnit[]>(`${url}/ProductionUnit/GetProductionSubUnits`);
  }

  public getFacilityClass2(subAreaId: string): Observable<FacilityClassTwo[]> {
    const url = this.getURLFromLocal();
    return this.http.get<FacilityClassTwo[]>(`${url}/FacilityClass/GetFacilityClass2?subAreaId=${subAreaId}`);
  }

  public getFacilityClass2Post(subAreaIds: string[]): Observable<FacilityClassTwo[]> {
    const url = this.getURLFromLocal();
    return this.http.post<FacilityClassTwo[]>(
      `${url}/FacilityClass/GetFacilityClass2`,
      subAreaIds
    );
  }

  public getFacilityClass1(facilityClass2Id: string): Observable<FacilityClassOne[]> {
    const url = this.getURLFromLocal();
    return this.http.get<FacilityClassOne[]>(`${url}/FacilityClass/GetFacilityClass1?facilityClass2Id=${facilityClass2Id}`);
  }

  public getFacilityClass1Post(facilityClass2Ids: string[]): Observable<FacilityClassOne[]> {
    const url = this.getURLFromLocal();
    return this.http.post<FacilityClassOne[]>(
      `${url}/FacilityClass/GetFacilityClass1`,
      facilityClass2Ids
    );
  }

  public getWell(cpID: string): Observable<Map<string, string>[]> {
    const url = this.getURLFromLocal();
    const bodyValue = "{ \"RouteIDs\": [\"" + cpID + "\"], \"SyncTime\": \"1900-01-01T00:00:00\" }";
    return this.http.post<Map<string, string>[]>(`${url}/Well`, bodyValue, { headers: { 'Content-Type': 'application/json' } });
  }

  // Method to return http header for post api
  private getPostAPIHeader(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders().set(
      "Content-Type",
      "application/json"
    );
    return headers;
  }

  private getURLFromLocal(): string {
    let url = this.storage.get("ecURL");
    if (url == null || url === undefined || url === "") {
      url = environment.OMEndpoint;
    }
    return url;
  }
}
