<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px">
    <h2>Edit ORD - {{ord.Name}} </h2>
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-md-2">Name</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line"  [(ngModel)]="ord.Name">
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Description</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="ord.Description" >
                <span class="text-danger field-validation-error" *ngIf="displayWarningDesc"> 
                    <span>Please fill in all required fields.</span>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="form-group">
                <span class="text-danger field-validation-error" *ngIf="displayWarningHeirarchy">
                    <span>The entire route heirarchy is required.</span>
                </span>
                <label class="control-label col-md-2">Production Unit</label>
                <label class="control-label col-md-10" style="text-align:left">{{prodUnitName}}</label>
            </div>
        </div>

        <div class="row">
            <div class="form-group">
                <label class="control-label col-md-2">Production Sub-Unit:</label>
                <div class="col-md-10 dropdown">
                    <p-dropdown [options]="ProdSubUnits" [(ngModel)]="selectedProdSubUnit" [showClear]="true"
                        optionLabel="Name" [filter]="true" (onChange)="getAreas()" [style]="{'width': '100%'}"
                        placeholder="Select a Production Sub-Unit">
                    </p-dropdown>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="form-group">
                <label class="control-label col-md-2">Area:</label>
                <div class="col-md-10 dropdown">
                    <p-dropdown [options]="Areas" [(ngModel)]="selectedArea" [showClear]="true" [disabled]="disableArea"
                        optionLabel="label" [filter]="true" (onChange)="getSubAreas()" [style]="{'width': '100%'}"
                        placeholder="Select an Area">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <!--SubAreas-->
        <div class="row">
            <div class="form-group">
                <label class="control-label col-md-2">Sub-Area</label>
                <div class="col-md-10 dropdown">
                    <p-dropdown [options]="subAreas" [(ngModel)]="selectedSubArea" [disabled]="disableSubArea"
                        [showClear]="true" optionLabel="label" [filter]="true" (onChange)="getFacilities2()"
                        placeholder="Select a Sub-Area">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <!--Facilities2-->
        <div class="row" style="padding-bottom: 16px">
            <div class="form-group">
                <label class="control-label col-md-2" >Facility Class 2</label>
                <div class="col-md-10 dropdown">
                    <p-dropdown [options]="Facilities2" [(ngModel)]="selectedFacility2" [disabled]="disableFacility2"
                        optionLabel="label" [showClear]="true" (onChange)="getFacilities1()" [filter]="true"
                        placeholder="Select a Facility 2">
                    </p-dropdown>
                </div>
            </div>
        </div>

        <!--Facilities1-->
        <div class="row" style="padding-bottom: 16px">
            <div class="form-group">
                <label class="control-label col-md-2">Facility Class 1</label>
                <div class="col-md-10 dropdown">
                    <p-dropdown [options]="Facilities1" [(ngModel)]="selectedFacility1" [disabled]="disableFacility1"
                        optionLabel="label" [showClear]="true" (onChange)="updateFacilities1()" [filter]="true"
                        placeholder="Select a Facility 1">
                    </p-dropdown>
                </div>
            </div>
        </div>


        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-primary" style="width: 100px;" (click)="updateORD()">Save</a>
            </div>
        </div>
    </div>    
</div>

<div style="width:80%; margin:auto">
    <a class="btn btn-primary"(click)="onRedirectButtonClick()" style="cursor: pointer;">Back to ORD</a> 
</div>
