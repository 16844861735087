<app-header></app-header>
<div class="notification-area">
    <p-toast position="top-right" [baseZIndex]="5000"></p-toast>
    <p-confirmDialog></p-confirmDialog>
    <p-toast position="center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div style="text-align: center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3em"></i>
                <h3>{{message.summary}}</h3>
                <p>{{message.detail}}</p>
            </div>
            <!-- <div class="p-g p-fluid">
                <div class="p-g-4"></div>     
                <div class="p-g-4">
                    <button type="button" pButton (click)="onConfirm()" label="Ok" class="p-button-success"></button>
                </div>
                <div class="p-g-4"></div>
            </div> -->
        </ng-template>
    </p-toast>
</div>
<div id="body">
    <main tabindex="-1" id="main-content" role="main">
        <div class="row">
            <div class="col col-sm-12">
                <app-welcome></app-welcome>
            </div>
        </div>
        <div style="min-height: 500px;">
            <router-outlet></router-outlet>
        </div>
    </main>
</div>

<app-footer></app-footer>

<app-loading-indicator></app-loading-indicator>
<app-feedback></app-feedback>
<app-notification></app-notification>