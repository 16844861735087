import { Component, OnInit, Inject } from '@angular/core';
import { ORDResponse } from '#models/ordResponse';
import { SelectItem, MessageService } from 'primeng/api';
import { Area } from '#models/area';
import { Subarea } from '#models/subarea';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { ORDTemplate } from '#models/ordTemplate';
import { ORDService } from '#services/api/ord.service';
import { LoadingIndicatorService, AuthService } from '#services/shared';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { BusinessUnit } from '#models/business-unit';
import { ProductionUnit } from '#models/production-unit';
import { ProductionSubUnit } from '#models/production-sub-unit';
import { FacilityClassTwo } from '#models/facility-class-two';
import { FacilityClassOne } from '#models/facility-class-one';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-op-field-report',
  templateUrl: './op-field-report.component.html',
  styleUrls: ['./op-field-report.component.scss']
})
export class OpFieldReportComponent implements OnInit {
  responses: ORDResponse[];
  responsesIncomplete: ORDResponse[];
  onlyShowIncomplete: boolean;
  startDate: Date;
  finishDate: Date;
  showReportFlag: boolean;
  reportName: string;
  Cai: string;
  isAdmin: boolean;
  public storedData: any = [];

  // Dropdown Options
  // ProdUnits: SelectItem[];
  prodUnitName: String = "";
  ProdSubUnits: ProductionUnit[];

  Areas: SelectItem[];
  subAreas: SelectItem[];
  Facilities2: SelectItem[];
  Facilities1: SelectItem[];
  ords: SelectItem[];
  equips: SelectItem[];

  // Object that is actually selected from Dropdown
  selectedProdUnit: ProductionUnit = new ProductionUnit();
  selectedProdSubUnit: ProductionSubUnit = new ProductionSubUnit();
  selectedArea: SelectItem;
  selectedSubArea: SelectItem;
  selectedFacility2: SelectItem;
  selectedFacility1: SelectItem;
  selectedORD: SelectItem;
  selectedEquipment: SelectItem[];

  // Flags to show/hide/disable content
  disableArea: boolean;
  disableSubArea: boolean;
  disableFacility2: boolean;
  disableFacility1: boolean;
  disableORD: boolean;
  disableEquipment: boolean;
  ordWarningMsg: boolean;
  equipWarningMsg: boolean;
  selectedTabReport: number;
  selectedBU: BusinessUnit;

  constructor(
    private omSvc: OMWorkbenchService,
    private ordSvc: ORDService,
    private loadingSvc: LoadingIndicatorService,
    private authSvc: AuthService,
    private messageSvc: MessageService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
    this.Cai = this.authSvc.getCurrentUser().Cai;
    this.isAdmin = this.authSvc.isSystemAdmin();
    this.selectedEquipment = [];
    this.ProdSubUnits = [{ Name: 'Select a Production Sub-Unit', ObjectId: null }];
    this.Areas = [{ label: 'Select an Area', value: null }];
    this.subAreas = [{ label: 'Select a Sub-Area', value: null }];
    this.Facilities2 = [{ label: 'Select a Facility', value: null }];
    this.Facilities1 = [{ label: 'Select a Facility', value: null }];
    this.equips = [{ label: 'Select an equipment', value: null }];
    this.selectedTabReport = 0;
    this.onlyShowIncomplete = false;
  }

  ngOnInit() {
    this.disableArea = true;
    this.disableSubArea = true;
    this.disableFacility2 = true;
    this.disableFacility1 = true;
    this.disableORD = true;
    this.ordWarningMsg = false;
    this.responses = [];
    this.equipWarningMsg = false;
    this.showReportFlag = false;
    this.selectedProdSubUnit=null;
    this.getFromLocal("BusinessUnits");
    this.getFromLocal("BU");
    const buArray: BusinessUnit[] = this.storedData["BusinessUnits"];
    this.selectedBU = this.storedData["BU"];

    if (this.selectedBU !== undefined && this.selectedBU != null) {
      if (this.selectedBU.Id === 0) {
        this.saveInLocal("BU", buArray[1]);
        this.selectedBU = buArray[1];
      }
    }
    this.loadingSvc.show();
    this.getProdUnit();
    this.getProdSubUnits();
    this.loadingSvc.hide();
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  getProdUnit() {
    this.loadingSvc.show();
    this.omSvc.getProductionUnit()
      .subscribe((data: ProductionUnit[]) => {
        this.loadingSvc.hide();
        this.prodUnitName = data[0].Name;
      }, e => {
        this.showError(e, 'Could not get ProdUnits from EC');
        this.loadingSvc.hide();
      });
  }

  getProdSubUnits() {
    this.getFromLocal("subunit");
    // this.selectedProdSubUnit = this.storedData["subunit"];
    this.selectedProdSubUnit = null;
    this.ProdSubUnits = [];
    this.loadingSvc.show();
    this.omSvc.getProductionSubUnit()
      .pipe(
        finalize(() => {
          this.loadingSvc.hide();
        }))
      .subscribe((data: ProductionSubUnit[]) => {
        data.forEach((unit) => {
          this.addProductionSubUnits(unit);
        });
      }, e => {
        this.ProdSubUnits = [];
        this.showError(e, 'Could not get ProdUnits from EC');
      });
  }

  private addProductionSubUnits(unit: ProductionSubUnit) {
    let element: ProductionSubUnit;

    element = { Name: unit.Name, ObjectId: unit.ObjectId };
    this.ProdSubUnits.push(element);
    // Make stored value appear as selected in dropdown
    if (this.selectedProdSubUnit != null && this.ProdSubUnits[this.ProdSubUnits.length - 1].ObjectId === this.selectedProdSubUnit.ObjectId) {
      this.selectedProdSubUnit = this.ProdSubUnits[this.ProdSubUnits.length - 1];
      this.getAreas();
    }
  }

  // Grab all areas if you are a sys admin, grab only those belonging to your cai if not
  getAreas() {
    this.saveInLocal("subunit", this.selectedProdSubUnit);
    this.getFromLocal("area");
    this.selectedArea = this.storedData["area"];
    if (this.selectedProdSubUnit == null) {
      // Empty out sub fields
      this.selectedArea = null;
      this.selectedSubArea = null;
      this.selectedFacility2 = null;
      this.selectedFacility1 = null;
      // this.selectedORD = null;

      // Disable fields
      this.disableArea = true;
      this.disableSubArea = true;
      this.disableFacility2 = true;
      this.disableFacility1 = true;
      this.disableORD = true;
      return;
    }
    this.Areas = [];
    this.loadingSvc.show();
    this.omSvc.getAreasByProductionSubUnit(this.Cai, this.selectedProdSubUnit.ObjectId)
      .pipe(
        finalize(() => {
          // Handles disabling input fields
          if (this.selectedProdSubUnit === null) {
            this.disableArea = true;
            this.disableSubArea = true;
            this.disableFacility2 = true;
            this.disableFacility1 = true;
            this.disableORD = true;
          } else {
            this.disableArea = false;
          }
          this.loadingSvc.hide();
        }))
      .subscribe((data: Area[]) => {
        data.forEach((area) => {
          this.addArea(area);
        });
      }, e => {
        this.Areas = [];
        this.showError(e, 'Could not get Areas from EC');
      });
  }

  private addArea(area: Area) {
    let element: SelectItem;
    element = { label: area.Name, value: area };
    this.Areas.push(element);
    // Make stored value appear as selected in dropdown
    if (this.selectedArea != null && this.selectedArea.value!=null && this.Areas[this.Areas.length - 1].value.ID === this.selectedArea.value.ID) {
      this.selectedArea = this.Areas[this.Areas.length - 1].value;
      this.getSubAreas();
    }
  }

  // Get Sub Areas by the Area ID selected, and update ORD to selected Area
  getSubAreas() {
    this.saveInLocal("area", this.selectedArea);
    this.getFromLocal("subarea");
    this.selectedSubArea = this.storedData["subarea"];
    if (this.selectedArea == null) {
      // Empty out sub fields
      this.selectedSubArea = null;
      this.selectedFacility2 = null;
      this.selectedFacility1 = null;
      // this.selectedORD = null;

      // Disable fields
      this.disableSubArea = true;
      this.disableFacility2 = true;
      this.disableFacility1 = true;
      this.disableORD = true;
      return;
    }
    this.subAreas = [];
    if (this.selectedArea.value != null) {
      this.loadingSvc.show();
      this.omSvc.getSubAreas(this.selectedArea.value.ID)
        .pipe(
          finalize(() => {
            // Handles disabling input fields
            if (this.selectedArea === null) {
              this.disableSubArea = true;
              this.disableFacility2 = true;
              this.disableFacility1 = true;
              this.disableORD = true;
            } else {
              this.disableSubArea = false;
            }
            this.loadingSvc.hide();
          }))
        .subscribe((data: Subarea[]) => {
          data.forEach((sub) => {
            const element: SelectItem = { label: sub.Name, value: sub };
            this.subAreas.push(element);
            if (this.selectedSubArea != null && this.selectedSubArea.value!=null && this.subAreas[this.subAreas.length - 1].value.ID === this.selectedSubArea.value.ID) {
              this.selectedSubArea = this.subAreas[this.subAreas.length - 1].value;
              this.getFacilities2();
            }
          });
        });
    }
  }

  getFacilities2() {
    this.saveInLocal("subarea", this.selectedSubArea);
    this.getFromLocal("facility2");
    this.selectedFacility2 = this.storedData["facility2"];
    if (this.selectedSubArea == null) { // Handles on Change to a null value
      this.selectedFacility2 = null;
      this.selectedFacility1 = null;
      this.selectedORD = null;
      this.disableFacility2 = true;
      this.disableFacility1 = true;
      this.disableORD = true;
      return;
    }
    this.Facilities2 = [];
    if (this.selectedSubArea.value != null) {
      this.loadingSvc.show();
      this.omSvc.getFacilityClass2(this.selectedSubArea.value.ID)
        .pipe(
          finalize(() => {
            // Handles disabling inputs
            if (this.selectedSubArea === null) {
              this.disableFacility2 = true;
              this.disableFacility1 = true;
              this.disableORD = true;
            } else {
              this.disableFacility2 = false;
            }
            this.loadingSvc.hide();
          }))
        .subscribe((data: FacilityClassTwo[]) => {
          data.forEach((route) => {
            const element: SelectItem = { label: route.Name, value: route };
            this.Facilities2.push(element);
            if (this.selectedFacility2 != null && this.selectedFacility2.value!=null && this.Facilities2[this.Facilities2.length - 1].value.ObjectId === this.selectedFacility2.value.ObjectId) {
              this.selectedFacility2 = this.Facilities2[this.Facilities2.length - 1].value;
              this.getFacilities1();
            }
          });
        });
    }
  }

  getFacilities1() {
    this.saveInLocal("facility2", this.selectedFacility2);
    this.getFromLocal("facility1");
    this.selectedFacility1 = this.storedData["facility1"];
    if (this.selectedFacility2 == null) { // Handles on Change to a null value
      this.selectedFacility1 = null;
      this.selectedORD = null;
      this.disableFacility1 = true;
      this.disableORD = true;
      return;
    }
    this.Facilities1 = [];
    if (this.selectedFacility2.value != null) {
      this.loadingSvc.show();
      this.omSvc.getFacilityClass1(this.selectedFacility2.value.ObjectId)
        .pipe(
          finalize(() => {
            // Handles disabling inputs
            if (this.selectedFacility2 === null) {
              this.disableFacility1 = true;
              this.disableORD = true;
            } else {
              this.disableFacility1 = false;
            }
            this.loadingSvc.hide();
          }))
        .subscribe((data: FacilityClassOne[]) => {
          data.forEach((route) => {
            const element: SelectItem = { label: route.Name, value: route };
            this.Facilities1.push(element);
            if (this.selectedFacility1 != null && this.selectedFacility1.value!=null && this.Facilities1[this.Facilities1.length - 1].value.ObjectId === this.selectedFacility1.value.ObjectId) {
              this.selectedFacility1 = this.Facilities1[this.Facilities1.length - 1].value;
              this.getORDs();
            }
          });
        });
    }
  }

  // Gets the newest ORDs based on new route chosen
  getORDs() {
    this.saveInLocal("facility1", this.selectedFacility1);
    this.getFromLocal("ord");
    this.selectedORD = this.storedData["ord"];
    if (this.selectedFacility1 == null) {
      this.selectedORD = null;
      this.disableORD = true;
      return;
    }
    this.ords = [];
    if (this.selectedFacility1.value != null) {
      this.loadingSvc.show();
      this.ordSvc.getORDTemplateNamesByCollectionPointID([this.selectedFacility1.value.ObjectId], true)  // Return true because we want logically deleted entries as well
        .pipe(
          finalize(() => {
            // Handles disabling the inputs
            if (this.selectedFacility1 === null) {
              this.disableORD = true;
            } else {
              this.disableORD = false;
            }
            this.loadingSvc.hide();
          }))
        .subscribe((data: ORDTemplate[]) => {
          if (data && data.length < 1) {
            this.selectedORD = null;
          }
          data.forEach((ord) => {
            const element: SelectItem = { label: ord.Name, value: ord };
            // added validation to only show plant ORDs
            if (ord.IsFieldORD === true) {
              this.ords.push(element);
            }
            // console.log('this.ords ' + JSON.stringify(this.ords));
            if (this.ords && this.ords.length >= 1) {
              if (this.selectedORD != null && this.selectedORD.value!=null && this.ords && this.ords[this.ords.length - 1].value.ID === this.selectedORD.value.ID) {
                this.selectedORD = this.ords[this.ords.length - 1].value;
                this.getEquipments();
              }
            }
          });
          // console.log('this.ords '+JSON.stringify(this.ords));
          if (this.ords && this.ords.length < 1) {
            this.selectedORD = null;
          }
        });
        
    }
  }

  getEquipments() {
    this.saveInLocal('ord', this.selectedORD);
    if (this.selectedORD != null) {
      this.selectedEquipment = [];
      this.equips = [];
      this.loadingSvc.show();
      this.ordSvc.getEquipmentsByORDID(this.selectedORD.value.ID)  // Return true because we want logically deleted entries as well
        .pipe(
          finalize(() => {
            // Handles disabling the inputs
            if (this.selectedFacility1 === null) {
              this.disableORD = true;
            } else {
              this.disableORD = false;
            }
            this.loadingSvc.hide();
          }))
        .subscribe((data: any) => {
          console.log("Equipment :" + JSON.stringify(data))
          data.forEach((equip) => {
            const element: SelectItem = { label: equip.Description, value: equip.ID };


            this.equips.push(element);
            this.selectedEquipment.push(equip.ID);
            //  if (this.selectedEquipment != null && this.equips[this.equips.length - 1].value.ID === this.selectedEquipment.ID) {
            //    this.selectedEquipment = this.equips[this.equips.length - 1].value;
            //  }
          });
        });
    }
  }

  saveInLocal(key: string, val: any): void {
    this.storage.set(key, val);
    this.storedData[key] = this.storage.get(key);
  }

  getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  getReport() {
    // console.log('en getreport ' + this.selectedEquipment);
    if (this.selectedEquipment === null || this.selectedEquipment === undefined) {
      this.equipWarningMsg = true;
    } else {
      this.equipWarningMsg = false;
    }
    if (this.selectedORD.value.ID === null || this.selectedORD.value.ID === undefined || this.selectedORD.value === null || this.selectedORD.value === undefined) {
      this.ordWarningMsg = true;
    } else {
      if (this.equipWarningMsg === false) {
        this.loadingSvc.show();
        this.ordWarningMsg = false;
        this.showReportFlag = true;
        this.setReportName();

        // let fnDate = this.finishDate;
        // let stDate = this.startDate;

        if (this.finishDate === undefined && this.startDate !== undefined) {
          this.finishDate = new Date();
        }
        if (this.finishDate !== undefined && this.startDate === undefined) {
          this.startDate = new Date();
          this.startDate.setDate(this.startDate.getDate() - 30);
        }
        if (this.finishDate === undefined && this.startDate === undefined) {
          this.finishDate = new Date();
          this.startDate = new Date();
          this.startDate.setDate(this.startDate.getDate() - 30);
        }
        // console.log('this.startDate '+this.startDate + 'end ' + this.finishDate);
        if (this.selectedTabReport === 0) {
          this.ordSvc.getFieldOrdResponseWithDateFilter(this.startDate, this.finishDate, this.selectedEquipment)
            .pipe(
              finalize(() => {
                this.loadingSvc.hide();
              }))
            .subscribe((data: ORDResponse[]) => {
              // this.responses = data;
              this.responses = data.sort(function (obj1: any, obj2: any) { return obj2.CompletedDate - obj1.CompletedDate; });
              // console.log('this.responses '+ JSON.stringify(this.responses));
            });
        } else {
          if (this.selectedTabReport === 1) {
            // call new report API
            // let fnDate = this.finishDate;
            // let stDate = this.startDate;

            if (this.finishDate === undefined && this.startDate !== undefined) {
              this.finishDate = new Date();
            }
            if (this.finishDate !== undefined && this.startDate === undefined) {
              this.startDate = new Date();
              this.startDate.setDate(this.startDate.getDate() - 30);
            }
            if (this.finishDate === undefined && this.startDate === undefined) {
              this.finishDate = new Date();
              this.startDate = new Date();
              this.startDate.setDate(this.startDate.getDate() - 30);
            }
            this.ordSvc.getFieldOrdResponseExpectedWithDateFilter(this.startDate, this.finishDate, this.selectedEquipment)
              .pipe(
                finalize(() => {
                  this.loadingSvc.hide();
                }))
              .subscribe((data: ORDResponse[]) => {
                // this.responses = data;
                this.responsesIncomplete = data.sort(function (obj1: any, obj2: any) { return obj2.MissingResponses - obj1.MissingResponses; });
                // console.log('this.responses '+ JSON.stringify(this.responses));
              });
          } else {
            // call default report API
            this.ordSvc.getFieldOrdResponseWithDateFilter(this.startDate, this.finishDate, this.selectedEquipment)
              .pipe(
                finalize(() => {
                  this.loadingSvc.hide();
                }))
              .subscribe((data: ORDResponse[]) => {
                // this.responses = data;
                this.responses = data.sort(function (obj1: any, obj2: any) { return obj2.CompletedDate - obj1.CompletedDate; });
                // console.log('this.responses '+ JSON.stringify(this.responses));
              });

          }
        }
      }
    }
  }

  /**
   * The report Name variable has to be a copy of the selectedORD.Name variable.
   * When getting the report, it will display the report name as a header,
   * and it needs to not change if they go back and change the search filters
   */
  public setReportName() {
    if (this.selectedORD.value.ID === null || this.selectedORD.value.ID === undefined || this.selectedORD.value === null || this.selectedORD.value === undefined) {
      this.reportName = null;
    } else {
      this.reportName = this.selectedORD.value.Name;
    }
  }

  handleChange(e) {
    this.selectedTabReport = e.index;
    if (e.index === 1) {
      // call report automatically
      this.getReport();
    }
  }
}
