import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlanItem } from '#models/task-plan-item';
import { TaskPlanItemOption } from '#models/task-plan-item-option';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TaskPlanSubSection } from '#models/task-plan-sub-section';
import { HttpErrorResponse } from '@angular/common/http';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-task-plan-item-edit',
  templateUrl: './task-plan-item-edit.component.html',
  styleUrls: ['./task-plan-item-edit.component.scss']
})

/**
 * README: Originally users had the option to edit the item and item option types,
 * For Example, change a dropdown question to a numeric question.
 * A lot of this code was written to support that, but there was a backend bug that was causing some issues 
 * with duplicate item option creations or erroring in the middle of deletion.
 * 
 * Instead we went with the approach to remove the change functionality from users.  They can still
 * add/remove item options, but once a dropdown type is created, it cannnot be modified.
 * I am leaving the code for that in here since we may decide to re-add that functionality.
 * 
 * So there may be a lot of excess code here.
 */
export class TaskPlanItemEditComponent implements OnInit {
  taskPlanId: number;
  ordID: number;
  subSectionId: number;
  itemId: number;
  subSectionDesc: string;
  taskPlanItem: TaskPlanItem;

  //Input handling flags
  displayWarningDesc: boolean;
  displayOptionWarning: boolean;
  displayTextField: boolean;
  displayNumericWarning: boolean;
  displayNullValueWarning: boolean;

  //display options
  displayDropdown: boolean;
  displayCheckbox: boolean;
  displayNumeric: boolean;
  displayMultipleDropdown: boolean;
  displayStatus: boolean;

  LowRange: number; HighRange: number; Units: string;  //Numeric Input Values
  sectionId: number;
  selectedItemOptionType: string;
  itemOptions: TaskPlanItemOption[];
  previousOptionType: string;
  previousItemOptions: TaskPlanItemOption[];

  form = new FormGroup({
    name: new FormControl()
  });

  constructor(
    private taskPlanSvc: TaskPlanService,
    private confirmationSvc: ConfirmationService,
    private messageSvc: MessageService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    private loadingSvc: LoadingIndicatorService
  ) { }

  onConfirm() {
    this.messageSvc.clear('c');
  }

  onReject() {
    this.messageSvc.clear('c');
  }

  ngOnInit() {
    this.taskPlanItem = new TaskPlanItem();
    this.previousItemOptions = [];
    this.subSectionDesc = "";
    this.aRoute.params.subscribe(v => {
      this.taskPlanId = v['taskPlanId'];
      this.sectionId = v['sectionId'];
      this.subSectionId = v['subSectionId'];
      this.ordID = v['ordID'];
      this.itemId = v['itemId'];
    });

    this.getSubSectionId(this.subSectionId);
    this.displayWarningDesc = false;
    this.displayOptionWarning = false;
    this.displayNumericWarning = false;
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/TaskPlan-manage', { id: this.taskPlanId, sectionId: this.sectionId, ordID: this.ordID }]);
  }

  private getSubSectionId(subSectionId: number) {
    this.taskPlanSvc.getTaskPlanSubSectionById(subSectionId)
      .subscribe((data: TaskPlanSubSection[]) => {
        this.subSectionDesc = data[0].Description;
        this.getItemById(this.itemId);
      })
  }

  public getItemById(id: number): void {
    this.loadingIndicatorSvc.show();
    this.taskPlanSvc.getTaskPlanItemById(id)
      .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        this.taskPlanItem = data[0];
        this.getItemOptions(this.itemId);
      });
  }

  private getItemOptions(Itemid: number): void {
    this.taskPlanSvc.getItemOptionByItemId(Itemid)
      .subscribe((data: any) => {
        this.itemOptions = data;
        this.previousItemOptions = data;
        this.itemOptions.forEach((opt) => {
          opt.isNew = false;
        });
        this.selectedItemOptionType = data[0].Type;
        this.previousOptionType = data[0].Type;
        let checkForRepairOption = data.map(x => x.Description === "Repair/Adjust");
        this.taskPlanItem.IncludeRepairBox = checkForRepairOption.reduce((accumulator, curValue) => accumulator || curValue);
        this.initializeItemOptions(this.selectedItemOptionType);
      });
  }

  public updateTaskPlanItem(): void {
    if (this.selectedItemOptionType != this.previousOptionType) {
      this.confirmationSvc.confirm({
        header: 'Please confirm',
        message: `Are you sure that you want to change the Option Type?\n Doing so will delete all previously saved Option Entries.`,
        accept: () => {
          this.taskPlanItem.ItemOptionType = this.selectedItemOptionType;
          this.deleteAllPreviousItemOptions();
          this.completeUpdate();
        },
        reject: () => {
          return;
        }
      });
    }
    else {
      this.completeUpdate();
    }
  }

  private completeUpdate(): void {
    this.displayWarningDesc = this.taskPlanSvc.isInvalidString(this.taskPlanItem.Description);
    this.displayOptionWarning = this.checkOptions();
    this.displayNumericWarning = this.checkNumericValues();

    if (!this.displayWarningDesc && !this.displayOptionWarning && !this.displayNumericWarning) {
      if (this.taskPlanItem.ItemOptionType === "Statusbox") {
        this.loadingIndicatorSvc.show();
        this.taskPlanSvc.updateTaskPlanItemStatusbox(this.taskPlanItem)
          .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe(() => this.onRedirectButtonClick())
      }
      else if (this.ordID == 1) {
        this.loadingIndicatorSvc.show();
        this.taskPlanItem.TaskPlanItemOptions = null;
        this.taskPlanSvc.updateAndCloneTaskPlanItem(this.taskPlanItem)
          .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe((data: any) => {
            if (this.selectedItemOptionType === 'Numeric') {
              this.addItemOption(this.LowRange + ' ' + this.Units + ' - ' + this.HighRange + ' ' + this.Units);
            }
            let newOptions = [];
            let i = 1;
            // update on database
            if (this.selectedItemOptionType === 'Numeric') {
              this.loadingIndicatorSvc.show();
              this.taskPlanSvc.updateAndCloneItemOptions(this.itemOptions)
                .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
                .subscribe((data: any) => {
                },
                  exception => {
                    this.showError(exception, 'Failed to update item. ');
                    if (exception instanceof HttpErrorResponse) {
                      switch (exception.status) {
                        case 0:
                          this.loadingIndicatorSvc.hide();
                          this.messageSvc.clear();
                          this.messageSvc.add({
                            key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                            detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                          });
                          break;
                      }
                    }
                  });
            }
            this.itemOptions.forEach((element) => {
              element.sortID = i;
              i++;
              if (element.isNew) {
                newOptions.push(element);
              }
            });
            this.createItemOptions(newOptions);
            this.onRedirectButtonClick();
          },
            exception => {
              this.showError(exception, 'Failed to update item. ');
              if (exception instanceof HttpErrorResponse) {
                switch (exception.status) {
                  case 0:
                    this.loadingIndicatorSvc.hide();
                    this.messageSvc.clear();
                    this.messageSvc.add({
                      key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                      detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                    });
                    break;
                }
              }
            });
      } else {
        this.loadingIndicatorSvc.show();
        this.taskPlanItem.TaskPlanItemOptions = null;
        this.taskPlanSvc.updateTaskPlanItem(this.taskPlanItem)
          .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe((data: any) => {
            if (this.selectedItemOptionType === 'Numeric') {
              this.addItemOption(this.LowRange + ' ' + this.Units + ' - ' + this.HighRange + ' ' + this.Units);
            }
            let newOptions = [];
            let i = 1;
            // update on database
            if (this.selectedItemOptionType === 'Numeric') {
              this.loadingIndicatorSvc.show();
              this.taskPlanSvc.updateItemOptions(this.itemOptions)
                .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
                .subscribe((data: any) => {
                },
                  e => this.showError(e, 'Failed to update options . '));
            }
            this.itemOptions.forEach((element) => {
              element.sortID = i;
              i++;
              if (element.isNew) {
                newOptions.push(element);
              }
            });
            this.createItemOptions(newOptions);
            this.onRedirectButtonClick();
          },
            e => this.showError(e, 'Failed to update item. '));
      }
    }
  }

  //This method is only called from ngOnInit, does not empty out item Options
  private initializeItemOptions(optionType: string): void {
    if (optionType === "Dropdown") {
      this.displayDropdown = true;
      this.displayCheckbox = false;
      this.displayNumeric = false;
      this.displayTextField = false;
      this.displayMultipleDropdown = false;
      this.displayStatus = false;
    } else if (optionType === "Checkbox") {
      this.displayCheckbox = true;
      this.displayDropdown = false;
      this.displayNumeric = false;
      this.displayTextField = false;
      this.displayMultipleDropdown = false;
      this.displayStatus = false;
    } else if (optionType === "Numeric") {
      this.displayNumeric = true;
      this.displayDropdown = false;
      this.displayCheckbox = false;
      this.displayTextField = false;
      this.displayMultipleDropdown = false;
      this.displayStatus = false;
      this.LowRange = this.itemOptions[0].MinValue;
      this.HighRange = this.itemOptions[0].MaxValue;
      this.Units = this.itemOptions[0].Units;
    } else if (optionType === "Textfield") {
      this.displayTextField = true;
      this.displayDropdown = false;
      this.displayCheckbox = false;
      this.displayNumeric = false;
      this.displayMultipleDropdown = false;
      this.displayStatus = false;
    } else if (optionType === "Multiple-Dropdown") {
      this.displayMultipleDropdown = true;
      this.displayDropdown = false;
      this.displayCheckbox = false;
      this.displayNumeric = false;
      this.displayTextField = false;
      this.displayStatus = false;
    }
    else if (optionType === "Statusbox") {
      this.displayStatus = true;
      this.displayMultipleDropdown = false;
      this.displayDropdown = false;
      this.displayCheckbox = false;
      this.displayNumeric = false;
      this.displayTextField = false;
    }
    this.setFirstLastFlags();
  }

  //This method is called from a user switching radio buttons
  showItemOptions(optionType: string): void {
    //Set Options to options from DB or empty
    if (optionType === this.previousOptionType) {
      this.itemOptions = this.previousItemOptions;
    }
    else {
      this.itemOptions = [];
    }

    //Set Display
    this.displayCheckbox = false;
    this.displayNumeric = false;
    this.displayTextField = false;
    this.displayDropdown = false;
    this.displayMultipleDropdown = false;
    this.displayStatus = false

    if (optionType === 'Dropdown') {
      this.displayDropdown = true;
      this.taskPlanItem.ItemOptionType = 'Dropdown';
    }
    else if (optionType === 'Checkbox') {
      this.displayCheckbox = true;
      this.taskPlanItem.ItemOptionType = 'Checkbox';
    }
    else if (optionType === 'Numeric') {
      this.displayNumeric = true;
      this.taskPlanItem.ItemOptionType = 'Numeric';
    }
    else if (optionType === 'Textfield') {
      this.displayTextField = true;
      this.taskPlanItem.ItemOptionType = 'Textfield';
    } else if (optionType === "Multiple-Dropdown") {
      this.displayMultipleDropdown = true;
      this.taskPlanItem.ItemOptionType = "Multiple-Dropdown";
    } else if (optionType === "Statusbox") {
      this.displayStatus = true;
      this.taskPlanItem.ItemOptionType = "Statusbox";
    }
  }

  //Adds Option to local list
  addItemOption(description: string): void {
    let option = new TaskPlanItemOption();
    if (description === null || description === undefined || description.trim() === "") {
      this.displayNullValueWarning = true;
      return;
    }
    this.displayNullValueWarning = false;
    option.Description = description;
    option.Type = this.selectedItemOptionType;
    option.isNew = true;
    option.TaskPlanItemID = this.taskPlanItem.ID;

    //Set numeric only fields
    if (this.selectedItemOptionType === 'Numeric') {
      //If there was a previous numeric entry, treat it as an update instead of creation
      if (this.itemOptions.length > 0 && this.itemOptions[0].Type === 'Numeric') {
        option.isNew = false;
        option.ID = this.itemOptions[0].ID;
      }
      this.itemOptions = []; //We do not want more than one range
      option.MaxValue = this.HighRange;
      option.MinValue = this.LowRange;
      option.Units = this.Units;


    }
    else if (this.selectedItemOptionType === 'Textfield') {
      this.itemOptions = []; //We only want one title for Text Fields
    }

    this.itemOptions.push(option);
    this.setFirstLastFlags();
    this.form.reset();
  }

  moveUpItemOption(desc: string): void {
    this.loadingIndicatorSvc.show();
    this.swapItem(desc, -1);
    this.setFirstLastFlags();
    this.loadingIndicatorSvc.hide();
  }

  moveDownItemOption(desc: string): void {
    this.loadingIndicatorSvc.show();
    this.swapItem(desc, 1);
    this.setFirstLastFlags();
    this.loadingIndicatorSvc.hide();
  }

  // MoveUp (possition = -1) && MoveDown (set possition = 1)
  private swapItem(desc: string, possition: number): void {
    let optionIndex = this.itemOptions.findIndex(x => x.Description === desc);
    let temp = this.itemOptions[optionIndex + possition];
    this.itemOptions[optionIndex + possition] = this.itemOptions[optionIndex];
    this.itemOptions[optionIndex] = temp;
  }

  deleteAllPreviousItemOptions(): void {
    if (this.ordID == 1) {
      this.previousItemOptions.forEach((element) => {
        this.taskPlanSvc.deleteAndCloneItemOption(element.ID)
          .subscribe(() => {
          }, exception => {
            this.showError(exception, "Failed to delete Item Options");
            if (exception instanceof HttpErrorResponse) {
              switch (exception.status) {
                case 0:
                  this.loadingIndicatorSvc.hide();
                  this.messageSvc.clear();
                  this.messageSvc.add({
                    key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                    detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                  });
                  break;
              }
            }
          });
      });
    } else {
      this.previousItemOptions.forEach((element) => {
        this.taskPlanSvc.deleteItemOption(element.ID)
          .subscribe(() => { },
            e => this.showError(e, "Failed to delete Item Options"));
      });
    }
  }

  deleteItemOption(description: string): void {
    let element = this.itemOptions.find((element: TaskPlanItemOption) => {
      return element.Description === description;
    });
    if (!element.isNew) {

      if (this.ordID == 1) {
        this.confirmationSvc.confirm({
          header: 'Please Confirm',
          message: 'Are you sure you want to delete this option?',
          accept: () => {
            let element = this.itemOptions.find((element: TaskPlanItemOption) => {
              return element.Description === description;
            });

            this.loadingSvc.show();
            this.taskPlanSvc.deleteAndCloneItemOption(element.ID)
              .subscribe((data: any) => {
                this.loadingSvc.hide();
                if (data.Success) {
                  this.messageSvc.add({
                    severity: 'success',
                    summary: 'Option Deleted',
                    detail: 'Option \"' + description + "\" has been successfully deleted."
                  });
                  this.itemOptions = this.itemOptions.filter(obj => obj !== element);
                  this.setFirstLastFlags();
                } else {
                  this.messageSvc.add({
                    severity: 'error',
                    summary: 'Deletion Failed',
                    detail: 'Unable to delete option'
                  });
                }
              }, exception => {
                if (exception instanceof HttpErrorResponse) {
                  switch (exception.status) {
                    case 0:
                      this.loadingIndicatorSvc.hide();
                      this.messageSvc.clear();
                      this.messageSvc.add({
                        key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                        detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                      });
                      break;
                  }
                }
              });
          }
        });
      } else {
        this.confirmationSvc.confirm({
          header: 'Please Confirm',
          message: 'Are you sure you want to delete this option?',
          accept: () => {
            let element = this.itemOptions.find((element: TaskPlanItemOption) => {
              return element.Description === description;
            });
            this.loadingSvc.show();
            this.taskPlanSvc.deleteItemOption(element.ID)
              .subscribe((data: any) => {
                this.loadingSvc.hide();
                if (data.Success) {
                  this.messageSvc.add({
                    severity: 'success',
                    summary: 'Option Deleted',
                    detail: 'Option \"' + description + "\" has been successfully deleted."
                  });
                  this.itemOptions = this.itemOptions.filter(obj => obj !== element);
                  this.setFirstLastFlags();
                } else {
                  this.messageSvc.add({
                    severity: 'error',
                    summary: 'Deletion Failed',
                    detail: 'Unable to delete option'
                  });
                }
              });
          }
        });
      }
    } else {
      this.itemOptions = this.itemOptions.filter(obj => obj !== element);
      this.setFirstLastFlags();
    }
  }

  // Called after item options are moved, created, or deleted
  private setFirstLastFlags(): void {
    if (this.itemOptions.length == 1) {
      this.itemOptions[0].isFirstOption = true;
      this.itemOptions[0].isLastOption = true;
    } else if (this.itemOptions.length > 1) {
      this.itemOptions.forEach((opt) => {
        opt.isFirstOption = false;
        opt.isLastOption = false;
      });
      this.itemOptions[0].isFirstOption = true;
      this.itemOptions[this.itemOptions.length - 1].isLastOption = true;
    }
  }
  checkOptions(): boolean {
    if (this.itemOptions.length === 0 && this.selectedItemOptionType != "Numeric") {
      return true;
    } else {
      return false;
    }
  }

  checkNumericValues(): boolean {
    if ((this.HighRange == null || this.LowRange == null || this.Units == null) && this.selectedItemOptionType === "Numeric") {
      return true;
    } else {
      if (this.itemOptions.length == 0 && this.selectedItemOptionType === "Numeric") {
        this.addItemOption(this.LowRange + ' ' + this.Units + ' - ' + this.HighRange + ' ' + this.Units);
      }
      return false;
    }
  }

  createItemOptions(options: TaskPlanItemOption[]): boolean {
    if (options.length == 0) {
      return true;
    }

    if (this.ordID == 1) {
      this.loadingIndicatorSvc.show();
      this.taskPlanSvc.createAndCloneItemOptions(options)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (!data.Success) {
            return false;
          }
        }, exception => {
          this.showError(exception, "Unable to create the Item Options");
          if (exception instanceof HttpErrorResponse) {
            switch (exception.status) {
              case 0:
                this.loadingIndicatorSvc.hide();
                this.messageSvc.clear();
                this.messageSvc.add({
                  key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                  detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                });
                break;
            }
          }
        });
    } else {
      this.loadingIndicatorSvc.show();
      this.taskPlanSvc.createItemOptions(options)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          if (!data.Success) {
            return false;
          }
        },
          e => this.showError(e, "Unable to create the Item Options"));
    }

    return true;
  }

  //Supposed to move focus on Enter key from Low field to High field, but not currently working
  @ViewChild("High") private High: ElementRef;
  focusHighInput(): void {
    this.High.nativeElement.focus();
  }
  //TODO: Write Create Item Options Function that calls API to create in our DB (API for this has not yet been created)


}


