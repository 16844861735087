import { NgModule } from '@angular/core';
import { BusinessUnitsService, FeedbackService } from './';
import { TaskPlanService } from '#services/api/task-plan.service';
import { SharedModule } from '#services/shared/shared.module';
import { UserService } from '#services/api/user.service';
import { OMWorkbenchService } from './omWorkbench.service';
import { ORDService } from './ord.service';
import { EquipmentService } from './equipment.service';

@NgModule({
  declarations: [
  ],
  imports: [
    SharedModule
  ],
  providers: [
    BusinessUnitsService,
    TaskPlanService,
    OMWorkbenchService,
    ORDService,
    FeedbackService,
    UserService,
    EquipmentService
  ]
})

export class ApiModule { }
