<div *ngIf="canBeDeleted" class="box-shadow module" style="width:80%; margin:auto; background-color: midnightblue; color: white">
    <h2>Attention!</h2>
    <hr>
    <h3>Are you sure you want to delete ORD below ?</h3>
    <div>
        <div style="width: 30%; margin:auto">
            <div class="row" style="padding-top:10px">
                <div class="col-xs-6" style="text-align:right">
                   Name:
                </div>
                <div class="col-xs-6">
                    {{ord.Name}}
                </div>
            </div>
            <div class="row" style="padding-bottom:10px">
                <div class="col-xs-6" style="text-align:right">
                    Description:
                </div>
                <div class="col-xs-6">
                    {{ord.Description}}
                </div>
            </div>
        </div>
        <h4>All the related section(s) | subsection(s) | item(s) will be removed.</h4>
        <div class="form-actions no-color">
            <div class="centerButtonContainer">
                <a class="btn btn-danger"style="cursor: pointer;" (click)="deleteord()" >Delete</a>
                &nbsp;
                &nbsp;
                <a class="btn btn-primary" style="cursor: pointer;" (click)="onRedirectButtonClick()">Back</a>
            </div>
        </div>    
    </div>
</div>

<div *ngIf="!canBeDeleted"  class="box-shadow module" style="width:80%; margin:auto; background-color: midnightblue; color: white">
    <h3>ORD [{{ord.Name}}] can not be removed</h3> 
    <h4>There are existing items using it !</h4>
    <hr>
    <div class="centerButtonContainer">
        <a class="btn btn-primary" style="width: 100px;" href="/admin/task-plan">Back</a>
    </div>
</div>