<div class="box-shadow module">    
    <h2>Create Equipment Task Plan</h2>
    <hr>    
    <div class="form-horizontal">
        <label style="margin-left:17%; margin-top:1%; color:orangered; font:small-caption" *ngIf="displayWarningNote">
        * This Code has already been taken
        </label>
        <br>  
        <label style="margin-left:17%; color:dimgray; font:small-caption">
            [Note] Code name need to be unique, and it cannot be renamed after Equipment Task Plan is created.
        </label>    
        <br>
        <div class="form-group">
            <label class="control-label col-md-2">Code</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Code">
                <span class="text-danger field-validation-error" *ngIf="displayWarningCode">
                    <span>The Code field is required.</span>
                </span>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Description</label>
            <div class="col-md-10">                
                <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Description">
                <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
                    <span>The Description field is required.</span>
                </span>
            </div>
        </div>
        <div class="form-group" *ngIf="selectedBU.LinkToEquipment =='EAM'">
            <label class="control-label col-md-2">Equipment</label>
            <div class="col-md-10">  
                <p-dropdown [options]="groupedEAMEquipmentCopy" [(ngModel)]="selectedEAMEquipment" [style]="{'width':'100%'}" placeholder="Select Equipment" [group]="true"
                [filter]="true" filterBy="label"  optionLabel="label" >
                    <ng-template let-group pTemplate="group">
                        <span style="margin-left:.25em">{{group.label}}</span>
                    </ng-template>
                </p-dropdown>              
            </div>
        </div>
        <div class="form-group" *ngIf="selectedBU.LinkToEquipment == 'EC'">
            <label class="control-label col-md-2">Equipment</label>
            <div class="col-md-10">
                <p-dropdown [options]="groupedEquipmentCopy" [(ngModel)]="ecEquipment" [style]="{'width':'100%'}" placeholder="Select Equipment" [group]="true"
               [filter]="true" filterBy="label"  optionLabel="label" >
                    <ng-template let-group pTemplate="group">
                        <span style="margin-left:.25em">{{group.label}}</span>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Generic Equipment Type</label>
            <div class="col-md-10">                
                <p-dropdown [options]="GenericEquipments" [(ngModel)]="taskPlan.GenericParentID" placeholder="Select a Generic Equipment Type" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-primary" style="width: 100px;" (click)="createTaskPlan()">Create</a>
            </div>
        </div>
    </div>
    <div>
        <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Task Plans</a> 
    </div>    
</div>