<div class="task-report-module">
    <div class="box-shadow module">
        <div class="row">
            <div class="col-md-2">
                <p-listbox [options]="TaskPlanMenuItems" [(ngModel)] ="currentTaskPlan" (onChange)="filterResponses(onlyShowNotOK, currentTaskPlan)" >
                </p-listbox>
            </div>
            <div class="col-md-10">
                <p-dataView #dv [value]="taskplans" *ngIf="taskplans && taskplans.length > 0" [sortField]="sortField" [sortOrder]="sortOrder"
                        filterBy="Description">
                        <p-header>
                            <div class="row">
                                <div class="col-sm-6">
                                    <b>All Responses for {{currentORD.Name}} on {{ordResponse.CompletedDate}}</b>
                                </div>
                                <div class="col-sm-6">
                                    <p-checkbox binary = "true" name="Not_OK_chkbx" label="Only show responses that are 'Not OK'?"
                                     (onChange)="filterResponses(onlyShowNotOK, currentTaskPlan)" [(ngModel)]="onlyShowNotOK"></p-checkbox>
                                     <br>
                                    <p-checkbox binary = "true" name="Blank_chkbx" label="Only show responses that are blank?"
                                     (onChange)="filterBlankResponses(onlyShowBlank, currentTaskPlan)" [(ngModel)]="onlyShowBlank"></p-checkbox>
                                </div>
                            </div>
                        </p-header>

                        <ng-template let-TaskPlan pTemplate="listItem">
                            <div *ngIf="!TaskPlan.hide" class="row">
                                <div class="p-g taskPlanRow">
                                    <div class="taskPlan">
                                        <b>{{TaskPlan.Description}}</b>
                                    </div>
                                </div>
                            </div>
                            <div *ngFor="let section of TaskPlan.TaskPlanSections">
                                <div *ngIf="!section.hide" class="p-g sectionRow">
                                    <div class="section">
                                        <b>{{section.Description}}</b>
                                    </div>
                                </div>
                                <div *ngFor="let subSec of section.TaskPlanSubSections">
                                    <div *ngIf="!subSec.hide" class="p-g subSecRow">
                                        <div class="p-g-12 p-md-3 subSec">
                                            <div class="p-grid-col-12">{{subSec.Description}}</div>
                                        </div>
                                        <div class="p-g-12 p-md-8 tresponse verticalBorder">
                                            <div class="p-g withBorder" *ngFor="let response of subSec.responses">
                                                <div *ngIf="response.TaskPlanItem['ItemOptionType'] != 'Numeric'; else showRanges" class="p-g-4 p-sm-6">{{response.ItemDescription}}</div>
                                                <ng-template #showRanges>
                                                    <div class="p-g-4 p-sm-6">{{response.ItemDescription}} 
                                                    <span *ngIf="response.TaskPlanItem['TaskPlanItemOptions'][0] !== undefined">
                                                    ({{response.TaskPlanItem["TaskPlanItemOptions"][0].MinValue}} - {{response.TaskPlanItem["TaskPlanItemOptions"][0].MaxValue}} {{response.TaskPlanItem["TaskPlanItemOptions"][0].Units}}): </span>
                                                    </div>
                                                </ng-template>
                                                <!-- <div class="p-g-2 p-sm-6">{{response.ItemDescription}}: </div> -->
                                                <div *ngIf="!subSec.isOk; else showGreen" class="p-g-6 p-sm-6 notOkResponse">
                                                    <b>{{response.Response}}</b>
                                                </div>
                                                <ng-template #showGreen><div class="p-g-6 p-sm-6 OkResponse">
                                                    <b>{{response.Response}}</b>
                                                </div></ng-template>
                                            </div>                               
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                </p-dataView>
            </div>
        </div>
    </div>
</div>
