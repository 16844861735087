import { Component, OnInit,Input, Output, Injectable, ViewChildren, QueryList, EventEmitter } from '@angular/core';
import { ORDResponse } from 'models/ordResponse';
import { Router } from '@angular/router';
@Component({
  selector: 'app-field-ord-grid',
  templateUrl: './field-ord-grid.component.html',
  styleUrls: ['./field-ord-grid.component.scss']
})
export class FieldOrdGridComponent implements OnInit {

  sortKey: string;
  sortField: string;
  sortOrder: number;

  selectedord: ORDResponse = new ORDResponse();

  private _responses: ORDResponse[];
  @Input()
  set responses(responses: ORDResponse[]) {
    this._responses = responses || [];

    if (this.dataView && this.dataView.first) {
      this.dataView.first.first = 0;
    }
  }
  get responses(): ORDResponse[] { return this._responses; }


  @ViewChildren('dv') dataView: QueryList<any>;

  ngOnInit() {
    this.sortOrder = -1;
    this.sortField = 'StartedDate'; // 'ordId';
  }

  constructor(
    private _router: Router
  ) {
  }

  onSortChange(event) {
    this.sortOrder = this.sortField === event ? this.sortOrder * -1 : 1;
    this.sortField = event;
  }

  onRedirectButtonClick(responseID: number, ordTemplateID: number, sectionID: number) {
    if (responseID !== undefined && responseID != null && ordTemplateID !== undefined && ordTemplateID != null && sectionID !== undefined && sectionID != null) {
      this._router.navigate(['fieldTaskReport', {id: responseID, ordId: ordTemplateID, sectionID: sectionID}]);
    }
  }
}
