import { NgModule, Component } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { BusinessUnitsComponent } from '#pages/admin/business-units/business-units.component';
import { ORDTemplateCreateComponent } from '#pages/ord/ord-template-create/ord-template-create.component';
import { ORDTemplateCreateOpComponent } from '#pages/ord/ord-template-create-op/ord-template-create-op.component';
import { ORDTemplateDeleteComponent } from '#pages/ord/ord-template-delete/ord-template-delete.component';
import { ORDTemplateEditComponent } from '#pages/ord/ord-template-edit/ord-template-edit.component';
import { OPORDTemplateEditComponent } from '#pages/ord/ord-template-edit-op/op-ord-template-edit.component';
import { TaskPlanComponent } from '#pages/ord/task-plan/task-plan.component';
import { TaskPlanCreateComponent } from '#pages/ord/task-plan-create/task-plan-create.component';
import { TaskPlanEditComponent } from '#pages/ord/task-plan-edit/task-plan-edit.component';
import { TaskPlanManageComponent } from '#pages/ord/task-plan-manage/task-plan-manage.component';
import { TaskPlanDeleteComponent } from '#pages/ord/task-plan-delete/task-plan-delete.component';
import { TaskPlanCloneComponent } from '#pages/ord/task-plan-clone/task-plan-clone.component';
import { TaskPlanSectionEditComponent } from '#pages/ord/task-plan-section-edit/task-plan-section-edit.component';
import { TaskPlanSectionDeleteComponent } from '#pages/ord/task-plan-section-delete/task-plan-section-delete.component';

import { TaskPlanSubSectionEditComponent } from '#pages/ord/task-plan-sub-section-edit/task-plan-sub-section-edit.component';
import { TaskPlanSubSectionDeleteComponent } from '#pages/ord/task-plan-sub-section-delete/task-plan-sub-section-delete.component';

import { TaskPlanItemCreateComponent } from '#pages/ord/task-plan-item-create/task-plan-item-create.component';
import { TaskPlanItemEditComponent } from '#pages/ord/task-plan-item-edit/task-plan-item-edit.component';

import { UsersComponent } from '#pages/admin/users/users.component';

import { PagesModule } from '#pages/pages.module';
import { AdminPagesModule } from '#pages/admin/admin.pages.module';
import { NotFoundComponent } from '#pages/error/not-found/not-found.component';
import { ErrorPagesModule } from '#pages/error/error.pages.module';
import { HomeComponent } from '#pages/home/home.component';
import { GenericErrorComponent } from '#pages/error/generic-error/generic-error.component';
import { NotAuthorizedComponent } from '#pages/error/not-authorized/not-authorized.component';
import { IsSystemAdmin, IsApplicationUser } from './app.routing.guards';
import { LoginComponent } from '#pages/login/login.component';
import { TaskReportComponent } from '#pages/taskReports/task-report.component';
import { FieldTaskReportComponent } from '#pages/field-task-report/field-task-report.component';
import { GenericComponent } from '#pages/ord/generic/generic.component';
import { GenericCreateComponent } from '#pages/ord/generic-create/generic-create.component';
import { GenericEditComponent } from '#pages/ord/generic-edit/generic-edit.component';
import { GenericManageComponent } from '#pages/ord/generic-manage/generic-manage.component';
import { GenericDeleteComponent } from '#pages/ord/generic-delete/generic-delete.component';
import { FieldComponent } from '#pages/ord/field/field.component';
import { FieldReportsComponent } from '#pages/field-reports/field-reports.component';
import { OpFieldReportComponent } from '#pages/operational/op-field-report/op-field-report.component';

const routes: Route[] = [
  // Home page and slash-appender
  {
    path: '',
    canActivate: [IsApplicationUser],
    component: HomeComponent
  },

  // Report Pages
  {
    path: 'taskReport', component: TaskReportComponent
  },
  // Report Field ORD Pages
  {
    path: 'fieldTaskReport', component: FieldTaskReportComponent
  },
  // Field ORD Report
  {
    path: 'field-reports', component: FieldReportsComponent
  },
  // Field ORD Report Operational
  {
    path: 'op-field-report', component: OpFieldReportComponent
  },
  // Ord pages
  {
    path: 'ord',
    children: [
      { path: 'create', component: ORDTemplateCreateComponent },
      { path: 'createOp', component: ORDTemplateCreateOpComponent },
      { path: 'delete', component: ORDTemplateDeleteComponent },
      { path: 'edit', component: ORDTemplateEditComponent },
      { path: 'editOp', component: OPORDTemplateEditComponent },
      { path: 'TaskPlan', component: TaskPlanComponent },
      { path: 'TaskPlan-create', component: TaskPlanCreateComponent },
      { path: 'TaskPlan-edit', component: TaskPlanEditComponent },
      { path: 'TaskPlan-clone', component: TaskPlanCloneComponent },
      { path: 'TaskPlan-manage', component: TaskPlanManageComponent },
      { path: 'TaskPlan-delete', component: TaskPlanDeleteComponent },
      { path: 'TaskPlan-section-edit', component: TaskPlanSectionEditComponent },
      { path: 'TaskPlan-section-delete', component: TaskPlanSectionDeleteComponent },
      { path: 'TaskPlan-sub-section-edit', component: TaskPlanSubSectionEditComponent },
      { path: 'TaskPlan-sub-section-delete', component: TaskPlanSubSectionDeleteComponent },
      { path: 'TaskPlan-item-create', component: TaskPlanItemCreateComponent },
      { path: 'TaskPlan-item-edit', component: TaskPlanItemEditComponent },
      { path: 'generic', component: GenericComponent },
      { path: 'generic-create', component: GenericCreateComponent },
      { path: 'generic-edit', component: GenericEditComponent },
      { path: 'generic-manage', component: GenericManageComponent },
      { path: 'generic-delete', component: GenericDeleteComponent },
      { path: 'field', component: FieldComponent }
    ]
  },

  // Admin pages
  {
    path: 'admin',
    canActivate: [IsSystemAdmin],
    children: [
      // { path: 'business-units', component: BusinessUnitsComponent },
      { path: 'users', component: UsersComponent }
    ]
  },

  // "Awaiting login" page
  {
    path: 'login',
    component: LoginComponent
  },

  // Error pages
  {
    path: 'error',
    children: [
      { path: 'not-found', component: NotFoundComponent },
      { path: 'internal-error', component: GenericErrorComponent },
      { path: 'not-authorized', component: NotAuthorizedComponent }
    ]
  },

  // Displays 404 "Not Found" error page for all unrecognized router.
  // Instead of redirecting, renders context to preserve original "incorrect" url
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    PagesModule,
    ErrorPagesModule,
    AdminPagesModule
  ],
  exports: [
    RouterModule
  ],
  providers: [
    IsSystemAdmin,
    IsApplicationUser
  ]
})
export class AppRoutingModule { }
