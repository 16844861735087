import { Component, OnInit, AfterViewChecked, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '#services/shared';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private authSvc: AuthService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private router: Router) { }

  ngOnInit() {
    if (this.authSvc.isAuthorized()) {
      this.router.navigate(['']);
    } else {
      setTimeout(() => {
        this.authSvc.login();
      }, 1000)
      setTimeout(() => {
        this.router.navigate(['']);
      }, 2000)
    }
  }

}
