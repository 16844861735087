<nav class="navbar navbar-default navbar-default-subsite centered" aria-label="Site menu" role="navigation">
    <!-- navbar-fixed-top -->
    <!-- Brand and toggle get grouped for better mobile display -->
    <div class="d-block d-sm-none">
        <div class="navbar-header centered d-block d-sm-none">
            <div class="hamburger-mobile pull-left d-sm-none d-md-block d-lg-block d-xl-block dropdown dropdown-mobile">
                <a href="#" rel="noopener" class="dropdown-toggle pull-left" data-bs-toggle="dropdown"
                    data-target="#navbar-brand-centered-subsite" aria-expanded="false">
                    <span class="visually-hidden">
                        Toggle navigation
                    </span>
                    <span class="glyphicon glyphicon-menu-hamburger black" aria-hidden="true"></span>
                </a>
            </div>
        </div>
        <div class="navbar-brand navbar-brand-centered d-block d-sm-none">
            <div class="hallmark-container">
                <a class="hallmark" href="/" title="Chevron"><img alt="Chevron" src="/assets/images/hallmark.png"
                        width="36" height="40"></a>
            </div>
            <div class="sitename-container vertical-align">
                <a class="sitename light-blue font-gotham" href="/">inside chevron</a>
            </div>
        </div>
        <div
            class="search search-mobile pull-right d-sm-none d-md-block d-lg-block d-xl-block dropdown dropdown-mobile">
            <a href="https://onesearch.sp.chevron.net/" class="dropdown-toggle pull-right">
                <span class="glyphicon glyphicon-search black" aria-hidden="true"></span>
                <span class="visually-hidden">toggle search</span>
            </a>
        </div>
    </div>
    <!-- desktop -->
    <div class="collapse navbar-collapse js-navbar-collapse centered row d-none d-sm-block font-gotham"
        id="navbar-brand-centered">
        <ul class="nav navbar-nav navbar-left" *ngIf="this.authSvc.isSystemAdmin()">
            <li class="dropdown dropdown-large hamburger pull-left">
                <button type="button" class="navbar-toggle dropdown-toggle" data-bs-toggle="dropdown"
                    data-target="#navbar-hamburger-menu" aria-expanded="false">
                    <span class="visually-hidden">Toggle navigation</span>
                    <span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
                    <a [routerLink]="['/admin/users']" style="font-size: 12px;">Manage Users</a>
                </button>
            </li>
        </ul>
        <div class="navbar-brand navbar-brand-centered">
            <div class="hallmark-container">
                <a class="hallmark" href="/" title="Chevron"><img alt="Chevron" src="/assets/images/hallmark.png"
                        width="42" height="46"></a>
            </div>
            <div class="sitename-container vertical-align">
                <a class="sitename light-blue font-gotham" href="/">Mobile ORD</a>
            </div>
        </div>
        <ul class="nav navbar-nav navbar-right pull-right black">
            <li class="dropdown dropdown-large search pull-right">
                <a href="https://onesearch.sp.chevron.net/" class="onesearch-button" tabindex="0" target="_blank">
                    <span class="glyphicon glyphicon-search black d-none d-sm-block" aria-hidden="true"></span>
                    <span class="visually-hidden d-none d-sm-block d-md-none">one search</span>
                    <span class="icon-text d-sm-none d-md-block">one search</span>
                </a>
            </li>
        </ul>
    </div>
</nav>

<nav class="navbar navbar-subsite centered" aria-label="Site menu" role="navigation">
    <!--navbar-fixed-top-->
    <div class="container-fluid">

        <div class="collapse navbar-collapse js-navbar-collapse centered row font-gotham   navbar-mobile dropdown-menu *dropdown-menu-mobile"
            id="navbar-brand-centered-subsite" style="display: block;">
            <div class="mobile-nav-container vertical-align flex-center centered">
                <!--width-1280-->
                <!-- <p-dropdown [options]="BusinessUnits" [(ngModel)]="selectedBU" (onChange)="setBUName()" optionLabel="label"
                style="margin-left:-41%;"  [ngClass]="!isAdminForAllBUs ? 'disabledClass' : ''" ></p-dropdown> -->
                <p-dropdown [options]="BusinessUnits" [(ngModel)]="selectedBU" optionLabel="label"
                    (onChange)="setBUName()" style="margin-left:-13%;"
                    [ngClass]="!isAdminForAllBUs ? 'disabledClass' : ''"></p-dropdown>
                <ul class="nav navbar-nav primary" style="display: contents;
                ">

                    <li (click)="setSelectedMenuIndex(1)" style="display: initial; margin-left: 33%;"
                        class=" dropdown level-1 menu-selected clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a (click)="goToReportPage()">Reports</a>
                    </li>
                    <!-- <li *ngIf="selectedMenuIndex != 1 && isGeographical" (click)="setSelectedMenuIndex(1)" style="display: initial;
                    margin-left: 33%;"
                        class=" dropdown level-1 menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a [routerLink]="['/field-reports']">Reports</a>
                    </li>
                    <li *ngIf="selectedMenuIndex === 1 && isOperational" (click)="setSelectedMenuIndex(1)" style="display: initial;
                    margin-left: 33%;"
                        class=" dropdown level-1  menu-selected clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a [routerLink]="['/op-field-report']">Reports</a>
                    </li>
                    <li *ngIf="selectedMenuIndex != 1 && isOperational" (click)="setSelectedMenuIndex(1)" style="display: initial;
                    margin-left: 33%;"
                        class=" dropdown level-1  menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a [routerLink]="['/op-field-report']">Reports</a>
                    </li> -->
                    <li *ngIf="selectedMenuIndex === 3" (click)="setSelectedMenuIndex(3)" style="display:flex;"
                        class=" dropdown level-1 menu-selected clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a [routerLink]="['/ord/field']">Templates</a>
                    </li>
                    <li *ngIf="selectedMenuIndex != 3" (click)="setSelectedMenuIndex(3)"
                        class=" dropdown level-1menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a [routerLink]="['/ord/field']">Templates</a>
                    </li>
                    <li *ngIf="selectedMenuIndex === 4 && this.authSvc.isSystemAdmin()" style="width:30%"
                        (click)="setSelectedMenuIndex(4)"
                        class=" dropdown level-1 menu-selected clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a [routerLink]="['/ord/generic']">Generic Equipment Types</a>
                    </li>
                    <li *ngIf="selectedMenuIndex != 4 && this.authSvc.isSystemAdmin()" (click)="setSelectedMenuIndex(4)"
                        style="width:30%"
                        class=" dropdown level-1menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
                        <a [routerLink]="['/ord/generic']">Generic Equipment Types</a>
                    </li>
                </ul>
                <div role="dialog" tabindex="-1" aria-label="Utility Menu" id="navbar-hamburger-menu"
                    class="utility-nav dropdown">
                    <div class="dropdown-menu d-block d-sm-none">
                        <div class="small-nav-container">
                            <div class="small-nav small-nav-corp-links">
                                <hr class="hr-mobile-nav d-block d-sm-none" />
                                <ul class="list-unstyled icon-list">
                                    <li class="menu-hover">
                                        <a [routerLink]="['/admin/users']">Manage Users</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mobile-nav-backdrop d-block d-sm-none"></div>
        </div>
    </div>
</nav>