<style>
.centerButtonContainer {
    display: flex;
    justify-content: center;
}
</style>
<div class="box-shadow module" style="width:80%; margin:auto; background-color: midnightblue; color: white">
    <h2>Delete ORD Operator Task</h2>
    <hr>
    <h3>Are you sure you want to delete <span style="color:red">{{taskPlanSubSection.Description}}</span> <br> from ORD Process Area <b>{{section}}</b> ?</h3>
    <h4>All the related item(s) will be removed.</h4>  
    <div class="form-actions no-color">
        <div class="centerButtonContainer">
            <a class="btn btn-danger" style="width: 100px;" (click)="deleteTaskPlanSubSection()" >Delete</a>
            &nbsp;
            &nbsp;
            <a class="btn btn-primary" style="width: 100px;" (click)="onRedirectButtonClick()">Back</a>
        </div>
    </div>  
</div>