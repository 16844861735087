<div class="box-shadow module">    
  <h2>Create Generic Equipment Type</h2>
  <hr>    
  <div class="form-horizontal">
      <label style="margin-left:17%; margin-top:1%; color:orangered; font:small-caption" *ngIf="displayWarningNote">
      * This Code has already been taken
      </label>
      <!-- <br>  
      <label style="margin-left:17%; color:dimgray; font:small-caption">
          [Note] Code name needs to be unique, and it cannot be renamed after the Generic Equipment Type is created.
      </label>     -->
      <!-- <div class="form-group">
          <label class="control-label col-md-2">Code</label>
          <div class="col-md-10">
              <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Code">
              <span class="text-danger field-validation-error" *ngIf="displayWarningCode">
                  <span>The Code field is required.</span>
              </span>
          </div>
      </div> -->
      <div class="form-group">
        <label class="control-label col-md-2">Business Unit</label>
        <label class="control-label col-md-10" style="text-align:left">{{storedBU.Name}}</label>
      </div>  
      <div class="form-group">
          <label class="control-label col-md-2">Description</label>
          <div class="col-md-10">                
              <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Description">
              <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
                  <span>The Description field is required.</span>
              </span>
          </div>
      </div>
      <!-- <div class="form-group">
          <label class="control-label col-md-2">Equipment</label>
          <div class="col-md-10">                
              <p-multiSelect [options]="Equipments" [(ngModel)]="taskPlan.Equipment" defaultLabel="Select Equipment"
               filterPlaceHolder="Search for Equipment" showToggleAll="false" [style]="{'width': '100%'}">
              </p-multiSelect>
          </div>
      </div>
      <div class="form-group">
          <label class="control-label col-md-2">Generic</label>
          <div class="col-md-10">                
              <p-dropdown placeholder="Select a Generic Task Plan to import" [disabled]="disableGeneric" [showClear]="true"
               [filter]="true" [style]="{'width': '100%'}">
              </p-dropdown>
          </div>
      </div>        -->
      <div class="form-group">
          <div class="col-md-offset-2 col-md-10">
              <a class="btn btn-primary" style="width: 100px;" (click)="createTaskPlan()">Create</a>
          </div>
      </div>
  </div>
  <div>
      <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Generic Equipment Types</a> 
  </div>    
</div>