<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px">
    <h2>Edit ORD Operator Task</h2>
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-md-2">Description</label>
            <div class="col-md-10">                
                <textarea class="form-control text-box single-line white-space-pre" [(ngModel)]="taskPlanSubSection.Description" cols="5" rows="5"></textarea>
                <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
                    <span>This field is required.</span>
                </span>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-primary" style="width: 100px;" (click)="updateTaskPlanSubSection()">Save</a>
            </div>
        </div>
    </div>    
</div>
<div style="width:80%; margin:auto">
    <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Task Plan Manage</a> 
</div>

