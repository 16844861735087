import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { ORDResponse } from 'models/ordResponse';
import { Router } from '@angular/router';
@Component({
  selector: 'app-incompletefieldgrid',
  templateUrl: './incompletefieldgrid.component.html',
  styleUrls: ['./incompletefieldgrid.component.scss']
})
export class IncompletefieldgridComponent implements OnInit {

  sortKey: string;
  sortField: string;
  sortOrder: number;

  selectedord: ORDResponse = new ORDResponse();

  private _responsesIncomplete: ORDResponse[];
  @Input()
  set responsesIncomplete(responsesIncomplete: ORDResponse[]) {
    this._responsesIncomplete = responsesIncomplete || [];

    if (this.dataView && this.dataView.first) {
      this.dataView.first.first = 0;
    }
  }
  get responsesIncomplete(): ORDResponse[] { return this._responsesIncomplete; }

  @Input()
  onlyShowIncomplete: boolean;
  
  @ViewChildren('dv') dataView: QueryList<any>;

  ngOnInit() {
    this.sortOrder = -1;
    this.sortField = 'StartedDate'; // 'ordId';
  }

  constructor(
    private _router: Router
  ) {
  }

  onSortChange(event) {
    this.sortOrder = this.sortField === event ? this.sortOrder * -1 : 1;
    this.sortField = event;
  }

 /* onRedirectButtonClick(id: number, ordID: number) {
    if (id !== undefined && id != null) {
      this._router.navigate(['taskReport', {id: id, ordId: ordID}]);
    }
  }*/

}
