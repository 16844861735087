
import { finalize } from 'rxjs/operators';
import { Component, OnInit, AfterViewChecked, Inject } from '@angular/core';
import { AuthService } from '#services/shared';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SelectItem } from 'primeng/api';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { hierarchyService } from '#services/api/hierarchy.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewChecked {
  selectedMenuIndex: number;
  public href = "";
  route: string;
  BusinessUnits: SelectItem[];
  selectedBU: BusinessUnit;
  BUName = '';
  public storedData: any = [];
  isAdminForAllBUs: boolean;
  isGeographical = true;
  isOperational = false;
  hierarchy = '';

  constructor(
    public authSvc: AuthService,
    private hieService: hierarchyService,
    private router: Router,
    private location: Location,
    public loadingIndicatorSvc: LoadingIndicatorService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    private buSvc: BusinessUnitsService
  ) {
    router.events.subscribe((val) => {
      if (location.path() !== '') {
        this.route = location.path();
        // console.log('this.route ' + this.route);
        if (this.route.includes("generic") || this.route.includes("ordID=1;")) {
          this.selectedMenuIndex = 4;
        } else if (this.route.includes("admin")) {
          this.selectedMenuIndex = -1;
        } else {
          if (this.route.includes("field-reports")) {
            this.selectedMenuIndex = 1;
          }
        }
      } else {
        this.route = 'Home';
        this.selectedMenuIndex = 1;
      }
      if (this.isAdminForAllBUs) {
        this.updateBusinessUnitsDisplay();
      }

      // console.log('this.selectedMenuIndex '+this.selectedMenuIndex);
    });
  }
  saveInLocal(key: string, val: any): void {
    this.storage.set(key, val);
    this.storedData[key] = this.storage.get(key);
    if (this.storedData["BU"]) {
      this.selectedBU = this.storedData["BU"];
    }
  }

  getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  public setBUName() {
    console.log("drpdown :" + JSON.stringify(this.selectedBU))
    var storageBU = this.storage.get("BU");
    if (this.selectedBU == undefined)
      this.router.navigate(['']);

    if (this.selectedBU != null) {
      if (this.selectedBU.Id != storageBU.Id) {
        this.loadingIndicatorSvc.show();
        this.buSvc.getBusinessUnitByID(this.selectedBU.Id).pipe(
          finalize(() => {
            this.loadingIndicatorSvc.hide();
            // location.reload();
            this.redirectTo('/');
          }))
          .subscribe((data: BusinessUnit) => {
            this.BUName = data.Name;
            // console.log('data BU ' + JSON.stringify(data));
            this.saveInLocal("BU", data);
            // this.selectedUser.BusinessUnitID = data.Id;

            const hierarchy = data.BusinessUnitHierarchy.Description;
            switch (hierarchy) {
              case "Geographical":
                this.isGeographical = true;
                this.isOperational = false;
                break;
              case "Oper+ational":
                this.isGeographical = false;
                this.isOperational = true;
                break;
              default:
                break;
            }
            this.saveInLocal("hierarchy", hierarchy);
          },
            e => {
              // this.showError(e, 'Failed to get BU');
            });
      }
    }
    else {
      this.router.navigate(['']);
    }
  }

  redirectTo(uri: string) {
    let currentUrl = this.router.url;
    // Operational/Geographical routing
    if (currentUrl === "/field-reports" && this.isOperational === true) {
      currentUrl = "/op-field-report";
    } else if (currentUrl === "/op-field-report" && this.isGeographical === true) {
      currentUrl = "/field-reports";
    }
    // console.log("redirect to: " + currentUrl);
    // Refresh main menu items
    // https://stackoverflow.com/questions/47813927/how-to-refresh-a-component-in-angular
    if (currentUrl === "/admin/users" || currentUrl === "/field-reports" || currentUrl === "/op-field-report" || currentUrl === "/ord" || currentUrl === "/ord/field" || currentUrl === "/ord/generic") {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
    else if (currentUrl === "/") {
      // To reload Home.component we navigate to another page then quickly redirect to home again.
      this.router.navigateByUrl('/ord', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });
    }
    else {
      // Redirect to Menu 
      // console.log("no redirect");
      this.redirectToMenu();
    }
  }

  redirectToMenu() {
    const idx = this.selectedMenuIndex;
    switch (idx) {
      case 0:
        this.router.navigateByUrl('/', { skipLocationChange: false });
        break;
      case 1:
        this.router.navigateByUrl('/field-reports', { skipLocationChange: false });
        break;
      case 2:
        this.router.navigateByUrl('/ord', { skipLocationChange: false });
        break;
      case 3:
        this.router.navigateByUrl('/ord/field', { skipLocationChange: false });
        break;
      case 4:
        this.router.navigateByUrl('/ord/generic', { skipLocationChange: false });
        break;
    }
  }

  ngOnInit(): void {

    this.isAdminForAllBUs = false;
    this.getFromLocal("BU");
    this.loadingIndicatorSvc.show();
    this.BusinessUnits = [];
    this.buSvc.getBusinessUnits().pipe(
      finalize(() => {
        const userInfo = this.authSvc.getCurrentUser();
        const businessUnit = userInfo.BusinessUnit;
        const hierarchy = businessUnit.BusinessUnitHierarchy.Description;
        this.setHierarchy(hierarchy);
        if (userInfo && userInfo.BusinessUnitID >= 0) {
          this.loadingIndicatorSvc.show();
          this.buSvc.getBusinessUnitByID(userInfo.BusinessUnitID).pipe(
            finalize(() => this.loadingIndicatorSvc.hide()))
            .subscribe((dataResp: BusinessUnit) => {
              // Always check status in the server
              if (dataResp.Id == 0) {
                this.isAdminForAllBUs = true;
                this.saveInLocal("isAdminForAllBUs", true);
                if (this.storedData["BU"]) {
                  this.selectedBU = this.storedData["BU"];
                  this.BUName = this.selectedBU.Name;
                  this.setHierarchy(this.selectedBU.BusinessUnitHierarchy.Description);
                } else {
                  this.BUName = dataResp.Name;
                  this.saveInLocal("BU", dataResp);
                }
                // console.log('this.selectedBU ' + this.selectedBU.Id);
              } else {
                this.isAdminForAllBUs = false;
                this.BUName = dataResp.Name;
                this.saveInLocal("BU", dataResp);
              }
              // this.selectedUser.BusinessUnitID = data.Id;
            },
              e => {
                // this.showError(e, 'Failed to get BU');
              });
          // console.log('BU init ' + JSON.stringify(userInfo));
        }
        this.loadingIndicatorSvc.hide();
      }))
      .subscribe((data: BusinessUnit[]) => {
        this.saveInLocal("BusinessUnits", data);
        this.updateBusinessUnitsDisplay();
      });
  }

  goToReportPage() {
    this.hieService.currenthierarchymessage.subscribe(msg => this.hierarchy = msg)
    if (this.hierarchy === "Operational")
      this.router.navigateByUrl('/op-field-report', { skipLocationChange: false });
    else
      this.router.navigateByUrl('/field-reports', { skipLocationChange: false });
  }

  ngAfterViewChecked(): void {
    // todo: refactor navbar component
    (<any>window).initNavbarJS();
  }

  private setHierarchy(hierarchy: string) {
    switch (hierarchy) {
      case "Geographical":
        this.isGeographical = true;
        this.isOperational = false;
        // console.log("x Geographical set");
        break;
      case "Operational":
        this.isGeographical = false;
        this.isOperational = true;
        // console.log("x Operational set");
        break;
      default:
        break;
    }
  }

  public setSelectedMenuIndex(index: number) {
    this.selectedMenuIndex = index;
  }

  private updateBusinessUnitsDisplay() {
    this.getFromLocal("BusinessUnits");
    const data: BusinessUnit[] = this.storedData["BusinessUnits"];
    const currentUrl = this.router.url;
    this.BusinessUnits = [];
    data.forEach((bu) => {
      const element: SelectItem = { label: bu.Name, value: bu };
      if (bu.Name === "ALL BUSINESS UNITS") {
        if (currentUrl.includes("users") && this.isAdminForAllBUs) {
          // console.log("pushing ALL BU");
          this.BusinessUnits.push(element);
        }
      } else {
        this.BusinessUnits.push(element);
      }
      // console.log("loop");
    });

    var userDetail = this.storage.get("CurrentUser");
    if (userDetail != null || userDetail != undefined) {
      this.buSvc.getBusinessUnitByID(userDetail.BusinessUnitID)
        .subscribe((dataResp: BusinessUnit) => {
          this.selectedBU = dataResp;
          this.setHierarchy(this.selectedBU.BusinessUnitHierarchy.Description);
          this.saveInLocal("BU", dataResp);
        });
    }
  }
}
