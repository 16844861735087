import { Component, OnInit, Inject } from '@angular/core';
import { ORDService } from '#services/api/ord.service';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ORDTemplate } from '#models/ordTemplate';
import { SelectItem, MessageService } from 'primeng/api';
import { Route } from '#models/route';
import { CollectionPoint } from '#models/collectionpoint';
import { Subarea } from '#models/subarea';
import { Area } from '#models/area';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { AuthService } from '#services/shared';
import { WebStorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-ord-template-edit',
  templateUrl: './ord-template-edit.component.html',
  styleUrls: ['./ord-template-edit.component.scss']
})
export class ORDTemplateEditComponent implements OnInit {
  ord: ORDTemplate = new ORDTemplate();
  ordId: number;
  displayWarningDesc: boolean;
  Cai: string;
  IsAdmin: boolean;
  private storedData: any = [];

  // Options of Dropdown Menus
  BusinessUnits: SelectItem[];
  Areas: SelectItem[];
  subAreas: SelectItem[];
  routes: SelectItem[];
  colPoints: SelectItem[];

  // Object that is actually selected from Dropdown
  selectedArea: SelectItem;
  selectedSubArea: SelectItem;
  selectedRoute: SelectItem;
  selectedColPoint: SelectItem;

  // Flags to show/hide/disable content
  disableSubArea: boolean;
  disableRoute: boolean;
  disableColPoint: boolean;
  displayWarningNote: boolean;
  displayWarningCode: boolean;

  displayWarningHeirarchy: boolean;

  constructor(
    private ordSvc: ORDService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    public omSvc: OMWorkbenchService,
    private messageSvc: MessageService,
    private authSvc: AuthService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(v => {
      this.ordId = v['id'];
    });
    this.getordById(this.ordId);
    this.displayWarningDesc = false;
    this.displayWarningNote = false;
    this.displayWarningCode = false;
    this.displayWarningHeirarchy = false;
    this.disableSubArea = true;
    this.disableRoute = true;
    this.disableColPoint = true;
    this.getFromLocal("BU");
    const storedBU = this.storedData["BU"];

    this.Cai = this.authSvc.getCurrentUser().Cai;
    this.IsAdmin = this.authSvc.isSystemAdmin();
    // if (!this.IsAdmin) {
    //   this.ord.BusinessUnitID = this.authSvc.getCurrentUser().BusinessUnitID;
    // }
    this.ord.BusinessUnitID = storedBU.Id;

    this.BusinessUnits = [{ label: 'Select BU', value: null }];
    this.Areas = [{ label: 'Select an Area', value: null }];
    this.subAreas = [{ label: 'Select a sub-area', value: null }];
    this.routes = [{ label: 'Select a route', value: null }];
    this.colPoints = [{ label: 'Select a Collection Point', value: null }];
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/field']);
  }

  public getordById(id: number): void {
    this.loadingIndicatorSvc.show();
    this.ordSvc.getORDTemplateByID(id)
      .pipe(
        finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        this.ord = data;
        this.setArea();
      });
  }

  private setArea(): void {
    this.getAreas();
  }

  public updateORD(): void {
    this.displayWarningDesc = this.ord.Description === undefined || this.ord.Description == null || this.ord.Description.length === 0 ||
      this.ord.Name === undefined || this.ord.Name == null || this.ord.Name.length === 0;
    const invalidHeirarchy =
      this.ord.AreaID === undefined || this.ord.AreaID == null || this.ord.AreaID.length === 0
      || this.ord.SubAreaID === undefined || this.ord.SubAreaID == null || this.ord.SubAreaID.length === 0
      || this.ord.RouteID === undefined || this.ord.RouteID == null || this.ord.RouteID.length === 0
      || this.ord.CollectionPointID === undefined || this.ord.CollectionPointID == null || this.ord.CollectionPointID.length === 0;
    this.displayWarningHeirarchy = invalidHeirarchy;
    if (!this.displayWarningDesc && !this.displayWarningHeirarchy) {
      this.loadingIndicatorSvc.show();
      this.ordSvc.updateORDTemplate([this.ord])
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          this.onRedirectButtonClick();
        });
    }
  }

  getAreas() {
    this.loadingIndicatorSvc.show();
    if (this.IsAdmin) {
      this.omSvc.getAllAreas()
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: Area[]) => {
          data.forEach((area) => {
            const element: SelectItem = { label: area.Name, value: area };
            this.Areas.push(element);
            if (this.ord.AreaID === area.ID) {
              this.selectedArea.value = area;
              this.getSubAreas();
            }
          });
        }, e => {
          this.Areas = [];
          this.showError(e, 'Could not get Areas from EC');
        });
    } else {
      this.omSvc.getAreas(this.Cai)
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: Area[]) => {
          data.forEach((area) => {
            const element: SelectItem = { label: area.Name, value: area };
            this.Areas.push(element);
            if (this.ord.AreaID === area.ID) {
              this.selectedArea.value = area;
              this.getSubAreas();
            }
          });
        }, e => {
          this.Areas = [];
          this.showError(e, 'Could not get Areas from EC');
        });
    }
  }

  // Get Sub Areas by the Area ID selected, and update ORD to selected Area
  getSubAreas() {
    if (this.selectedArea == null) {
      // Empty out sub fields
      this.selectedSubArea = null;
      this.selectedRoute = null;
      this.selectedColPoint = null;

      // Disable fields
      this.disableSubArea = true;
      this.disableRoute = true;
      this.disableColPoint = true;
      return;
    }
    if (this.selectedArea.value == null || this.selectedArea.value == undefined)
      return;
    this.loadingIndicatorSvc.show();
    this.subAreas = [];
    this.ord.AreaID = this.selectedArea.value.ID;
    this.ord.AreaName = this.selectedArea.value.Name;
    this.omSvc.getSubAreas(this.selectedArea.value.ID)
      .pipe(
        finalize(() => {
          // Handles disabling input fields
          if (this.selectedArea === null) {
            this.disableSubArea = true;
            this.disableRoute = true;
            this.disableColPoint = true;
          } else {
            this.disableSubArea = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: Subarea[]) => {
        data.forEach((sub) => {
          const element: SelectItem = { label: sub.Name, value: sub };
          this.subAreas.push(element);
          if (this.ord.SubAreaID === sub.ID) {
            this.selectedSubArea.value = sub;
            this.getRoutes();
          }
        });
      });
  }

  // Get new Routes when different Sub Area is selected
  getRoutes() {
    if (this.selectedSubArea == null) {
      // Empty out sub fields
      this.selectedRoute = null;
      this.selectedColPoint = null;

      // Disable fields
      this.disableRoute = true;
      this.disableColPoint = true;
      return;
    }
    if (this.selectedSubArea.value == null || this.selectedSubArea.value == undefined)
      return;
    this.loadingIndicatorSvc.show();
    this.routes = [];
    this.ord.SubAreaID = this.selectedSubArea.value.ID;
    this.ord.SubAreaName = this.selectedSubArea.value.Name;
    this.omSvc.getRoutes(this.selectedSubArea.value.ID)
      .pipe(
        finalize(() => {
          // Handles disabling inputs
          if (this.selectedSubArea === null) {
            this.disableRoute = true;
            this.disableColPoint = true;
          } else {
            this.disableRoute = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: Route[]) => {
        data.forEach((route) => {
          const element: SelectItem = { label: route.Name, value: route };
          this.routes.push(element);
          if (this.ord.RouteID === route.ID) {
            this.selectedRoute.value = route;
            this.getCollectionPoints();
          }
        });
      });
  }

  // Gets the newest CPs based on new route chosen
  getCollectionPoints() {
    if (this.selectedRoute == null) {
      // Empty out sub fields
      this.selectedColPoint = null;

      // Disable fields
      this.disableColPoint = true;
      return;
    }
    if (this.selectedRoute.value == null || this.selectedRoute.value == undefined)
      return;
    this.loadingIndicatorSvc.show();
    this.colPoints = [];
    this.ord.RouteID = this.selectedRoute.value.ID;
    this.ord.RouteName = this.selectedRoute.value.Name;
    this.omSvc.getCollectionPoints(this.selectedRoute.value.ID)
      .pipe(
        finalize(() => {
          // Handles disabling the inputs
          if (this.ord.RouteID === null) {
            this.disableColPoint = true;
          } else {
            this.disableColPoint = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: CollectionPoint[]) => {
        data.forEach((colPoint) => {
          const element: SelectItem = { label: colPoint.Name, value: colPoint };
          this.colPoints.push(element);
          if (this.ord.CollectionPointID === colPoint.ID) {
            this.selectedColPoint.value = colPoint;
          }
        });
      });
  }

  clearSubArea() {
    this.selectedSubArea = null;
    this.ord.SubAreaID = null;
    this.ord.SubAreaName = null;
    this.getSubAreas();
    this.clearRoute();
  }

  clearRoute() {
    this.selectedRoute = null;
    this.ord.RouteID = null;
    this.ord.RouteName = null;
    this.getRoutes();
    this.clearColPoint();
  }

  clearColPoint() {
    this.selectedColPoint = null;
    this.ord.CollectionPointID = null;
    this.ord.CollectionPointName = null;
    this.getCollectionPoints();
  }

  // Updates the ord to the selected Collection Point
  updateCollectionPoint() {
    if (this.selectedColPoint == null || this.selectedColPoint == undefined || this.selectedColPoint.value == null || this.selectedColPoint.value == undefined)
      return;
    this.ord.CollectionPointID = this.selectedColPoint.value.ID;
    this.ord.CollectionPointName = this.selectedColPoint.value.Name;
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }
}
