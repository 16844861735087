
import { finalize } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { CalAngularService, CvxClaimsPrincipal, ICvxClaimsPrincipal } from '@cvx/cal-angular';
import { LoadingIndicatorService } from './loading-indicator.service';
import { UserService } from '#services/api';
import { LoggedUser } from 'models';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { Observable, firstValueFrom } from 'rxjs';
import { resolve } from 'path';
import { environment } from '#environments/environment';
// import { MsalService } from '@azure/msal-angular/msal.service';

@Injectable()
export class AuthService {
    private currentUser: LoggedUser;
    private userInfo: LoggedUser;
    private currentUserBUName = "";
    private storedData: any = [];
    private user_cai: string;
    constructor(
        private authService: CalAngularService,
        private loadingIndicatorSvc: LoadingIndicatorService,
        private userSvc: UserService,
        @Inject(LOCAL_STORAGE) private storage: WebStorageService
    ) {
    }
    currentUserProfile: ICvxClaimsPrincipal;
    loggedIn: boolean;
    ngOnInit() {
        this.authService.isUserSignedIn().subscribe({
            next(isUserSignedIn: boolean) {
                this.loggedIn = isUserSignedIn;
                if (isUserSignedIn == true) {
                    //   this.setAuthorization();
                    this.currentUserProfile = this.authService.cvxClaimsPrincipal;
                }
                else {
                    console.log("user not yet signed in");
                    //  this.currentUserProfile = this.userInitiatedSignIn();
                }
            }

        });
    }

    async getToken(): Promise<string> {
        const scopes = [environment.scope];
        const tokenResult = await firstValueFrom(this.authService.getAADToken(scopes));
        return tokenResult.toString();
    }

    getAuthorizationHeader(): string {
        const token = this.getToken();

        return !!token
            ? `Bearer ${token}`
            : '';
    }

    getBUHeader(): string {
        var userDetail = this.storage.get("CurrentUser");
        let businessUnitHeader = "";
        let storageBU = this.storage.get("BU");
        if (userDetail == null || userDetail == undefined) {
            businessUnitHeader = this.currentUserBUName;
        } else {
            businessUnitHeader = userDetail.BusinessUnit.Name;
        }

        if (businessUnitHeader === "ALL BUSINESS UNITS") {
            businessUnitHeader = "MCBU";
        }
        // console.log("returning businessUnitHeader " + businessUnitHeader);
        return businessUnitHeader;
    }

    getFullName(): string {
        this.userInfo = this.storage.get("CurrentUser")
        return this.userInfo ? this.userInfo.Email : null;
    }

    isAuthenticated(): boolean {
        // return this.msal.userInfo
        //     ? this.msal.userInfo.authenticated
        //     : null;
        this.authService.isUserSignedIn().subscribe({
            next(isUserSignedIn: boolean) {
                this.loggedIn = isUserSignedIn;

            }

        });

        return this.loggedIn;
    }

    isAuthorized(): boolean {
        this.userInfo = this.storage.get("CurrentUser")
        return !!this.userInfo && this.userInfo.ID > 0;
    }

    getCurrentUser(): LoggedUser {
        this.userInfo = this.storage.get("CurrentUser")
        return this.userInfo
        // return this.currentUser;
    }

    isSystemAdmin(): boolean {
        this.userInfo = this.storage.get("CurrentUser")
        return !!this.userInfo && this.userInfo.IsSystemAdmin;
    }

    async login(force: boolean = false): Promise<LoggedUser> {
        return new Promise<LoggedUser>(async (resolve, reject) => {
            try {
                await this.loginWithAD();
                resolve(this.currentUser);
            }
            catch (ex) {
                reject(ex);
            }
        });
    }


    private async loginWithAD() {
        try {
            // CAL implementation
            this.authService.userInitiatedSignIn().subscribe(async (user: CvxClaimsPrincipal) => {
                this.currentUserProfile = this.authService.cvxClaimsPrincipal;
                //await this.loadUserInfo();
                // this.storage.set("CurrentUser", this.currentUser);
            });
        }
        catch (ex) {
            throw 'User Login Failed with Exception - ' + JSON.stringify(ex);
        }
    }

    logout(): void {
        // this.msal.logoutRedirect();
        this.storage.clear();
        this.currentUser = null;
    }

    loadUserInfo() {
        // this.loadingIndicatorSvc.show();
        this.userSvc.getCurrentUser().pipe(
            finalize(() => this.loadingIndicatorSvc.hide()))
            .subscribe((userInfo: LoggedUser) => {
                this.currentUser = userInfo;
                this.storage.set("CurrentUser", this.currentUser);
                this.currentUserBUName = userInfo.BusinessUnit.Name;
                // var data = await this.userSvc.getCurrentConfiguration(userInfo.BusinessUnit.Name);
                // if (data !== null && data !== undefined) {
                //     const url = data.filter(obj => obj.Key === "ecURL");
                //     this.saveInLocal("ecURL", url[0].Value);
                // }
                return userInfo;
            });
    }

    public loadUserInfo1() : Promise<LoggedUser>{
        return new Promise<LoggedUser>(async (resolve, reject) => {
        var userInfo=null;
        this.loadingIndicatorSvc.show();
        this.userSvc.getCurrentUser().pipe(
            finalize(() => this.loadingIndicatorSvc.hide()))
            .subscribe((userInfo: LoggedUser) => {
                return userInfo;
            });
        return userInfo;
        })
    }

    saveInLocal(key: string, val: any): void {
        this.storage.set(key, val);
        this.storedData[key] = this.storage.get(key);
    }
}
