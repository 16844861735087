import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable ,  merge } from 'rxjs';

import { environment } from '#environments/environment';
import { EamEquipment } from '#models/eamEquipment';

@Injectable()
export class EquipmentService {
    private readonly httpOptions: any = { headers: { 'Content-Type': 'application/json' } };
    batchSize = 50;

    constructor(
        private http: HttpClient,
    ) { }

    public getEAMEquipmentByRoute(routeID: string): Observable<EamEquipment[]> {
        return this.http.get<EamEquipment[]>(`${environment.apiEndpoint}/equipment/getEAMEquipmentByRoute?routeIDs=${routeID}`);
    }

    public getEAMEquipmentByEAMCodes(eamCodes: string[]): Observable<EamEquipment[]> {
        let batchCall: Observable<EamEquipment[]>[] = [];
        for (let batchIndex = 0; batchIndex < eamCodes.length; batchIndex += this.batchSize) {
            let eamCodeBatch = eamCodes.slice(batchIndex, batchIndex + this.batchSize);
            let urlAttributes = "";
            eamCodeBatch.forEach(eamCode => {
                urlAttributes = urlAttributes != "" ? urlAttributes += "&" : ""; //Add & to every thing that is not the first
                let encodedName = encodeURIComponent(eamCode);
                urlAttributes += `eamCodes=${encodedName}`;
            });
            batchCall.push(this.http.get<EamEquipment[]>(`${environment.apiEndpoint}/equipment/getEAMEquipmentByID?${urlAttributes}`));
        }
        return merge(...batchCall);
    }

    public getEAMEquipmentByECNames(ecNames: string[]): Observable<EamEquipment[]> {
        let batchCall: Observable<EamEquipment[]>[] = [];
        for (let batchIndex = 0; batchIndex < ecNames.length; batchIndex += this.batchSize)
        {
            let ecNameBatch = ecNames.slice(batchIndex, batchIndex + this.batchSize);
            let urlAttributes = "";
            ecNameBatch.forEach(ecName => {
                urlAttributes = urlAttributes != "" ? urlAttributes += "&" : ""; //Add & to every thing that is not the first
                let encodedName = encodeURIComponent(ecName);
                urlAttributes += `ecNames=${encodedName}`;
            });
            batchCall.push(this.http.get<EamEquipment[]>(`${environment.apiEndpoint}/equipment/getEAMEquipmentByECName?${urlAttributes}`));
        }
        return merge(...batchCall, 5);
    }
}
