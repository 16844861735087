import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '#services/shared/shared.module';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { HomeComponent } from './home/home.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ComponentsModule } from '#components/components.module';
import { StorageServiceModule } from 'ngx-webstorage-service';

// Primeng
import { MultiSelectModule } from 'primeng/multiselect';
import{AutoCompleteModule} from 'primeng/autocomplete';
import {ChipsModule} from 'primeng/chips';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { LoginComponent } from './login/login.component';
import { CheckboxModule} from 'primeng/checkbox';
import {TabViewModule} from 'primeng/tabview';
import { TaskReportComponent } from './taskReports/task-report.component';
import { DataViewModule } from 'primeng/dataview';
import { TableModule } from 'primeng/table';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ListboxModule } from 'primeng/listbox';
import { SpinnerModule } from 'primeng/spinner';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';

import { ORDTemplateCreateComponent } from '#pages/ord/ord-template-create/ord-template-create.component';
import { ORDTemplateDeleteComponent } from '#pages/ord/ord-template-delete/ord-template-delete.component';
import { ORDTemplateEditComponent } from '#pages/ord/ord-template-edit/ord-template-edit.component';
import { OPORDTemplateEditComponent } from '#pages/ord/ord-template-edit-op/op-ord-template-edit.component';

import { TaskPlanComponent } from '#pages/ord/task-plan/task-plan.component';
import { TaskPlanCreateComponent } from '#pages/ord/task-plan-create/task-plan-create.component';
import { TaskPlanEditComponent } from '#pages/ord/task-plan-edit/task-plan-edit.component';
import { TaskPlanManageComponent } from '#pages/ord/task-plan-manage/task-plan-manage.component';
import { TaskPlanDeleteComponent } from '#pages/ord/task-plan-delete/task-plan-delete.component';
import { TaskPlanCloneComponent } from '#pages/ord/task-plan-clone/task-plan-clone.component';
import { TaskPlanSectionEditComponent } from '#pages/ord/task-plan-section-edit/task-plan-section-edit.component';
import { TaskPlanSectionDeleteComponent } from '#pages/ord/task-plan-section-delete/task-plan-section-delete.component';

import { TaskPlanSubSectionEditComponent } from '#pages/ord/task-plan-sub-section-edit/task-plan-sub-section-edit.component';
import { TaskPlanSubSectionDeleteComponent } from '#pages/ord/task-plan-sub-section-delete/task-plan-sub-section-delete.component';

import { TaskPlanItemCreateComponent } from '#pages/ord/task-plan-item-create/task-plan-item-create.component';
import { TaskPlanItemEditComponent } from '#pages/ord/task-plan-item-edit/task-plan-item-edit.component';
import { BrowserModule } from '@angular/platform-browser';
import { GenericComponent } from './ord/generic/generic.component';
import { GenericCreateComponent } from './ord/generic-create/generic-create.component';
import { GenericEditComponent } from './ord/generic-edit/generic-edit.component';
import { GenericManageComponent } from './ord/generic-manage/generic-manage.component';
import { GenericDeleteComponent } from './ord/generic-delete/generic-delete.component';
import { FieldComponent } from './ord/field/field.component';
import { FieldReportsComponent } from './field-reports/field-reports.component';
import { FieldTaskReportComponent } from './field-task-report/field-task-report.component';
import { OpFieldReportComponent } from './operational/op-field-report/op-field-report.component';
import { ORDTemplateCreateOpComponent } from './ord/ord-template-create-op/ord-template-create-op.component';


@NgModule({
    declarations: [
        HomeComponent,
        DocumentationComponent,
        LoginComponent,
        TaskReportComponent,
        ORDTemplateCreateComponent,
        ORDTemplateDeleteComponent,
        ORDTemplateEditComponent,
        OPORDTemplateEditComponent,
        TaskPlanComponent,
        TaskPlanCreateComponent,
        TaskPlanEditComponent,
        TaskPlanManageComponent,
        TaskPlanDeleteComponent,
        TaskPlanCloneComponent,
        TaskPlanSectionEditComponent,
        TaskPlanSectionDeleteComponent,
        TaskPlanSubSectionEditComponent,
        TaskPlanSubSectionDeleteComponent,
        TaskPlanItemCreateComponent,
        TaskPlanItemEditComponent,
        GenericComponent,
        GenericCreateComponent,
        GenericEditComponent,
        GenericManageComponent,
        GenericDeleteComponent,
        FieldComponent,
        FieldReportsComponent,
        FieldTaskReportComponent,
        OpFieldReportComponent,
        ORDTemplateCreateOpComponent
    ],
    imports: [
        // Angular
        CommonModule,
        SharedModule,
        FormsModule,
        ComponentsModule,
        ReactiveFormsModule,
        BrowserModule,
        StorageServiceModule,

        // PrimeNG
        InputTextModule,
        ButtonModule,
        CalendarModule,
        DropdownModule,
        DataViewModule,
        ListboxModule,
        CheckboxModule,
        TabViewModule,
        TableModule,
        InputTextModule,
        ButtonModule,
        BrowserAnimationsModule,
        ConfirmDialogModule,
        DialogModule,
        RadioButtonModule,
        DropdownModule,
        ListboxModule,
        SpinnerModule,
        CheckboxModule,
        TooltipModule,
        MultiSelectModule,
        ChipsModule,
        AutoCompleteModule,
        InputSwitchModule,
        TableModule,
        ToggleButtonModule,
        SelectButtonModule,
        ToastModule
    ],
    providers: [
        LoadingIndicatorService,
        ConfirmationService,
        MessageService
    ]
})

export class PagesModule { }
