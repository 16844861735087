
import { Component, OnInit } from '@angular/core';
import { ORDService } from '#services/api/ord.service';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Area } from '#models/area';
import { Subarea } from 'models/subarea';
import { Route, ActivatedRoute } from '@angular/router';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { TaskPlanSubSection } from '#models/task-plan-sub-section';
import { ORDTaskResponse } from '#models/ordTaskResponse';
import { TaskPlanService } from '#services/api';
import { TaskPlan } from '#models/task-plan';
import { SelectItem, MessageService } from 'primeng/api';
import { ORDResponse } from '#models/ordResponse';
import { ORDTemplate } from '#models/ordTemplate';
import { TaskPlanSection } from '#models/task-plan-section';
import { finalize } from "rxjs/operators";
class SubSecContainer {
  responses: ORDTaskResponse[];
  subSecName: string;
  isOK: boolean;
  isBlank: boolean;
}

class SectionContainer {
  subSecs: SubSecContainer[];
  sectionName: string;
}

@Component({
  selector: 'app-field-task-report',
  templateUrl: './field-task-report.component.html',
  styleUrls: ['./field-task-report.component.scss']
})
export class FieldTaskReportComponent implements OnInit {

  responseId: number;
  ordResponse: ORDResponse;
  currentORD: ORDTemplate;
  ordId: number;
  sectionID: number;

  TaskPlanMenuItems: SelectItem[];
  taskplans: TaskPlan[];
  currentTaskPlan: TaskPlan;
  onlyShowNotOK: boolean;
  onlyShowBlank: boolean;
  // Content Containers
  subSecs: TaskPlanSubSection[];
  responses: ORDTaskResponse[];
  sections: SectionContainer[];
  constructor(
    private ordSvc: ORDService,
    private omSvc: OMWorkbenchService,
    private messageSvc: MessageService,
    private tpSvc: TaskPlanService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private aRoute: ActivatedRoute,
  ) {
    this.onlyShowNotOK = false;
    this.onlyShowBlank = false;
  }

  ngOnInit() {
    this.loadingIndicatorSvc.show();

    const getAllTPMenuItem: SelectItem = { label: "Select All", value: null };
    this.TaskPlanMenuItems = [getAllTPMenuItem];

    this.aRoute.params.subscribe(v => {
      this.responseId = v['id'];
      this.ordId = v['ordId'];
      this.sectionID = v['sectionID'];
    });

    // 1. Responses from ORDTaskResponse
    this.ordSvc.getOrdTaskResponse(this.responseId)
    .subscribe((data: ORDTaskResponse[]) => {
      this.responses = data;   // Responses shown, can be filtered
    });

    // 2. Response Header from ORDResponse
    this.ordSvc.getOrdResponseById(this.responseId)
    .pipe(
      finalize(() => {
      this.loadingIndicatorSvc.hide();
     }))
    .subscribe((data: ORDResponse) => {
      this.ordResponse = data;

      // 3. Get Header from ORDTemplate
      this.ordSvc.getORDTemplateByID(this.ordResponse.ORDTemplateID)
      .subscribe((ordData: ORDTemplate) =>  {
        this.currentORD = ordData;
      });

      // 4. Get TaskPlan
      this.tpSvc.getTaskPlanByTemplateIdForSection(this.ordId, this.ordResponse.CreateDate, this.ordResponse.StartedDate, this.sectionID)
      .pipe(
        finalize((() => {
        this.sortResponses();
      })))
      .subscribe((tpData: TaskPlan[]) => {
        this.taskplans = tpData;   // Copy of Taskplans to revert back to after filtering
        tpData.forEach((tp) => {
          const element: SelectItem = { label: tp.Description, value: tp };
          this.TaskPlanMenuItems.push(element);
        });
      });
    });
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e || ''
    });
  }

  /*
  *  Sorts all responses into their correct Sections and SubSections for that Task Plan
  **/
  public sortResponses() {
    // this.loadingIndicatorSvc.show();
    let error = false;

    // Put each response in its correct section
    this.responses.forEach((response) => {

      const curTaskPlan = this.taskplans.find(x => x.ID === response.TaskPlanID);
      if (curTaskPlan === null || curTaskPlan === undefined) {
        error = true;
      } else {
        curTaskPlan.hide = false;

        const curSection = curTaskPlan.TaskPlanSections.find(x => x.ID === response.SectionID);
        if (curSection === null || curSection === undefined) {
          error = true;
        } else {
          curSection.hide = false;

          const curSubSection = curSection.TaskPlanSubSections.find(x => x.ID === response.SubSectionID);

          if (curSubSection === undefined || curSubSection === null) {
            // error = true;
          } else {
            // If Ok Status hasn't been created, then default to true
            if (curSubSection.isOk === undefined || curSubSection === null) {
              curSubSection.isOk = true;
              curSubSection.hide = false;
            }

            // If isBlank Status hasn't been created, then default to false
            if (curSubSection.isBlank === undefined || curSubSection === null) {
              curSubSection.isBlank = false;
              curSubSection.hide = false;
            }

            if (curSubSection.responses === undefined || curSubSection.responses === null) {
              curSubSection.responses = [];   // Initialize if it is undefined
            }
            const tmp = JSON.parse(JSON.stringify(response));
            const itemType = JSON.parse(JSON.stringify(tmp.TaskPlanItem.ItemOptionType));

            // Add Ok attributes to filter later if desired
            if (response.Response.toLowerCase() === "not ok" || !curSubSection.isOk) {
              curSubSection.isOk = false;
              curSubSection.isBlank = false;
            } else {
              curSubSection.isOk = true;
              // curSubSection.isBlank = false;
            }

            if (itemType === "Numeric" && response.Response === "") {
              curSubSection.isBlank = true;
            }
            if (itemType === "Statusbox" && response.Response === "") {
              curSubSection.isBlank = true;
            }

            curSubSection.responses.push(response);
          }
        }
      }
    });

    // This error means there are responses that are tied to Items that should not be in this report.
    if (error) {
      // this.showError("Some responses are mapped to deleted or unknown entities and cannot be shown here", "Unmapped Responses");
    }
    this.loadingIndicatorSvc.hide();
  }

  // Toggle between all responses, and only not OK responses
  public filterResponses(onlyShowNotOK: boolean, taskplan: TaskPlan) {
    if (taskplan === null || taskplan === undefined) {
      taskplan = new TaskPlan();
      taskplan.ID = 0;
    }
    if (this.onlyShowBlank && onlyShowNotOK) {
      this.filterBlankResponses(true, taskplan);
      return;
    }
    if (this.onlyShowBlank && !onlyShowNotOK) {
      this.filterBlankResponses(true, taskplan);
      return;
    }
    const tpID = taskplan.ID;
    this.taskplans.forEach((tp) => {
      tp.hide = true;
      tp.TaskPlanSections.forEach((section) => {
        section.hide = true;
        section.TaskPlanSubSections.forEach((subSec) => {
          if ((subSec.isOk && onlyShowNotOK) || (tp.ID !== tpID && tpID !== 0) || (subSec.isOk === undefined && onlyShowNotOK)) {
            subSec.hide = true;
          } else {
            subSec.hide = false;

            // If anything is shown, display the heirarchy info as well
            section.hide = false;
            tp.hide = false;
          }
        });
      });
    });
  }


  // Toggle between all responses, and only Blank responses with property isBlank
  public filterBlankResponses(onlyShowBlank: boolean, taskplan: TaskPlan) {
    if (taskplan === null || taskplan === undefined) {
      taskplan = new TaskPlan();
      taskplan.ID = 0;
    }
    if (!onlyShowBlank && this.onlyShowNotOK) {
      this.filterResponses(true, taskplan);
      return;
    }
    const tpID = taskplan.ID;
    this.taskplans.forEach((tp) => {
      tp.hide = true;
      tp.TaskPlanSections.forEach((section) => {
        section.hide = true;
        section.TaskPlanSubSections.forEach((subSec) => {
          subSec.hide = true;
          if ((onlyShowBlank && subSec.isBlank === true)) {
            subSec.hide = false;
            section.hide = false;
            tp.hide = false;
          }
          if ((onlyShowBlank && this.onlyShowNotOK && !subSec.isOk)) {
            subSec.hide = false;
            section.hide = false;
            tp.hide = false;
          }

          if (!onlyShowBlank) {
            subSec.hide = false;
            section.hide = false;
            tp.hide = false;
          }
        });
      });
    });
  }

}
