import { Component, OnInit, Inject } from '@angular/core';
import { ORDService } from '#services/api/ord.service';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ORDTemplate } from '#models/ordTemplate';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api/business-unit.service';
import { SelectItem, MessageService } from 'primeng/api';
import { Area } from '#models/area';
import { Subarea } from '#models/subarea';
import { Route } from '#models/route';
import { CollectionPoint } from '#models/collectionpoint';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { AuthService } from '#services/shared';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-ord-template-create',
  templateUrl: './ord-template-create.component.html',
  styleUrls: ['./ord-template-create.component.scss']
})
export class ORDTemplateCreateComponent implements OnInit {
  ord: ORDTemplate = new ORDTemplate();
  Cai: string;
  IsAdmin: boolean;
  private storedData: any = [];

  // Options of Dropdown Menus
  BusinessUnits: SelectItem[];
  Areas: SelectItem[];
  subAreas: SelectItem[];
  routes: SelectItem[];
  colPoints: SelectItem[];

  // Object that is actually selected from Dropdown
  selectedArea: SelectItem;
  selectedSubArea: SelectItem;
  selectedRoute: SelectItem;
  selectedColPoint: SelectItem;

  // Flags to show/hide/disable content
  disableSubArea: boolean;
  disableRoute: boolean;
  disableColPoint: boolean;
  displayWarningNote: boolean;
  displayWarningCode: boolean;
  displayWarningDesc: boolean;

  private buDisplayName: string;
  displayWarningHeirarchy: boolean;

  constructor(
    private ordSvc: ORDService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    public buSvc: BusinessUnitsService,
    public omSvc: OMWorkbenchService,
    private authSvc: AuthService,
    private messageSvc: MessageService,
    private _router: Router, private aRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
  }

  ngOnInit() {
    this.displayWarningNote = false;
    this.displayWarningCode = false;
    this.displayWarningDesc = false;
    this.displayWarningHeirarchy = false;
    this.disableSubArea = true;
    this.disableRoute = true;
    this.disableColPoint = true;
    this.getFromLocal("BU");
    const storedBU = this.storedData["BU"];

    this.Cai = this.authSvc.getCurrentUser().Cai;
    this.IsAdmin = this.authSvc.isSystemAdmin();
    // if (!this.IsAdmin) {
    //   this.ord.BusinessUnitID = this.authSvc.getCurrentUser().BusinessUnitID;
    // }
    this.ord.BusinessUnitID = storedBU.Id;

    this.BusinessUnits = [{ label: 'Select BU', value: null }];
    this.Areas = [{ label: 'Select an Area', value: null }];
    this.subAreas = [{ label: 'Select a sub-area', value: null }];
    this.routes = [{ label: 'Select a route', value: null }];
    this.colPoints = [{ label: 'Select a Collection Point', value: null }];

    // TODO: Hard coding these values for now as there in no place for input yet/Not sure if these inputs will be needed
    this.ord.HoursDuration = 8;
    this.ord.ScheduleID = 1;

    this.getAreas();
    // console.log("getBusinessUnits ");
    this.buSvc.getBusinessUnits()
      .subscribe((data: BusinessUnit[]) => {
        data.forEach((bu) => {
          const element: SelectItem = { label: bu.Name, value: bu.Id };
          this.BusinessUnits.push(element);

          if (bu.Id === storedBU.Id) {
            // console.log("buDisplayName found ");
            this.buDisplayName = bu.Name;
          }
        });
      });
  }

  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  getAreas() {
    if (this.IsAdmin) {
      this.omSvc.getAllAreas()
        .subscribe((data: Area[]) => {
          data.forEach((area) => {
            const element: SelectItem = { label: area.Name, value: area };
            this.Areas.push(element);
          });
        }, e => {
          this.Areas = [];
          this.showError(e, 'Could not get Areas from EC');
        });
    } else {
      this.omSvc.getAreas(this.Cai)
        .subscribe((data: Area[]) => {
          data.forEach((area) => {
            const element: SelectItem = { label: area.Name, value: area };
            this.Areas.push(element);
          });
        }, e => {
          this.Areas = [];
          this.showError(e, 'Could not get Areas from EC');
        });
    }
  }

  // Get Sub Areas by the Area ID selected, and update ORD to selected Area
  getSubAreas() {
    if (this.selectedArea == null) {
      // Empty out sub fields
      this.selectedSubArea = null;
      this.selectedRoute = null;
      this.selectedColPoint = null;

      // Disable fields
      this.disableSubArea = true;
      this.disableRoute = true;
      this.disableColPoint = true;
      return;
    }
    if (this.selectedArea == null || this.selectedArea == undefined || this.selectedArea.value == null || this.selectedArea.value == undefined)
      return;
    this.loadingIndicatorSvc.show();
    this.subAreas = [];
    this.ord.AreaID = this.selectedArea.value.ID;
    this.ord.AreaName = this.selectedArea.value.Name;
    this.omSvc.getSubAreas(this.selectedArea.value.ID)
      .pipe(
        finalize(() => {
          // Handles disabling input fields
          if (this.selectedArea === null) {
            this.disableSubArea = true;
            this.disableRoute = true;
            this.disableColPoint = true;
          } else {
            this.disableSubArea = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: Subarea[]) => {
        data.forEach((sub) => {
          const element: SelectItem = { label: sub.Name, value: sub };
          this.subAreas.push(element);
        });
      });
  }

  // Get new Routes when different Sub Area is selected
  getRoutes() {
    if (this.selectedSubArea == null) {
      // Empty out sub fields
      this.selectedRoute = null;
      this.selectedColPoint = null;

      // Disable fields
      this.disableRoute = true;
      this.disableColPoint = true;
      return;
    }
    if (this.selectedSubArea == null || this.selectedSubArea == undefined || this.selectedSubArea.value == null || this.selectedSubArea.value == undefined)
      return;
    this.loadingIndicatorSvc.show();
    this.routes = [];
    this.ord.SubAreaID = this.selectedSubArea.value.ID;
    this.ord.SubAreaName = this.selectedSubArea.value.Name;
    this.omSvc.getRoutes(this.selectedSubArea.value.ID)
      .pipe(
        finalize(() => {
          // Handles disabling inputs
          if (this.selectedSubArea === null) {
            this.disableRoute = true;
            this.disableColPoint = true;
          } else {
            this.disableRoute = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: Route[]) => {
        data.forEach((route) => {
          const element: SelectItem = { label: route.Name, value: route };
          this.routes.push(element);
        });
      });
  }

  // Gets the newest CPs based on new route chosen
  getCollectionPoints() {
    if (this.selectedRoute == null) {
      // Empty out sub fields
      this.selectedColPoint = null;

      // Disable fields
      this.disableColPoint = true;
      return;
    }
    if (this.selectedRoute == null || this.selectedRoute == undefined || this.selectedRoute.value == null || this.selectedRoute.value == undefined)
      return;
    this.loadingIndicatorSvc.show();
    this.colPoints = [];
    this.ord.RouteID = this.selectedRoute.value.ID;
    this.ord.RouteName = this.selectedRoute.value.Name;
    this.omSvc.getCollectionPoints(this.selectedRoute.value.ID)
      .pipe(
        finalize(() => {
          // Handles disabling the inputs
          if (this.ord.RouteID === null) {
            this.disableColPoint = true;
          } else {
            this.disableColPoint = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: CollectionPoint[]) => {
        data.forEach((colPoint) => {
          const element: SelectItem = { label: colPoint.Name, value: colPoint };
          this.colPoints.push(element);
        });
      });
  }

  // Updates the ord to the selected Collection Point
  updateCollectionPoint() {
    if (this.selectedColPoint == null || this.selectedColPoint == undefined || this.selectedColPoint.value == null || this.selectedColPoint.value == undefined)
      return;
    this.ord.CollectionPointID = this.selectedColPoint.value.ID;
    this.ord.CollectionPointName = this.selectedColPoint.value.Name;
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/field']);
  }

  public createord(): void {
    const invalidDesc = this.ord.Description === undefined || this.ord.Description == null || this.ord.Description.length === 0;
    this.displayWarningDesc = invalidDesc;
    const invalidHeirarchy =
      this.ord.AreaID === undefined || this.ord.AreaID == null || this.ord.AreaID.length === 0
      || this.ord.SubAreaID === undefined || this.ord.SubAreaID == null || this.ord.SubAreaID.length === 0
      || this.ord.RouteID === undefined || this.ord.RouteID == null || this.ord.RouteID.length === 0
      || this.ord.CollectionPointID === undefined || this.ord.CollectionPointID == null || this.ord.CollectionPointID.length === 0;
    this.displayWarningHeirarchy = invalidHeirarchy;

    if (!invalidDesc && !invalidHeirarchy) {
      this.loadingIndicatorSvc.show();
      this.ord.IsFieldORD = true;
      this.ordSvc.createORDTemplate([this.ord])
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          this.onRedirectButtonClick();
        });
    }
  }
}
