import { Component, OnInit } from '@angular/core';
import { AuthService } from '#services/shared';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  userName: string;

  constructor(private authSvc: AuthService) { }

  ngOnInit() {
    this.userName = this.authSvc.getFullName();
  }
}
