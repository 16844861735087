import { EquipmentBase } from "./equipmentBase";
import { EquipmentEC } from "./equipmentEC";

export class Equipment extends EquipmentBase {
    ObjectId: string;
    ID: number;

    // Build in constructor to map Equipment from EC to our EQ model
    // Mainly used to map EC Id(string) to our ObjectID(string), and leave our
    // ID (int) untouched for the DB to handle

    constructor(eq: EquipmentEC) {
        super();
        this.ObjectId = eq.ID;
        this.CreatedDate = eq.CreatedDate;
        this.EndDate = eq.EndDate;
        this.EquipmentType = eq.EquipmentType;
        this.Name = eq.Name;
        this.RouteId = eq.RouteId;
        this.CollectionPointId = eq.CollectionPointId;
    }
}
