import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AuthService } from '#services/shared';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable()
export class IsSystemAdmin implements CanActivate {
    constructor(
        private authSvc: AuthService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authSvc.isSystemAdmin()) {
            return true;
        } else {
            this.router.navigate([this.authSvc.isAuthenticated() ? '/error/not-authorized' : '/login']);

            return false;
        }
    }
}

@Injectable()
export class IsApplicationUser implements CanActivate {
    constructor(
        private authSvc: AuthService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authSvc.isAuthorized()) {
            return true;
        } else {
            this.router.navigate([this.authSvc.isAuthenticated() ? '/error/not-authorized' : '/login']);

            return false;
        }
    }
}
