<div class="box-shadow module">
  <h2>My ORDs</h2>  
  <div class="row">
    <div class="col-sm-6">
        <a class="btn btn-primary" (click)="onRedirectButtonClick('create')" style="cursor: pointer;">Create New</a> 
    </div>
    <div class="col-sm-6">
    </div>
  </div>

  <table class="table table-hover">
    <tr>
      <th>Name</th>
      <th>Description</th>
      <th>Route</th>
      <th></th>
    </tr>
    <tr *ngFor="let ord of ords">
      <td><a (click)="onRedirectButtonClick('TaskPlan', ord.ID)" style="cursor: pointer;">{{ord.Name}}</a></td>
      <td>{{ord.Description}}</td>
      <td>{{ord.RouteName}}</td>
      <td style="text-align:end">   
        <a (click)="onRedirectButtonClick('edit', ord.ID)" style="cursor: pointer;">Edit</a> |
        <a (click)="onRedirectButtonClick('delete',ord.ID)" style="cursor: pointer;">Delete</a>
      </td>
    </tr>
  </table>
</div>