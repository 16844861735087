
import {of as observableOf,  Observable } from 'rxjs';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import * as msal from "@azure/msal-browser"; 
import {
  AppComponent, HeaderComponent, FooterComponent, WelcomeComponent,
  LoadingIndicatorComponent, FeedbackComponent, VersionComponent
} from './';
import { AppRoutingModule } from './app.routing.module';
import { LoadingIndicatorService, AuthService } from '#services/shared';
import { SharedModule } from '#services/shared/shared.module';
import {MessageService,ConfirmationService } from 'primeng/api';
import {ToastModule} from 'primeng/toast';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ToggleButtonModule} from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ComponentsModule } from '#components/components.module';
import { environment } from '#environments/environment';
import { AuthInterceptor, DatetimeParseInterceptor } from '#services/interceptors';
import { BlockUIModule } from 'primeng/blockui';
import { DropdownModule } from 'primeng/dropdown';
import { CalAngularModule } from  '@cvx/cal-angular';
import { LoginComponent } from '#pages/login/login.component';

@NgModule({
  declarations: [ 
    AppComponent,
    HeaderComponent,
    FooterComponent,
    WelcomeComponent,
    LoadingIndicatorComponent,
    FeedbackComponent,
    VersionComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    CalAngularModule.forRoot({
      "autoSignIn": false,
      "popupForLogin": false,
      "instance": "https://login.microsoftonline.com/",
      "tenantId": "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
      "clientId": environment.clientId,
      // redirectUri will need to match a redirectUri setup in your app registration
      "redirectUri": environment.redirectURL,
      "oidcScopes": ["openid"],
      "graphScopes": ["User.Read", "Directory.Read.All"],
      "cacheLocation": "localStorage"
    }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    ToastModule,
    ConfirmDialogModule,
    ComponentsModule,
    BlockUIModule,
    ToggleButtonModule,
    SelectButtonModule,
    DropdownModule
  ],
  providers: [
   
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DatetimeParseInterceptor,
      multi: true
    }
   
    ,
    LoadingIndicatorService,
    MessageService,
    ConfirmationService
  ],
  bootstrap: [AppComponent]
})

export class AppModule { }
