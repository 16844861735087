<div class="panel">
    <h2>User Maintenance</h2>
    <div class="container">
  
      <div class="row" style="margin-bottom: 1em">
        <div class="col-sm-8 col-sm-offset-3">
          <h3 style="text-align:left"><i class="pi pi-search"></i> Add a New User</h3>
          <p-autoComplete (completeMethod)="searchDirectoryUsers($event)" (onSelect)="fillInfo($event)" [suggestions]="directoryUsers"
            [dropdown]="true" [size]="63" placeholder="Type first name, last name, CAI, or email" field="GivenName" [minLength]="2"
            [(ngModel)]="selectedUser">
            <ng-template let-u pTemplate="item">
              <div class="p-helper-clearfix" style="border-bottom:1px solid #D5D5D5">
                <div><i class="pi pi-user"></i> {{u.GivenName}} {{u.Surname}} ({{u.Cai}})</div>
              </div>
            </ng-template>
          </p-autoComplete>
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <label>Business Unit: </label>            
          <p-dropdown [options]="BusinessUnits" [(ngModel)]="selectedBU" optionLabel="label"
          (onChange)="setBUName()"  placeholder="Select a BU"></p-dropdown>
        </div>
      </div>       

      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <label>CAI:</label>
          <input type="text" style="opacity:.4"  value={{CAI}} class="form-control" readonly>
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <label>First Name:</label>
          <input type="text" style="opacity:.4" value={{FirstName}} class="form-control" readonly>
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <label>Last Name:</label>
          <input type="text" style="opacity:.4"  value={{LastName}} class="form-control" readonly>
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <label>Email Address:</label>
          <input type="email" style="opacity:.4"   pInputText value={{EmailAddress}} class="form-control" readonly>
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-6 col-sm-offset-3">
          <label>Access Permissions:</label><br />&nbsp;
          <p-checkbox name="group1" binary="true" label="System Admin" [(ngModel)]="selectedUser.IsSystemAdmin" inputId="sa" [disabled]="disableAdminCheckbox"></p-checkbox>
        </div>
      </div>
  
      <div class="row">
        <div class="col-sm-12">
          <div class="btn-container labeled right">
            <p-button label="Save User" icon="pi pi-check" styleClass="p-button-primary" style="float: right;" (click)="saveUser()"></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="panel">
    <div class="container">
      <h4 style="text-align:left"><i class="pi pi-users"></i> User list</h4>
      <p-table #tt [value]="userList" [globalFilterFields]="['Cai','FirstName','LastName','Email']">
        <ng-template pTemplate="caption">
          <div class="row">
            <div class="col-sm-6">
              <input type="text" pInputText placeholder="Filter" (input)="tt.filterGlobal($event.target.value, 'contains')"
                class="form-control">
            </div>
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th pSortableColumn="Cai">CAI <p-sortIcon field="Cai"></p-sortIcon>
            </th>
            <th pSortableColumn="FirstName">First Name <p-sortIcon field="FirstName"></p-sortIcon>
            </th>
            <th pSortableColumn="LastName">Last Name <p-sortIcon field="LastName"></p-sortIcon>
            </th>
            <th pSortableColumn="Email" width="300">Email <p-sortIcon field="Email"></p-sortIcon>
            </th>
            <th *ngIf="!editBUView; else editView" pSortableColumn="BU" width="150">BU <p-sortIcon field="BU"></p-sortIcon>
            </th>
            <ng-template #editView>
                <th pSortableColumn="BU" width="250">BU <p-sortIcon field="BU"></p-sortIcon>
                </th>
            </ng-template>
            <th pSortableColumn="deleteUser" width="110">Delete<p-sortIcon field="deleteUser"></p-sortIcon>
            </th>
            <th pSortableColumn="sysAdmin" width="110">System Admin?<p-sortIcon field="sysAdmin"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user>
          <tr>
            <td>{{user.Cai}}</td>
            <td>{{user.FirstName}}</td>
            <td>{{user.LastName}}</td>
            <td>{{user.Email}}</td>
            <td *ngIf="!editBUView; else editView">
              {{user.BusinessUnit.Name}}
              <p-button icon="pi pi-pencil" styleClass="p-button-secondary p-button-xs" (click)="changeBUView(user)"></p-button>
            </td>
            <ng-template>
              <td>
                <p-dropdown [options]="BusinessUnits" optionLabel="label" [(ngModel)]="user.BusinessUnit" placeholder="Select a BU" ></p-dropdown>
                <p-button icon="pi pi-save" styleClass="p-button-secondary p-button-xs" (click)="saveBUChanges(user)"></p-button>
                <p-button icon="pi pi-times" styleClass="p-button-secondary p-button-xs" (click)="changeBUView(user)"></p-button>
              </td>
            </ng-template>
            <td>
              <p-button icon="pi pi-trash" styleClass="p-button-secondary p-button-xs" (click)="deleteUser(user)"></p-button>
            </td>
            <td>
                <p-inputSwitch [(ngModel)]="user.IsSystemAdmin" (click)="updateUserStatus(user)"></p-inputSwitch>
            </td>
  
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  