<div class="box-shadow module">
  <h2>Generic Equipment Types</h2>
  <div class="row">
    <div class="col-sm-6">
      <a class="btn btn-primary" (click)="onRedirectButtonClick('generic-create')" style="cursor: pointer;">Create New</a>
    </div>
    <div class="col-sm-6">
    </div>
  </div>

  <table class="table table-hover">
    <tr>
      <th>Name</th>
      <th></th>
      <th></th>
    </tr>
    <tr *ngFor="let tp of taskPlans">
      <td>{{tp.Description}}</td>
      <td style="text-align:end">
        <a (click)="onRedirectButtonClick('generic-edit',tp.ID)" style="cursor: pointer;">Edit</a> |
        <a (click)="onRedirectButtonClick('TaskPlan-manage',tp.ID)" style="cursor: pointer;">Manage</a> |
        <a (click)="onRedirectButtonClick('generic-delete',tp.ID)" style="cursor: pointer;">Delete</a>
      </td>
      <td style="text-align: end" width="5%">
        <img src="assets/images/blank.png" class="imageIcon-sm ShowOnHover" alt="blank" *ngIf="tp.ID == firstTPId" />
        <a (click)="moveUpTaskPlan(tp.ID)" *ngIf="tp.ID != firstTPId">
          <img src="assets/images/upTriangle.png" alt="upTriangle" class="imageIcon-sm ShowOnHover" />
        </a>
        <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm ShowOnHover" *ngIf="tp.ID == lastTPId" />
        <a (click)="moveDownTaskPlan(tp.ID)" *ngIf="tp.ID != lastTPId">
          <img src="assets/images/dnTriangle.png" alt="dnpTriangle" class="imageIcon-sm ShowOnHover" />
        </a>
      </td>
    </tr>
  </table>
</div>
<div class="row">
  <div class="col-sm-6">
    <a class="btn btn-primary" (click)="onRedirectButtonClick('')" style="cursor: pointer;">Back to ORD</a>
  </div>
  <div class="col-sm-6">
  </div>
</div>