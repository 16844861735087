import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router , ActivatedRoute} from '@angular/router';
import { ORDTemplate } from '#models/ordTemplate';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-generic-delete',
  templateUrl: './generic-delete.component.html',
  styleUrls: ['./generic-delete.component.scss']
})
export class GenericDeleteComponent implements OnInit {
  taskPlan: TaskPlan = new TaskPlan();
  ordID: number;
  taskPlanId: number;
  canBeDeleted: boolean;
  constructor(
    private taskPlanSvc: TaskPlanService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(v => {
      this.taskPlanId = v['id'];
      this.ordID = v['ordID'];
    });
    this.getTaskPlanById(this.taskPlanId);
    this.canBeDeleted = true;
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/generic', {id: this.ordID}]);
  }

  public getTaskPlanById(id: number): void {
    this.loadingIndicatorSvc.show();
        this.taskPlanSvc.getTaskPlanById(id)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: TaskPlan[]) => {
          this.taskPlan = data.pop();
        });
  }

  public deleteTaskPlan(): void {
    this.loadingIndicatorSvc.show();
        this.taskPlanSvc.deleteTaskPlan(this.taskPlanId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          this.onRedirectButtonClick();
        });
  }
}

