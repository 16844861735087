import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class hierarchyService {

    private hierarchymessage = new BehaviorSubject('hierarchy');
    currenthierarchymessage = this.hierarchymessage.asObservable();

    constructor() {

    }
    updateHierarchyMessage(value: string) {
        this.hierarchymessage.next(value)
    }
}