export class ORDTemplate {
    Name: string;
    ID: number;
    BusinessUnitID: number;
    TaskPlanID: number;
    Description: string;
    ScheduleID: number;
    HoursDuration: number;
    AreaID: string;
    AreaName: string;
    SubAreaID: string;
    SubAreaName: string;
    RouteID: string;
    RouteName: string;
    CollectionPointID: string;
    CollectionPointName: string;
    IsGeneric: boolean;
    IsFieldORD: boolean;
}
