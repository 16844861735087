import { Component, OnInit, Inject } from '@angular/core';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { AuthService } from '#services/shared';
import { hierarchyService } from '#services/api/hierarchy.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  private storedData: any = [];
  isGeographical = true;
  isOperational = false;

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService,
   private authSvc: AuthService,
   private hieService:hierarchyService) {
   }

  ngOnInit() {
    this.getFromLocal("BU");
    const selectedBU = this.storedData["BU"];
    let hierarchy = "";

    if (selectedBU === undefined || selectedBU === null) {
      const userInfo = this.authSvc.getCurrentUser();
      const businessUnit = userInfo.BusinessUnit;
      hierarchy = businessUnit.BusinessUnitHierarchy.Description;
    } else {
      hierarchy = selectedBU.BusinessUnitHierarchy.Description;
    }

    this.hieService.updateHierarchyMessage(hierarchy)

    switch (hierarchy) {
      case "Geographical":
        this.isGeographical = true;
        this.isOperational = false;
        break;
      case "Operational":
        this.isGeographical = false;
        this.isOperational = true;
        break;
      default:
        break;
    }
    this.saveInLocal("hierarchy", hierarchy);
  }

  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  saveInLocal(key: string, val: any): void {
    this.storage.set(key, val);
    this.storedData[key] = this.storage.get(key);
  }
}
