import { of as observableOf, throwError as observableThrowError, empty as observableEmpty, Observable, Subject } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Component, OnInit, AfterViewChecked, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { AuthService } from '#services/shared';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private refreshTokenInProgress = false;
    private tokenRefreshedSource = new Subject();
    private tokenRefreshed$ = this.tokenRefreshedSource.asObservable();
    constructor(public authSvc: AuthService,
        @Inject(LOCAL_STORAGE) private storage: WebStorageService,
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addAuthHeader(request);
        return next.handle(request).pipe(
            catchError(error => {
                if (error.status === 401) {
                    return this.refreshToken().pipe(
                        switchMap(() => {
                            request = this.addAuthHeader(request);
                            return next.handle(request);
                        }),
                        catchError(() => {
                            return observableEmpty();
                        }),);
                }
                return observableThrowError(error);
            }));
    }
    private addAuthHeader(request) {
        const BU = this.authSvc.getBUHeader();
        return request.clone({
            setHeaders: {
                'Authorization': this.storage.get("token"),
                'business_unit': BU,
                "Accept": "application/json",
                "Content-Type": "application/json"
            }
        });
        return request;
    }
    private refreshToken() {
        if (this.refreshTokenInProgress) {
            return new Observable(observer => {
                this.tokenRefreshed$.subscribe(() => {
                    observer.next();
                    observer.complete();
                });
            });
        } else {
            this.refreshTokenInProgress = true;
            this.authSvc.login();
            this.refreshTokenInProgress = false;
            this.tokenRefreshedSource.next(void 0);
            return observableOf();
        }
    }
}
