
<p-dataView  #dv [value]="responses"  *ngIf="responses && responses.length > 0" [sortField]="sortField" [sortOrder]="sortOrder"
    filterBy="CompletedDate, Assignee">
    <p-header> 
        <div class="ug clearfix report-header">
            <div class="col p-g-3" [ngClass]="{'sorted': sortField == 'CompletedDate', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
                <a (click)="onSortChange('CompletedDate')">Equipment - Part</a>
            </div> 
            <div class="col p-g-2" [ngClass]="{'sorted': sortField == 'ScheduleName', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
                <a (click)="onSortChange('ScheduleName')">Schedule Type</a>
            </div>
            <div class="col p-g-2" [ngClass]="{'sorted': sortField == 'DueDate', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
                <a (click)="onSortChange('DueDate')">Due Date</a>
            </div>
            <div class="col p-g-2" [ngClass]="{'sorted': sortField == 'CompletedDate', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
                <a (click)="onSortChange('CompletedDate')">Completed Date</a>
            </div>
            <div class="col p-g-2" [ngClass]="{'sorted': sortField == 'Assignee', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
                <a (click)="onSortChange('Assignee')">Assignee</a>
            </div>
        
           
            <div class="col p-g-1" [ngClass]="{'sorted': sortField == 'CompletedOverdue', 'asc' : sortOrder === 1, 'desc' : sortOrder === -1}">
                <a (click)="onSortChange('CompletedOverdue')">Overdue</a>
            </div>
        </div>
    </p-header>
    <ng-template let-a pTemplate="listItem">
        <div class="ug report-row">
            <div class="col p-g-3 responseItem"><a (click)="onRedirectButtonClick(a.ORDResponseID, a.ORDTemplateID, a.TaskPlanSectionID)" [ngClass]="{'redOverdue' : a.CompletedOverdue == true}" style="cursor: pointer;">{{a.TaskPlanDescription}} - {{a.TaskPlanSectionDescription}}</a></div>
            
            <div *ngIf="a.CompletedDate != null" [ngClass]="{'redOverdue' : a.CompletedOverdue == true}" class="col p-g-2 responseItem">{{a.ScheduleName}}</div>
            <div *ngIf="a.CompletedDate == null" [ngClass]="{'redOverdue' : a.CompletedOverdue == true}" class="col p-g-2 responseItem"></div>
            <div *ngIf="a.DueDate != null;" [ngClass]="{'redOverdue' : a.CompletedOverdue == true}" class="col p-g-2 responseItem">{{a.DueDate.toString().replace('GMT-0500 (Central Daylight Time)','(CT)').replace('GMT-0600 (Central Standard Time)','(CST)')}}</div>
            <div *ngIf="a.DueDate == null;"  class="col p-g-2 responseItem"></div>
           
            <div *ngIf="a.CompletedDate != null" [ngClass]="{'redOverdue' : a.CompletedOverdue == true}" class="col p-g-2 responseItem">{{a.CompletedDate.toString().replace('GMT-0500 (Central Daylight Time)','(CT)').replace('GMT-0600 (Central Standard Time)','(CST)')}}</div>
            <div *ngIf="a.CompletedDate == null" class="col p-g-2 responseItem"></div>
            
            <!--<ng-template #showNothing><div class="col p-g-2 responseItem"></div></ng-template>-->
            <div [ngClass]="{'redOverdue' : a.CompletedOverdue == true}" class="col p-g-2 responseItem">{{a.Assignee}}</div>
           
            <div *ngIf="a.CompletedOverdue == true" class="col p-g-1 responseItem redOverdue">Overdue</div>
            <div *ngIf="a.CompletedOverdue != true" class="col p-g-1 responseItem"></div>
       
        </div>
    </ng-template>
</p-dataView>
<div *ngIf="!responses || responses.length === 0">
    <h5>No data</h5>
</div>