import { TaskPlanItem } from '#models/task-plan-item';
import { ORDTaskResponse } from './ordTaskResponse';

export class TaskPlanSubSection {
    public Description: string;
    public ID: number;
    public TaskPlanSectionId: number;
    public SortID: number;
    public TaskPlanItems: TaskPlanItem[];
    public AutoCreateRepairBox: boolean;

    // Only for Angular model
    public firstItemId: number;
    public lastItemId: number;
    public responses: ORDTaskResponse[];
    public isOk: boolean;
    public hide: boolean;
    isBlank: boolean;

}
