import { Component, OnInit, Inject } from '@angular/core';
import { ORDService } from '#services/api/ord.service';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router } from '@angular/router';
import { Area } from '#models/area';
import { Subarea } from 'models/subarea';
import { Route } from 'models/route';
import { ORDTemplate } from 'models/ordTemplate';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { AuthService } from '#services/shared';
import { MessageService } from 'primeng/api';
import { BusinessUnit } from '#models/business-unit';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { ProductionSubUnit } from '#models/production-sub-unit';
import { FacilityClassTwo } from '#models/facility-class-two';
import { FacilityClassOne } from '#models/facility-class-one';
import { finalize } from "rxjs/operators";
import { BusinessUnitsService } from '#services/api';

@Component({
  selector: 'app-field',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss']
})
export class FieldComponent implements OnInit {
  public ords: ORDTemplate[];
  public areaIds: string[];
  public subareaIds: string[];
  public routeIds: string[];
  public fcty2Ids: string[];
  public fcty1Ids: string[];
  private isAdmin: boolean;
  private Cai: string;
  private storedData: any = [];
  private selectedBU: BusinessUnit;
  private selectedProdSubUnit: ProductionSubUnit = new ProductionSubUnit();

  constructor(
    private ordSvc: ORDService,
    private omSvc: OMWorkbenchService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private authSvc: AuthService,
    private messageSvc: MessageService,
    private _router: Router,
    private buSvc: BusinessUnitsService,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {

  }

  ngOnInit() {
    this.areaIds = [];
    this.subareaIds = [];
    this.routeIds = [];
    this.fcty2Ids = [];
    this.fcty1Ids = [];
    this.Cai = this.authSvc.getCurrentUser().Cai;
    this.isAdmin = this.authSvc.isSystemAdmin();
    this.getFromLocal("BusinessUnits");
    this.getFromLocal("BU");
    this.selectedBU = this.storedData["BU"];
    if (this.selectedBU == null || this.selectedBU == undefined) {
      var userDetail = this.storage.get("CurrentUser");
      this.buSvc.getBusinessUnitByID(userDetail.BusinessUnitID)
        .subscribe((dataResp: BusinessUnit) => {
          this.selectedBU = dataResp;
          this.saveInLocal("BU", dataResp);
          this.loadORDTemplates();
        });
    }
    else {
      this.loadORDTemplates();
    }
    // const data: BusinessUnit[] = this.storedData["BusinessUnits"];
    // if (this.selectedBU !=undefined && this.selectedBU.Id === 0) {
    //   this.saveInLocal("BU", data[1]);
    //   this.selectedBU = data[1];
    // }
    //this.loadORDTemplates();
  }

  private saveInLocal(key: string, val: any): void {
    this.storage.set(key, val);
    this.storedData[key] = this.storage.get(key);
  }

  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  onRedirectButtonClick(sufix: string, id?: number): void {
    // LABU and SASBU
    let navSufix = sufix;
    if (navSufix === "create") {
      this.getFromLocal("hierarchy");
      const hierarchy = this.storedData["hierarchy"];
      if (hierarchy === "Operational") {
        navSufix = navSufix + "Op";
      }
    }
    if (navSufix === "edit") {
      this.getFromLocal("hierarchy");
      const hierarchy = this.storedData["hierarchy"];
      if (hierarchy === "Operational") {
        navSufix = navSufix + "Op";
      }
    }

    if (id !== undefined && id != null) {
      this._router.navigate(['ord/' + navSufix, { id: id }]);
    } else {
      this._router.navigate(['ord/' + navSufix]);
    }
  }

  /**
   * Load Templates
   * Sys Admins: Load all templates
   * Normal Users: Load templates that have a routeID tied to any Area in EC you are tied to
   */
  private loadORDTemplates(): void {
    this.loadingIndicatorSvc.show();
    const buID = this.selectedBU.Id;
    if (this.isAdmin) {
      this.ordSvc.getORDTemplateByBusinessUnitID(buID, false, true)
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: ORDTemplate[]) => this.ords = data.filter(obj => obj.IsFieldORD === true),
          e => {
            this.ords = [];
            this.showError(e, 'Failed to get ORD Templates');
          });
    } else {
      // Grab Area Ids by Cai
      this.getFromLocal("hierarchy");
      const hierarchy = this.storedData["hierarchy"];
      if (hierarchy === "Operational") {
        this.omSvc.getProductionSubUnit()
          .pipe(
            finalize(() => {
              this.omSvc.getAreasByProductionSubUnit(this.Cai, this.selectedProdSubUnit.ObjectId)
                .pipe(
                  finalize(() => this.loadingIndicatorSvc.hide()))
                .subscribe((data: Area[]) => {
                  data.forEach((area) => {
                    this.areaIds.push(area.ID);
                  });
                  this.getSubAreas();
                },
                  e => {
                    this.ords = [];
                    this.showError(e, 'Failed to get Areas from EC');
                  });
            }))
          .subscribe((data: ProductionSubUnit[]) => {
            this.selectedProdSubUnit = data[0];
          }, e => {
            this.showError(e, 'Could not get ProductionSubUnit from EC');
          });
      } else {
        this.omSvc.getAreas(this.Cai)
          .pipe(
            finalize(() => this.loadingIndicatorSvc.hide()))
          .subscribe((data: Area[]) => {
            data.forEach((area) => {
              this.areaIds.push(area.ID);
            });
            this.getSubAreas();
          },
            e => {
              this.ords = [];
              this.showError(e, 'Failed to get Areas from EC');
            });
      }
    }
  }

  private getSubAreas() {
    this.loadingIndicatorSvc.show();
    // Grab Subsequent SubArea Ids
    this.omSvc.getSubAreasPost(this.areaIds)
      .pipe(
        finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: Subarea[]) => {
        data.forEach((subArea) => {
          this.subareaIds.push(subArea.ID);
        });
        this.getRoutes();
      },
        e => {
          this.ords = [];
          this.showError(e, 'Failed to get SubAreas from EC');
        });
  }

  private getRoutes() {
    this.loadingIndicatorSvc.show();
    // Grab Subsequent Route Ids
    const hierarchy = this.storedData["hierarchy"];
    if (hierarchy === "Operational") {
      this.loadingIndicatorSvc.hide();
      this.getFacilityClass2();
    } else {
      this.omSvc.getRoutesPost(this.subareaIds)
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: Route[]) => {
          data.forEach((route) => {
            this.routeIds.push(route.ID);
          });
          this.getORDs();
        },
          e => {
            this.ords = [];
            this.showError(e, 'Failed to get Route Ids from EC');
          });
    }
  }

  private getFacilityClass2() {
    this.loadingIndicatorSvc.show();
    this.omSvc.getFacilityClass2Post(this.subareaIds)
      .subscribe((data: FacilityClassTwo[]) => {
        data.forEach((fcty) => {
          this.fcty2Ids.push(fcty.ObjectId);
        });
        this.loadingIndicatorSvc.hide();
        this.getFacilityClass1();
      },
        e => {
          this.ords = [];
          this.showError(e, 'Failed to get getFacilityClass2 from EC');
        });
  }

  private getFacilityClass1() {
    this.loadingIndicatorSvc.show();
    this.omSvc.getFacilityClass1Post(this.fcty2Ids)
      .subscribe((data: FacilityClassOne[]) => {
        data.forEach((fcty) => {
          this.fcty1Ids.push(fcty.ObjectId);
        });
        this.loadingIndicatorSvc.hide();
        this.getORDs();
      },
        e => {
          this.ords = [];
          this.showError(e, 'Failed to get getFacilityClass1 from EC');
        });
  }

  private getORDs() {
    this.loadingIndicatorSvc.show();
    // Grab ORDs to display based on routes
    const hierarchy = this.storedData["hierarchy"];
    if (hierarchy === "Operational") {
      this.ordSvc.getORDTemplateNamesByCollectionPointID(this.fcty1Ids, false) // False bc we do not want any deleted templates coming back
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: ORDTemplate[]) => this.ords = data.filter(obj => obj.IsFieldORD === true),
          e => {
            this.ords = [];
            this.showError(e, 'Failed to get Ord Templates');
          });
    } else {
      this.ordSvc.getORDTemplateNamesByRouteID(this.routeIds, false) // False bc we do not want any deleted templates coming back
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: ORDTemplate[]) => this.ords = data.filter(obj => obj.IsFieldORD === true),
          e => {
            this.ords = [];
            this.showError(e, 'Failed to get Ord Templates');
          });
    }
  }
}
