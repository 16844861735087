import { Component, OnInit, Inject } from '@angular/core';
import { ORDService } from '#services/api/ord.service';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem, MessageService } from 'primeng/api';
import { ORDTemplate } from '#models/ordTemplate';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api/business-unit.service';
import { Route } from '#models/route';
import { CollectionPoint } from '#models/collectionpoint';
import { Subarea } from '#models/subarea';
import { Area } from '#models/area';
import { finalize } from "rxjs/operators";
import { ProductionUnit } from '#models/production-unit';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { AuthService } from '#services/shared';
import { ProductionSubUnit } from '#models/production-sub-unit';
import { WebStorageService, LOCAL_STORAGE } from 'ngx-webstorage-service';
import { FacilityClassTwo } from '#models/facility-class-two';
import { FacilityClassOne } from '#models/facility-class-one';
import { json } from 'stream/consumers';
@Component({
  selector: 'app-op-ord-template-edit',
  templateUrl: './op-ord-template-edit.component.html',
  styleUrls: ['./op-ord-template-edit.component.scss']
})
export class OPORDTemplateEditComponent implements OnInit {
  ord: ORDTemplate = new ORDTemplate();
  ordId: number;
  displayWarningDesc: boolean;
  Cai: string;
  IsAdmin: boolean;
  private storedData: any = [];

  // Options of Dropdown Menus
  // BusinessUnits: SelectItem[];
  prodUnitName: String = "";
  ProdSubUnits: ProductionUnit[];
  Areas: SelectItem[];
  subAreas: SelectItem[];
  Facilities2: SelectItem[];
  Facilities1: SelectItem[];

  // Object that is actually selected from Dropdown
  selectedProdUnit: ProductionUnit = new ProductionUnit();
  selectedProdSubUnit: ProductionSubUnit = new ProductionSubUnit();
  selectedArea: SelectItem;
  selectedSubArea: SelectItem;
  selectedFacility2: SelectItem;
  selectedFacility1: SelectItem;

  // Flags to show/hide/disable content
  disableArea: boolean;
  disableSubArea: boolean;
  disableFacility2: boolean;
  disableFacility1: boolean;
  displayWarningNote: boolean;
  displayWarningCode: boolean;
  displayWarningHeirarchy: boolean;
  private buDisplayName: string;

  constructor(
    private ordSvc: ORDService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    public buSvc: BusinessUnitsService,
    public omSvc: OMWorkbenchService,
    private authSvc: AuthService,
    private messageSvc: MessageService,
    private _router: Router, private aRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) { }

  ngOnInit() {
    this.aRoute.params.subscribe(v => {
      this.ordId = v['id'];
    });
    this.getordById(this.ordId);
    this.displayWarningNote = false;
    this.displayWarningCode = false;
    this.displayWarningDesc = false;
    this.displayWarningHeirarchy = false;
    this.disableArea = true;
    this.disableSubArea = true;
    this.disableFacility2 = true;
    this.disableFacility1 = true;
    const storedBU = this.storedData["BU"];

    this.Cai = this.authSvc.getCurrentUser().Cai;
    this.IsAdmin = this.authSvc.isSystemAdmin();
    // if (!this.IsAdmin) {
    //   this.ord.BusinessUnitID = this.authSvc.getCurrentUser().BusinessUnitID;
    // }
    this.ord.BusinessUnitID = storedBU.Id;

    this.ProdSubUnits = [{ Name: 'Select a Production Sub-Unit', ObjectId: null }];
    this.Areas = [{ label: 'Select an Area', value: null }];
    this.subAreas = [{ label: 'Select a Sub-Area', value: null }];
    this.Facilities2 = [{ label: 'Select a Facility', value: null }];
    this.Facilities1 = [{ label: 'Select a Facility', value: null }];
    // TODO: Hard coding these values for now as there in no place for input yet/Not sure if these inputs will be needed
    this.ord.HoursDuration = 8;
    this.ord.ScheduleID = 1;

    // console.log("getBusinessUnits ");
    this.buSvc.getBusinessUnits()
      .subscribe((data: BusinessUnit[]) => {
        data.forEach((bu) => {
          // const element: SelectItem = { label: bu.Name, value: bu.Id };
          // this.BusinessUnits.push(element);

          if (bu.Id === storedBU.Id) {
            // console.log("buDisplayName found ");
            this.buDisplayName = bu.Name;
          }
        });
      });


  }

  getProdUnit() {
    this.omSvc.getProductionUnit()
      .subscribe((data: ProductionUnit[]) => {
        this.prodUnitName = data[0].Name;
      }, e => {
        this.showError(e, 'Could not get ProdUnits from EC');
      });
  }

  getProdSubUnits() {
    this.ProdSubUnits = [];
    this.loadingIndicatorSvc.show();
    this.omSvc.getProductionSubUnit()
      .pipe(
        finalize(() => {
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: ProductionSubUnit[]) => {
        data.forEach((unit) => {
          let element: ProductionSubUnit;
          element = { Name: unit.Name, ObjectId: unit.ObjectId };
          this.ProdSubUnits.push(element);
        });
      }, e => {
        this.ProdSubUnits = [];
        this.showError(e, 'Could not get ProdUnits from EC');
      });
  }

  getAreas() {
    if (this.selectedProdSubUnit == null) {
      // Empty out sub fields
      this.selectedArea = null;
      this.selectedSubArea = null;
      this.selectedFacility2 = null;
      this.selectedFacility1 = null;

      // Disable fields
      this.disableArea = true;
      this.disableSubArea = true;
      this.disableFacility2 = true;
      this.disableFacility1 = true;
      return;
    }
    this.loadingIndicatorSvc.show();
    this.Areas = [];
    this.omSvc.getAreasByProductionSubUnit(this.Cai, this.selectedProdSubUnit.ObjectId)
      .pipe(
        finalize(() => {
          if (this.selectedProdSubUnit === null) {
            this.disableArea = true;
            this.disableSubArea = true;
            this.disableFacility2 = true;
            this.disableFacility1 = true;
          } else {
            this.disableArea = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: Area[]) => {
        data.forEach((area) => {
          const element: SelectItem = { label: area.Name, value: area };
          this.Areas.push(element);
        });
      }, e => {
        this.Areas = [];
        this.showError(e, 'Could not get Areas from EC');
      });
  }

  // Get Sub Areas by the Area ID selected, and update ORD to selected Area
  getSubAreas() {
    if (this.selectedArea == null) {
      // Empty out sub fields
      this.selectedSubArea = null;
      this.selectedFacility2 = null;
      this.selectedFacility1 = null;

      // Disable fields
      this.disableSubArea = true;
      this.disableFacility2 = true;
      this.disableFacility1 = true;
      return;
    }
    this.loadingIndicatorSvc.show();
    this.subAreas = [];
    this.ord.AreaID = this.selectedArea.value.ID;
    this.ord.AreaName = this.selectedArea.value.Name;
    this.omSvc.getSubAreas(this.selectedArea.value.ID)
      .pipe(
        finalize(() => {
          // Handles disabling input fields
          if (this.selectedArea === null) {
            this.disableSubArea = true;
            this.disableFacility2 = true;
            this.disableFacility1 = true;
          } else {
            this.disableSubArea = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: Subarea[]) => {
        data.forEach((sub) => {
          const element: SelectItem = { label: sub.Name, value: sub };
          this.subAreas.push(element);
        });
      });
  }

  // Get new Routes when different Sub Area is selected
  getFacilities2() {
    if (this.selectedSubArea == null) {
      // Empty out sub fields
      this.selectedFacility2 = null;
      this.selectedFacility1 = null;

      // Disable fields
      this.disableFacility2 = true;
      this.disableFacility1 = true;
      return;
    }
    if (this.selectedSubArea.value == null || this.selectedArea.value == undefined)
      return;
    this.loadingIndicatorSvc.show();
    this.Facilities2 = [];

    this.ord.SubAreaID = this.selectedSubArea.value.ID;
    this.ord.SubAreaName = this.selectedSubArea.value.Name;
    this.omSvc.getFacilityClass2(this.selectedSubArea.value.ID)
      .pipe(
        finalize(() => {
          // Handles disabling inputs
          if (this.selectedSubArea === null) {
            this.disableFacility2 = true;
            this.disableFacility1 = true;
          } else {
            this.disableFacility2 = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: FacilityClassTwo[]) => {
        data.forEach((route) => {
          const element: SelectItem = { label: route.Name, value: route };
          this.Facilities2.push(element);
        });
      });
  }

  getFacilities1() {
    if (this.selectedFacility2 == null) {
      // Empty out sub fields
      this.selectedFacility1 = null;

      // Disable fields
      this.disableFacility1 = true;
      return;
    }
    this.Facilities1 = [];
    if (this.selectedFacility2.value == undefined)
      return;
    this.loadingIndicatorSvc.show();
    this.ord.RouteID = this.selectedFacility2.value.ObjectId;
    this.ord.RouteName = this.selectedFacility2.value.Name;
    this.omSvc.getFacilityClass1(this.selectedFacility2.value.ObjectId)
      .pipe(
        finalize(() => {
          // Handles disabling inputs
          if (this.selectedFacility2 === null) {
            this.disableFacility1 = true;
          } else {
            this.disableFacility1 = false;
          }
          this.loadingIndicatorSvc.hide();
        }))
      .subscribe((data: FacilityClassOne[]) => {
        data.forEach((route) => {
          const element: SelectItem = { label: route.Name, value: route };
          this.Facilities1.push(element);
        });
      });
  }



  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }


  onRedirectButtonClick(): void {
    this._router.navigate(['ord/field']);
  }

  public updateORD(): void {
    this.displayWarningDesc = this.ord.Description === undefined || this.ord.Description == null || this.ord.Description.length === 0 ||
      this.ord.Name === undefined || this.ord.Name == null || this.ord.Name.length === 0;
    const invalidHeirarchy =
      this.ord.AreaID === undefined || this.ord.AreaID == null || this.ord.AreaID.length === 0
      || this.ord.SubAreaID === undefined || this.ord.SubAreaID == null || this.ord.SubAreaID.length === 0
      || this.ord.RouteID === undefined || this.ord.RouteID == null || this.ord.RouteID.length === 0
      || this.ord.CollectionPointID === undefined || this.ord.CollectionPointID == null || this.ord.CollectionPointID.length === 0;
    this.displayWarningHeirarchy = invalidHeirarchy;
    if (!this.displayWarningDesc && !this.displayWarningHeirarchy) {
      this.loadingIndicatorSvc.show();
      this.ordSvc.updateORDTemplate([this.ord])
        .pipe(
          finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          this.onRedirectButtonClick();
        });
    }
  }

  updateFacilities1() {
    if (this.selectedFacility1 == null || this.selectedFacility1 == undefined || this.selectedFacility1.value == null || this.selectedFacility1.value == undefined)
      return;
    this.ord.CollectionPointID = this.selectedFacility1.value.ObjectId;
    this.ord.CollectionPointName = this.selectedFacility1.value.Name;
  }

  public getordById(id: number): void {
    this.loadingIndicatorSvc.show();
    this.ordSvc.getORDTemplateByID(id)
      .pipe(
        finalize(() => this.loadingIndicatorSvc.hide()))
      .subscribe((data: any) => {
        this.ord = data;
        this.getProdUnit();
        this.getProdSubUnits();
      });
  }


}