<div class="feedback-banner" *ngIf="!formVisible" (click)="showForm()" [ngClass]="{'pulsing': sending}">
  <div class="feedback-banner__icon glyphicon glyphicon-comment"></div>
  <div class="feedback-banner__text">Send Feedback</div>
</div>

<div class="feedback-form" *ngIf="formVisible">
  <span class="glyphicon glyphicon-remove remove-button" (click)="hideForm()"></span>
  <form [formGroup]="feedbackForm" (ngSubmit)="submitFeedback()">
    <h3>Send us feedback</h3>
    <div class="form-group">
      <select class="form-control selectpicker" formControlName="category">
        <option *ngFor="let category of categories">
          {{category}}
        </option>
      </select>
    </div>
    <div class="form-group">
      <textarea class="form-control" rows="10" placeholder="Here you can write feedback about application, ideas and improvement."
        formControlName="text"></textarea>
    </div>
    <div class="form-group">
      <input type="submit" class="btn btn-info" value="Send Message" [disabled]="!feedbackForm.valid" />
    </div>
  </form>
</div>