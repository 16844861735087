import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-task-plan-section-delete',
  templateUrl: './task-plan-section-delete.component.html'
})
export class TaskPlanSectionDeleteComponent implements OnInit {
  taskPlanId: number;
  taskPlanDesc: string;
  taskPlanSectionId: number;
  taskPlanSectionDesc: string;
  ordID: number;

  constructor(
    private taskPlanSvc: TaskPlanService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    private messageSvc: MessageService
  ) { }

  onConfirm() {
    this.messageSvc.clear('c');
  }

  onReject() {
    this.messageSvc.clear('c');
  }

  ngOnInit() {
    this.aRoute.params.subscribe(v => {
      this.taskPlanId = v['taskPlanId'];
      this.taskPlanDesc = v['taskPlanDesc'];
      this.taskPlanSectionId = v['id'];
      this.taskPlanSectionDesc = v['desc'];
      this.ordID = v['ordID'];
    });
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/TaskPlan-manage', { id: this.taskPlanId, sectionId: this.taskPlanSectionId, ordID: this.ordID }]);
  }

  public deleteTaskPlanSection(): void {
    if (this.ordID == 1) {
      this.loadingIndicatorSvc.show();
      this.taskPlanSvc.deleteAndCloneTaskPlanSection(this.taskPlanSectionId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          this.onRedirectButtonClick();
        }, exception => {
          if (exception instanceof HttpErrorResponse) {
            switch (exception.status) {
              case 0:
                this.loadingIndicatorSvc.hide();
                this.messageSvc.clear();
                this.messageSvc.add({
                  key: 'c', sticky: true, severity: 'warn', summary: 'Timeout error',
                  detail: 'The process continues running in the background. Please wait 10 minutes and check if the changes are impacted in the ORD\'s before you try again. Error Message: ' + exception.message
                });
                break;
            }
          }
        });
    } else {
      this.loadingIndicatorSvc.show();
      this.taskPlanSvc.deleteTaskPlanSection(this.taskPlanSectionId)
        .pipe(
      finalize(() => this.loadingIndicatorSvc.hide()))
        .subscribe((data: any) => {
          this.onRedirectButtonClick();
        });
    }
  }
}
