<div class="box-shadow module">    
    <h2>Create ORD</h2>
    <hr>    
    <div class="form-horizontal">  
        <div class="form-group">
            <label class="control-label col-md-2">Name</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="ord.Name">
                <span class="text-danger field-validation-error" *ngIf="displayWarningCode">
                    <span>The Name field is required.</span>
                </span>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Description</label>
            <div class="col-md-10">                
                <input class="form-control text-box single-line" [(ngModel)]="ord.Description">
                <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
                    <span>The Description field is required.</span>
                </span>
            </div>
        </div>

        <div *ngIf="IsAdmin" class="form-group">
            <label class="control-label col-md-2">Business Unit</label>
            <label class="control-label col-md-10" style="text-align:left">{{buDisplayName}}</label>
        </div>   

        <div class="form-group">
            <span class="text-danger field-validation-error" *ngIf="displayWarningHeirarchy">
                <span>The entire route heirarchy is required.</span>
            </span>
            <label class="control-label col-md-2">Area</label>
            <div class="col-md-10">                
                <p-dropdown  [options]="Areas" [(ngModel)]="selectedArea" placeholder="Select an Area"  optionLabel="label" [showClear]="true"
                 [filter]="true" (onChange) = "getSubAreas()" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Sub-Area</label>
            <div class="col-md-10">                
                <p-dropdown [options]="subAreas" [(ngModel)]="selectedSubArea" [disabled]="disableSubArea" [showClear]="true"
                optionLabel="label" [filter]="true" (onChange) = "getRoutes()" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Route</label>
            <div class="col-md-10">                
                <p-dropdown [options]="routes" [(ngModel)]="selectedRoute" [disabled]="disableRoute"  optionLabel="label"
                 [showClear]="true" (onChange) = "getCollectionPoints()" [filter]="true" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">Collection Point</label>
            <div class="col-md-10">                
                <p-dropdown [options]="colPoints" [(ngModel)]="selectedColPoint" [disabled]="disableColPoint" optionLabel="label"
                 [showClear]="true" (onChange) = "updateCollectionPoint()" [filter]="true" [style]="{'width': '100%'}">
                </p-dropdown>
            </div>
        </div>   
            
        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-primary" style="width: 100px;" (click)="createord()">Create</a>
            </div>
        </div>
    </div>
    <div>
        <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to ORD</a> 
    </div>    
</div>