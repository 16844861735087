import { TaskPlanSection } from '#models/task-plan-section';
import { Equipment } from './equipment';

export class TaskPlan {
    public Code: string;
    public Description: string;
    public ID: number;
    public TaskPlanSections: TaskPlanSection[];
    public IsGeneric: boolean;
    public ORDTemplateID: number;
    public EquipmentID: number;
    public sortId: number;
    public GenericParentID: number;
    public BusinessUnitID: number;
    public ECObjectID: string;
    public EAMCode: string;
    public ECObjectType: string;

    // Only needed for Angular model
    public isFirstOption: boolean;
    public isLastOption: boolean;
    public EquipmentName: string;
    public Equipment: Equipment[];
    public hide: boolean;
    public displayName: string;
}
