<h2>{{taskPlan.Description}}'s Content</h2>
<div class="form-horizontal">
    <hr />
    <div class="row is-flex">
        <!-- *START* Left Panel -->
        <div class="col-sm-4 box-shadow module">
            <h4 style="color:dimgray">{{taskPlan.Description}} Equipment/Location</h4>
            <table class="table">
                <tr *ngFor="let tps of taskPlan.TaskPlanSections"
                    [ngClass]="{'isSelected' : tps.ID == selectedSectionId}">
                    <td>
                        <a (click)="selectTaskPlanSections(tps.ID)" *ngIf="tps.ID != selectedSectionId">
                            {{tps.Description}}
                        </a>
                        <div style="padding: 6px 12px; color: white; display: inline-block;"
                            *ngIf="tps.ID == selectedSectionId">
                            {{tps.Description}}
                        </div>
                    </td>
                    <td>
                        <img src="assets/images/blank.png" class="imageIcon-sm ShowOnHover" alt="blank"
                            *ngIf="tps.ID == firstSectionId" />
                        <a (click)="moveUpTaskPlanSection(tps.ID)" *ngIf="tps.ID != firstSectionId">
                            <img src="assets/images/upTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                        </a>
                    </td>
                    <td>
                        <img src="assets/images/blank.png" class="imageIcon-sm ShowOnHover" alt="blank"
                            *ngIf="tps.ID == lastSectionId" />
                        <a (click)="moveDownTaskPlanSection(tps.ID)" *ngIf="tps.ID != lastSectionId">
                            <img src="assets/images/dnTriangle.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                        </a>
                    </td>
                    <td>
                        <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm ShowOnHover"
                            *ngIf="tps.General" />
                        <a (click)="updateTaskPlanSection(tps.ID, tps.Description)" *ngIf="!tps.General">
                            <img src="assets/images/edit.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                        </a>
                    </td>
                    <td>
                        <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm ShowOnHover"
                            *ngIf="tps.General" />
                        <a (click)="deleteTaskPlanSection(tps.ID, tps.Description)" *ngIf="!tps.General">
                            <img src="assets/images/cross_color.png" alt="blank" class="imageIcon-sm ShowOnHover" />
                        </a>
                    </td>
                </tr>
            </table>
            <hr style="border-color:lightgray">
            <!-- Add New Sections Sub-panel-->
            <div class="form-horizontal">
                <h4>Create New Equipment Location</h4>
                <div class="form-group">
                    <div class="col-md-10 col-md-offset-1">
                        <input class="form-control text-box single-line" [(ngModel)]="taskPlanSection.Description"
                            (keyup.enter)="createTaskPlanSection()">
                        <span class="text-danger field-validation-error" *ngIf="displayWarningSec">
                            <span>This field is required.</span>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-md-10 col-md-offset-1">
                        <a class="btn btn-primary" style="width: 100px;" (click)="createTaskPlanSection()">Create</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- *END* Left Panel -->
        <!-- *START* Right Panel -->
        <div class="col-sm-8" *ngIf="hasSections">
            <div class="box-shadow module">
                <div class="accordionContainer-lg">
                    <!-- sub-panel *START* (NewSubSection) -->
                    <div>
                        <h3 class="sectionHeader">
                            <div class="_accordion-title">
                                <span class="text-tab">New Operator Task</span>
                            </div>
                        </h3>
                        <hr style="border-color:lightgray">
                        <div class="form-horizontal">
                            <div class="form-group">
                                <label class="control-label col-md-2">Operator Task Description</label>
                                <div class="col-md-10">
                                    <input class="form-control text-box single-line"
                                        [(ngModel)]="subSection.Description" (keyup.enter)="createSubSection()">
                                    <span class="text-danger field-validation-error" *ngIf="displayWarningSubSec">
                                        <span>This field is required.</span>
                                    </span>
                                </div>
                            </div>
                            <div class="form-group">
                                <div class="col-md-offset-2 col-md-10">
                                    <a class="btn btn-primary" style="width: 100px;"
                                        (click)="createSubSection()">Create</a>
                                </div>
                            </div>
                        </div>
                        <hr style="border-color:lightgray">
                    </div> <!-- sub-panel *END* (NewSubSection) -->
                    <!-- sub-panel *START*  (SubSectionList) -->
                    <div *ngFor="let ss of subSectionList">
                        <h5 class="subSectionHeader">
                            <div class="_accordion-title">
                                <span class="text-tab">{{ss.Description}}</span>
                            </div>
                            <div class="_accordion-icons">
                                <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm imageIcon-tab-left"
                                    *ngIf="ss.ID == firstSubSectionId" />
                                <a (click)="moveUpTaskPlanSubSection(ss.ID)" *ngIf="ss.ID != firstSubSectionId">
                                    <div class="imageIcon-tab-left">
                                        <img src="assets/images/upTriangle_white.png" alt="blank"
                                            class="imageIcon-sm ShowOnHover" />
                                    </div>
                                </a>
                                <img src="assets/images/blank.png" alt="blank" class="imageIcon-sm imageIcon-tab-left"
                                    *ngIf="ss.ID == lastSubSectionId" />
                                <a (click)="moveDownTaskPlanSubSection(ss.ID)" *ngIf="ss.ID != lastSubSectionId">
                                    <div class="imageIcon-tab-left">
                                        <img src="assets/images/dnTriangle_white.png" alt="blank"
                                            class="imageIcon-sm ShowOnHover" />
                                    </div>
                                </a>
                                <a (click)="updateTaskPlanSubSection(ss.ID)">
                                    <div class="imageIcon-tab-left">
                                        <img src="assets/images/edit.png" alt="blank"
                                            class="imageIcon-sm ShowOnHover" />
                                    </div>
                                </a>
                                <a (click)="deleteTaskPlanSubSection(ss.ID)">
                                    <div class="imageIcon-tab-left">
                                        <img src="assets/images/cross_color.png" alt="blank"
                                            class="imageIcon-sm ShowOnHover" />
                                    </div>
                                </a>
                            </div>
                        </h5>
                        <!-- *END* subSectionHeader -->
                        <!-- *START* subSectionBody -->
                        <div class="p-accordion-content p-helper-reset p-corner-bottom">
                            <!-- items list -->
                            <table class="table table-hover" style="width:100%; margin-bottom: 0px;">
                                <tr *ngFor="let item of ss.TaskPlanItems">
                                    <td *ngIf="item.ItemOptionType === 'Statusbox'; else showLink" style="width:20%"
                                        pTooltip="A status cannot be modified" showDelay="1000" tooltipPosition="left">
                                        {{item.Code}}
                                    </td>
                                    <ng-template #showLink>
                                        <td style="width:20%">
                                            <a (click)="updateItem(item.ID, ss.ID)">
                                                {{item.Code}}
                                            </a>
                                        </td>
                                    </ng-template>
                                    <td style="width:70%">
                                        {{item.Description}}
                                    </td>
                                    <td>
                                        <img src="assets/images/blank.png" alt="" class="imageIcon-sm ShowOnHover"
                                            *ngIf="item.ID == ss.firstItemId" />
                                        <a (click)="moveUpTaskPlanItem(item.ID, ss.ID)"
                                            *ngIf="item.ID != ss.firstItemId">
                                            <img src="assets/images/upTriangle.png" alt="blank"
                                                class="imageIcon-sm ShowOnHover" />
                                        </a>
                                    </td>
                                    <td>
                                        <img src="assets/images/blank.png" alt="Inactive"
                                            class="imageIcon-sm ShowOnHover" *ngIf="item.ID == ss.lastItemId" />
                                        <a (click)="moveDownTaskPlanItem(item.ID, ss.ID)"
                                            *ngIf="item.ID != ss.lastItemId">
                                            <img src="assets/images/dnTriangle.png" alt="Inactive"
                                                class="imageIcon-sm ShowOnHover" />
                                        </a>
                                    </td>
                                    <td>
                                        <a (click)="deleteItem(item.ID, ss.ID)"
                                            *ngIf="!(item.ItemOptionType === 'Statusbox')">
                                            <img src="assets/images/cross_color.png" alt="Inactive"
                                                class="imageIcon-sm ShowOnHover" />
                                        </a>
                                    </td>
                                </tr>
                            </table> <!-- end items list -->
                            <div class="form-group">
                                <div class="col-md-10">
                                    <a class="btn btn-primary" (click)="createItem(ss.ID)"
                                        *ngIf="ss.TaskPlanItems.length < 3; else disableBtn">Create New Item</a>
                                    <ng-template #disableBtn>
                                        <div pTooltip="Reached maximum screen capacity for this subsection"
                                            showDelay="500" tooltipPosition="left">
                                            <a class="btn btn-primary" disabled>Create New Item</a>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div> <!-- *END* subSectionBody -->
                    </div> <!-- *END* SubSectionList -->
                </div>
            </div>
        </div> <!-- *END* Right Panel -->
    </div>
    <hr />
</div>
<div class="row">
    <div class="col-sm-6">
        <a class="btn btn-primary" (click)="onRedirectButtonClick()" style="cursor: pointer;">Back to Generic Equipment Types</a>
    </div>
    <div class="col-sm-6">
    </div>
</div>