<div class="report-module">
    <!--Report Filters-->
    <div class="box-shadow module">
        <div class="row">
            <h3>ORD Report Filters</h3>
        </div>
        <div class="row">
            <!--Select Areas-->
            <div class="form-group col-sm-6">
                <h3>Choose Areas</h3>
                <div class="row">
                    <div class="form-group">
                        <label class="control-label col-md-2">Area</label>
                        <div class="col-md-10 dropdown">                
                            <p-dropdown  [options]="Areas" [(ngModel)]="selectedArea" [showClear]="true"
                            optionLabel="label" [filter]="true" (onChange) = "getSubAreas()" [style]="{'width': '100%'}" placeholder="Select an Area">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <!--SubAreas-->
                <div class="row">
                    <div class="form-group">
                        <label class="control-label col-md-2">Sub-Area</label>
                        <div class="col-md-10 dropdown">                
                            <p-dropdown  [options]="subAreas" [(ngModel)]="selectedSubArea" [disabled]="disableSubArea" [showClear]="true"
                            optionLabel="label" [filter]="true" (onChange) = "getRoutes()" placeholder="Select a Sub-Area">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <!--Routes-->
                <div class="row">
                    <div class="form-group">
                        <label class="control-label col-md-2">Route</label>
                        <div class="col-md-10 dropdown">                
                            <p-dropdown  [options]="routes" [(ngModel)]="selectedRoute" [disabled]="disableRoute"
                            optionLabel="label"  [showClear]="true" (onChange) = "getORDs()" [filter]="true" placeholder="Select a Route">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <!--ORDs-->
                <div class="row">
                    <div class="form-group">
                        <label class="control-label col-md-2">ORD</label>
                        <div class="col-md-10 dropdown">                
                            <p-dropdown  [options]="ords" [(ngModel)]="selectedORD" [disabled]="disableORD"
                            optionLabel="label"  [showClear]="true" [filter]="true" placeholder="Select an ORD" (onChange) = "getEquipments()">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
                <!--Equipment-->
                <div class="row">
                    <div class="form-group">
                        <label class="control-label col-md-2">Equipment</label>
                        <div class="col-md-10 dropdown">                
                            <p-multiSelect  (onChange) = "saveInLocal('equip', selectedEquipment)" [options]="equips" [(ngModel)]="selectedEquipment" [disabled]="disableEquipment"
                        title="Select an Equipment" placeholder="Select an Equipment" showToggleAll="false" [style]="{'width': '100%'}" >
                            </p-multiSelect>
                        </div>
                    </div>
                </div>
            </div>
            
            <!--Calendar input-->
            <div class="form-group col-sm-6">
                <h3>Choose Dates</h3>
                <div class="row calendar-div">
                    <div class="form-group">
                        <label class="control-label col-md-2">Start Date</label>
                        <p-calendar [(ngModel)] = "startDate" dateFormat="mm.dd.yy" showIcon="true" placeholder="Enter the start date..."
                            [monthNavigator] = "true" [yearNavigator]="true" yearRange="2018:2030" [readonlyInput]="true"
                            class = "calendar"
                        ></p-calendar>
                    </div>
                </div>
                <div class="row calendar-div">
                    <div class="form-group">
                        <label class="control-label col-md-2">End Date</label>
                        <p-calendar [(ngModel)] = "finishDate" dateFormat="mm.dd.yy" showIcon="true" placeholder="Enter the end date..."
                            [monthNavigator] = "true" [yearNavigator]="true" yearRange="2018:2030" [readonlyInput]="true"
                            class = "calendar">
                        </p-calendar>
                    </div>
                </div>
            </div>
            <p-checkbox *ngIf="this.selectedTabReport==1" binary = "true" name="Not_OK_chkbx" label="Show Incomplete Only"
            (onChange)="filterResponses(onlyShowIncomplete, currentTaskPlan)" [(ngModel)]="onlyShowIncomplete"></p-checkbox>
        </div>
        <!--Button to start report-->
        <div class="row">
            <p-button label="Get Report" (onClick)="getReport()"></p-button>
            <span class="text-danger field-validation-error" *ngIf="ordWarningMsg">
                <span>Please select an ORD.</span>
            </span>
            <span class="text-danger field-validation-error" *ngIf="equipWarningMsg">
                <span>Please select one or more equipments.</span>
            </span>
        </div>
    </div>
    <!--End of Report Filters-->

    <!--Report-->
    <div class="box-shadow module" *ngIf="showReportFlag">
        <h3>{{reportName}} Report</h3>
        <div class="row">
            <p-tabView (onChange)="handleChange($event)">
                <p-tabPanel header="ORD View" [selected]="true">
                    <app-field-ord-grid [responses]="responses"></app-field-ord-grid>
                </p-tabPanel>
                <p-tabPanel header="Completed Vs. Expected" [selected]="false">
                        <app-incompletefieldgrid [responsesIncomplete]="responsesIncomplete" [onlyShowIncomplete]="onlyShowIncomplete"></app-incompletefieldgrid>
                   <!-- <ord-grid></ord-grid> [responses]="responses"-->
                </p-tabPanel>
            </p-tabView>
        </div>
    </div>
</div>
