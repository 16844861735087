import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { ORDTemplate } from '#models/ordTemplate';
import { Equipment } from '#models/equipment';
import { ORDResponse } from '#models/ordResponse';
import { ORDTaskResponse } from '#models/ordTaskResponse';

class RouteContainer {
    RouteIDs: string[];
    IncludeLogicallyDeleted: boolean;
}

class RouteContainerOp {
    CollectionPointIDs: string[];
    IncludeLogicallyDeleted: boolean;
}

class ResponseDateContainer {
    startDate1: Date;
    startDate2: Date;
    ordTemplateId: number;
}

class FieldORDRequestContainer {
    TaskPlanIDs: number[];
    StartDate: Date;
    EndDate: Date;
} 

@Injectable()
export class ORDService {
    constructor(
        private http: HttpClient
    ) { }

    public getORDTemplates(): Observable<ORDTemplate[]> {
        return this.http.get<ORDTemplate[]>(`${environment.apiEndpoint}/ord/getORDTemplates`);
    }

    public getORDTemplateByBusinessUnitID(businessUnitID: number, includePlantORD: boolean, includeFieldORDs: boolean): Observable<ORDTemplate[]> {
        return this.http.get<ORDTemplate[]>(`${environment.apiEndpoint}/ord/getORDTemplateByBU?businessUnitID=${businessUnitID}&includePlantORDs=${includePlantORD}&includeFieldORDs=${includeFieldORDs}`);
    }

    public getORDTemplateByID(ID: number): Observable<ORDTemplate> {
        return this.http.get<ORDTemplate>(`${environment.apiEndpoint}/ord/getORDTemplateByID?ID=${ID}`);
    }

    public getORDTemplateByRouteID(routeIDs: string[], IncludeLogicallyDeleted: boolean): Observable<ORDTemplate[]> {
        const routes = new RouteContainer();
        routes.RouteIDs = routeIDs;
        routes.IncludeLogicallyDeleted = IncludeLogicallyDeleted;
        return this.http.post<ORDTemplate[]>(`${environment.apiEndpoint}/ord`, routes);
    }

    public getORDTemplateNamesByRouteID(routeIDs: string[], IncludeLogicallyDeleted: boolean): Observable<ORDTemplate[]> {
        const routes = new RouteContainer();
        routes.RouteIDs = routeIDs;
        routes.IncludeLogicallyDeleted = IncludeLogicallyDeleted;
        return this.http.post<ORDTemplate[]>(`${environment.apiEndpoint}/ord/getORDNamesByRoute`, routes);
    }

    public getORDTemplateByCollectionPointID(collectionPointIDs: string[], IncludeLogicallyDeleted: boolean): Observable<ORDTemplate[]> {
        const routes = new RouteContainerOp();
        routes.CollectionPointIDs = collectionPointIDs;
        routes.IncludeLogicallyDeleted = IncludeLogicallyDeleted;
        return this.http.post<ORDTemplate[]>(`${environment.apiEndpoint}/ord/getORDsByCollectionPoint`, routes);
    }

    public getORDTemplateNamesByCollectionPointID(collectionPointIDs: string[], IncludeLogicallyDeleted: boolean): Observable<ORDTemplate[]> {
        const routes = new RouteContainerOp();
        routes.CollectionPointIDs = collectionPointIDs;
        routes.IncludeLogicallyDeleted = IncludeLogicallyDeleted;
        return this.http.post<ORDTemplate[]>(`${environment.apiEndpoint}/ord/getORDNamesByCollectionPoint`, routes);
    }

    public createORDTemplate(createList: ORDTemplate[]): Observable<ORDTemplate[]> {
        return this.http.post<ORDTemplate[]>(`${environment.apiEndpoint}/ord/createORD`, createList);
    }

    public updateORDTemplate(updList: ORDTemplate[]): Observable<ORDTemplate[]> {
        return this.http.put<ORDTemplate[]>(`${environment.apiEndpoint}/ord/updateordTemplate`, updList);
    }

    public deleteORDTemplate(ID: number): Observable<string> {
        return this.http.put<string>(`${environment.apiEndpoint}/ord/deleteordTemplate/${ID}`, '');
    }

    public getEquipment(ID: number): Observable<Equipment> {
        return this.http.get<Equipment>(`${environment.apiEndpoint}/equipment/getEquipmentById/${ID}`);
    }

    // public createEquipment(eqList: Equipment[]): Observable <Equipment[]> {
    //     return this.http.post<Equipment[]>(`${environment.apiEndpoint}/equipment/createEquipment`, eqList);
    // }

    public getOrdResponseWithDateFilter(startDate1: Date, startDate2: Date, ordId: number): Observable<ORDResponse[]> {
        const container = new ResponseDateContainer();
        container.startDate1 = startDate1;
        container.startDate2 = startDate2;
        container.ordTemplateId = ordId;
        return this.http.post<ORDResponse[]>(`${environment.apiEndpoint}/ord-response/getORDResponseByStartDates`, container);
    }
    
    //ordservice-dev-api-cvx.azurewebsites.net/api/ord-response/GetFieldReportHeaders 

/*
  public copyTaskPlan(sourceTaskPlanID: number, destinationTaskPlanID: number, isNew: boolean): Observable<TaskPlan> {
        return this.http.post<TaskPlan>(`${environment.apiEndpoint}/task-plan/copyTaskPlan
        ?sourceTaskPlanID=${sourceTaskPlanID}&destinationTaskPlanID=${destinationTaskPlanID}&isNew=${isNew}`, null);
    }
{
    "TaskPlanIDs": [
      1365
    ],
    "StartDate": "2018-10-29T16:45:33.153Z",
    "EndDate": "2019-10-29T16:45:33.153Z"
  } */

  
    public getFieldOrdResponseWithDateFilter(startDate1: Date, startDate2: Date, equips: any): Observable<ORDResponse[]> {
        const container = new FieldORDRequestContainer();
        container.StartDate = startDate1;
        container.EndDate = startDate2;
        container.TaskPlanIDs = equips;
        return this.http.post<ORDResponse[]>(`${environment.apiEndpoint}/ord-response/GetFieldReportHeaders`, container);
   }

   public getFieldOrdResponseExpectedWithDateFilter(startDate1: Date, startDate2: Date, equips: any): Observable<ORDResponse[]> {
    const container = new FieldORDRequestContainer();
    container.StartDate = startDate1;
    container.EndDate = startDate2;
    container.TaskPlanIDs = equips;
    return this.http.post<ORDResponse[]>(`${environment.apiEndpoint}/ord-response/getORDFieldResponseSummary`, container);
}

    public getOrdResponseById(ID: number): Observable<ORDResponse> {
        return this.http.get<ORDResponse>(`${environment.apiEndpoint}/ord-response/getORDResponseByID/${ID}`);
    }

    public getOrdTaskResponse(responseID: number): Observable<ORDTaskResponse[]> {
        return this.http.get<ORDTaskResponse[]>(`${environment.apiEndpoint}/ord-response/getORDTaskResponseDetailsByResponseID/${responseID}`);
    }
    ///api/task-plan/GetTaskPlanNamesByORD 
    //getORDTemplateNamesByRouteID
    public getEquipmentsByORDID(ORDID:number): Observable<Equipment> {
        return this.http.get<Equipment>(`${environment.apiEndpoint}/task-plan/GetTaskPlanNamesByORD?ordID=${ORDID}`);

    }
}
