import { Component, OnInit, Inject } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ORDTemplate } from '#models/ordTemplate';
import { BusinessUnitsService } from '#services/api/business-unit.service';
import {SelectItem, MessageService} from 'primeng/api';
import { Equipment } from '#models/equipment';
import { OMWorkbenchService } from '#services/api/omWorkbench.service';
import { ORDService } from '#services/api/ord.service';
import { EquipmentEC } from '#models/equipmentEC';
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service';
import { BusinessUnit } from '#models/business-unit';
import { finalize } from "rxjs/operators";
@Component({
  selector: 'app-generic-create',
  templateUrl: './generic-create.component.html',
  styleUrls: ['./generic-create.component.scss']
})
export class GenericCreateComponent implements OnInit {
  taskPlan: TaskPlan = new TaskPlan();
  ordTemplate: ORDTemplate = new ORDTemplate();
  Equipments: SelectItem[];
  selectedEquipment: Equipment[];
  manualEnteredEquipment: string[];
  disableGeneric: boolean;
  displayWarningNote: boolean;
  displayWarningCode: boolean;
  displayWarningDesc: boolean;
  storedBU: BusinessUnit;
  private storedData: any = [];

  constructor(
    private taskPlanSvc: TaskPlanService,
    private ordSvc: ORDService,
    public buSvc: BusinessUnitsService,
    public omSvc: OMWorkbenchService,
    public loadingIndicatorSvc: LoadingIndicatorService,
    private messageSvc: MessageService,
    private _router: Router,
    private aRoute: ActivatedRoute,
    @Inject(LOCAL_STORAGE) private storage: WebStorageService
  ) {
    }

  ngOnInit() {
    this.Equipments = [];
    this.selectedEquipment = [];
    this.displayWarningNote = false;
    this.displayWarningCode = false;
    this.displayWarningDesc = false;
    this.disableGeneric = true;
    this.taskPlan.IsGeneric = true;
    this.taskPlan.Code = null;
    // this.aRoute.params.subscribe(v => {
    //   this.taskPlan.ORDTemplateID = v['ordID'];
    // });
    this.taskPlan.ORDTemplateID = 1;
    // this.loadingIndicatorSvc.show();
    // this.ordSvc.getORDTemplateByID(this.taskPlan.ORDTemplateID)
    //   .pipe(
    //  finalize(() => this.loadingIndicatorSvc.hide()))
    //   .subscribe((data: ORDTemplate) => {
    //     this.ordTemplate = data;
    //     // this.getEquipment();
    // });

    this.getFromLocal("BU");
    this.storedBU = this.storedData["BU"];
    // console.log("generic storedBU " + this.storedBU.Name);
  }

  private getFromLocal(key: string): void {
    this.storedData[key] = this.storage.get(key);
  }

  private showError(e, message: string) {
    this.messageSvc.add({
      severity: 'error',
      summary: message,
      detail: e.error.Message || ''
    });
  }

  // private getEquipment(): void {
  //   this.omSvc.getEquipment(this.ordTemplate.RouteID)
  //   .subscribe((data: EquipmentEC[]) => {
  //     data.forEach((eqEC) => {
  //       const equipment = new Equipment(eqEC);
  //       const element: SelectItem = { label: equipment.Name, value: equipment };
  //       this.Equipments.push(element);
  //     });
  //   },
  //   e => {
  //     this.showError(e, 'Failed to get Equipment from EC');
  //   });
  // }

  onRedirectButtonClick(): void {
    this._router.navigate(['ord/generic']);
  }

  public createTaskPlan(): void {
    // const invalidCode = this.taskPlan.Code === undefined || this.taskPlan.Code == null || this.taskPlan.Code.length === 0;
    const invalidDesc = this.taskPlan.Description === undefined || this.taskPlan.Description == null || this.taskPlan.Description.length === 0;
    // this.displayWarningCode = invalidCode;
    this.displayWarningDesc = invalidDesc;
    if (!invalidDesc) {
      this.loadingIndicatorSvc.show();
      this.taskPlan.BusinessUnitID = this.storedBU.Id;
      this.taskPlanSvc.createTaskPlanV2(this.taskPlan)
      .pipe(
      finalize(() => {
        this.loadingIndicatorSvc.hide();
        this.onRedirectButtonClick();
      }))
      .subscribe((data: TaskPlan) => {
      },
      e => {
        this.showError(e, 'Failed to fully create Generic Equipment Type');
      });
    }
  }
}

